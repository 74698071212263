.weeks-selector {
  padding: 15px 50px;

  .date-range {
    margin-left: -50px;

    .label-and-input-wrapper {
      display: inline;
    }

    .input-wrapper {
      position: relative;
      top: -2px;
      display: inline-block;
      width: 140px;
      height: 34px;
      text-align: left;
      margin: 0 5px;
      vertical-align: middle;

      .fake-input,
      ec-date-picker {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
      }

      .form-control {
        font-size: 13px;
        padding: 7px 12px;
      }

      ec-date-picker {
        --ec-date-picker-input-group-display: flex;
        --ec-date-picker-button-size: 34px;

        .form-control {
          opacity: 0;
        }

        &:focus-within,
        &.active {
          .form-control {
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: 550px) {
      .label-and-input-wrapper {
        display: block;
        width: 214px;
        text-align: right;
        margin-top: 5px;
      }
    }

  }

  .months-container {
    position: relative;
    margin: 30px auto 0;
    padding-bottom: 40px;
    min-height: 233px;

    .months {
      display: grid;
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      grid-auto-rows: 200px;
      margin: 0 auto;

      .month {
        width: 95px;
        margin-right: 20px;

        .month-label {
          font-size: 14px;
          font-weight: bold;
          color: #333;
          border-bottom: 1px solid #ddd;
          padding-left: 20px;
          margin-right: 9px;

          .year {
            color: #888;
            font-weight: 100;
          }
        }

        .weekdays-label {
          font-size: 12px;
          color: #888;
          margin-left: 20px;
          margin-top: 5px;

          .start-weekday,
          .end-weekday {
            display: inline-block;
            width: 18px;
          }

          .end-weekday {
            margin-left: 2px;
          }
        }

        .weeks {

          .week {
            display: block;
            font-size: 12px;
            font-weight: normal;
            color: #888;
            position: relative;
            padding: 0 0 0 20px;
            margin: 5px 0 0;

            &.checked {
              font-weight: bold;
            }

            input {
              position: absolute;
              left: 0;
              top: 4px;
              margin: 0;
            }

            .day-label {
              color: #333;
              display: inline-block;
              width: 18px;

              &.end-day-label {
                margin-left: 2px;
              }

              &.start-day-label.from-previous-month,
              &.end-day-label.from-next-month {
                color: #888;
              }
            }
          }

        }
      }
    }

    &.with-4-columns {
      .months {
        width: 450px;
        grid-template-columns: repeat(4, 75px);
      }
    }

    &.with-3-columns {
      .months {
        width: 325px;
        grid-template-columns: repeat(3, 75px);
      }
    }

    &.with-2-columns {
      .months {
        width: 200px;
        grid-template-columns: repeat(2, 75px);
      }
    }

    &.with-1-column {
      .months {
        width: 75px;
        grid-template-columns: repeat(1, 75px);
      }
    }

    .actions {
      .action {
        position: absolute;
        color: #828282;
        cursor: pointer;

        &:hover {
          color: #505050;
        }

        &.action-prev {
          top: 90px;
          left: -35px;
        }

        &.action-next {
          bottom: 126px;
          right: -35px;
        }
      }

      .clear-all {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 14px;
      }
    }
  }
}
