@mixin button-color(
  $background-color,
  $border-color,
  $background-color-hover,
  $border-color-hover,
) {

  &,
  &:focus,
  &.focus {
    color: #fff;
    background-color: $background-color;
    border-color: $border-color;
  }

  &:hover,
  &.hover,
  &:active,
  &.active {
    color: #fff;
    background-color: $background-color-hover;
    border-color: $border-color-hover;
  }
}

@mixin button-color-border(
  $color,
  $border-color,
  $background-color-hover,
  $border-color-hover,
) {

  &.btn.btn-border {
    &,
    &:focus,
    &.focus {
      color: $color;
      border-color: $border-color;
      background-color: transparent;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      color: #fff;
      background-color: $background-color-hover;
      border-color: $border-color-hover;
    }
  }
}
