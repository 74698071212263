.school-details-popover {
  min-width: 300px;
  z-index: 1100;
}
.school-details-popover.bottom {
  margin-top: 5px;
}
.school-details-popover .popover-title {
  padding-top: 15px;
  padding-bottom: 0;
  min-height: 45px;
}
.school-details-popover .popover-title img {
  max-width: 30px;
  max-height: 30px;
  position: relative;
  top: -5px;
  left: -5px;
  vertical-align: top;
}
.school-details-popover .popover-content {
  padding-bottom: 15px;
  word-wrap: break-word;
}
.school-details-popover .divider {
  margin: 10px -15px;
  width: auto;
  border-color: #ededed;
}
