@use "abstracts/mixins";
@use "abstracts/variables";

.testimonial {
  padding: 0 0 30px;
  margin: 20px 0;
  font-size: inherit;
  line-height: inherit;
  border: none;
  border-bottom: 1px solid variables.$section-border-color;

  .testimonial-author {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    position: relative;
    padding-left: 100px;
    min-height: 80px;
    margin-top: 20px;

    &:before {
      content: "";
    }

    .testimonial-author-image-wrapper {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      img {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .testimonial-author-details-wrapper {
      cite {
        font-size: inherit;
        font-style: normal;
        font-weight: normal;
      }
      .name {
        display: block;
        font-weight: bold;
      }
      .details {
        display: block;
      }
      .company {
        display: block;
        font-weight: bold;
      }
    }
  }

  @media (min-width: 767px) {

    .testimonial-author {
      margin-top: 50px;
      margin-bottom: 50px;
    }

  }

  @media (min-width: 992px) {
    padding-bottom: 60px;
    margin-top: 60px;
    margin-bottom: 60px;
    @include mixins.clearfix;

    .testimonial-content {
      float: right;
      width: calc(100% - 330px);
    }

    .testimonial-author {
      float: left;
      width: 300px;
      margin: 0;
      padding-left: 110px;
    }
  }
}

// collapsing on tablets and mobiles
.testimonial {

  .collapse-text-toggle {
    .more {
      display: none;
    }
    .less {
      display: inline;
    }
  }
  &.collapsed {
    @media (max-width: 991px) {
      .hide-when-collapsed {
        display: none;
      }
      .collapse-text-toggle {
        .more {
          display: inline;
        }
        .less {
          display: none;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .collapse-text-toggle {
      display: none;
    }
  }
}

.testimonials-carousel {

  max-width: 530px;
  margin: 0 auto;

  .carousel {
    margin-bottom: 80px;

    .carousel-indicators {
      bottom: -80px;
    }

    .carousel-control {
      top: 5px;
      margin: 0;

      &.left {
        left: -80px;
      }
      &.right {
        right: -80px;
      }
    }
  }

  .testims-read-all {
    text-align: center;
    font-size: 18px;
    max-width: 530px;
    margin: 0 auto;
    padding-top: 25px;
  }

  .testimonial {
    display: inline-block;
    max-width: 530px;
    text-align: left;
    float: none;
    width: auto;
    padding: 0;
    margin: 0;
    border: none;

    .testimonial-content {
      font-size: 18px;
      margin-bottom: 40px;
      float: none;
      width: auto;
    }

    .testimonial-author {
      font-size: 18px;
      line-height: 1.6em;
      font-weight: normal;
      margin: 0;
      padding: 0;
      float: none;
      width: auto;

      .testimonial-author-image-wrapper {
        display: none;
      }

      .testimonial-author-details-wrapper .company {
        font-weight: normal;
      }
    }
  }

  @media (max-width: 767px) {

    .carousel {
      margin-bottom: 55px;

      .carousel-indicators {
        bottom: -55px;
      }
    }

    .testims-read-all {
      font-size: 16px;
      padding-top: 5px;
    }

    .testimonial {
      padding: 25px 25px 0;

      .testimonial-content {
        font-size: 16px;
        line-height: 28px;
      }

      .testimonial-author {
        font-size: 16px;
      }
    }
  }
}
