body.embedded-mode {

  #footer-wrapper {
    display: none;
  }

  #header {

    .portal-container {
      padding: 0 !important;
    }

    .header-logo-container {
      display: none;
    }

    .header-menu-container {
      width: auto;

      .header-menu-open {
        display: none;
      }

      .header-menu {
        > li.share-icon,
        > li.schedule-icon {
          display: none;
        }

        > li.cart-dropdown {
          float: right;
          display: block;
        }
      }

      .header-user-menu {
        margin-right: 0;

        > li.header-login:not(.ng-hide),
        > li.header-signup:not(.ng-hide) {
          display: block;

          > a {
            border-bottom-color: transparent;
            font-size: 18px;
          }
        }

        > li.dropdown.header-account {
          display: block;

          > .dropdown-menu > li:not(.logout) {
            display: none;
          }

        }

      }

    }

  }

  .modal-backdrop {
    background-color: #fff;

    &.in {
      opacity: 0.65;
    }
  }

  .modal {
    top: 75px;

    .modal-dialog {
      box-shadow: 0 0 15px rgba(0,0,0,0.25);
      margin: 15px auto;
    }

    .modal-content {
      border: 1px solid #d2d2d2;
    }
  }

  .cart-modal {

    .modal-dialog {
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);
      height: auto;
      transform: translate(0, -150%);

      .cart-container {
        min-height: 200px;

        .cart-summary {
          position: static;
        }

      }
    }
  }

  .cart-modal.fade .modal-dialog {
    transition: transform .3s ease-out;
  }

  .cart-modal.fade.in .modal-dialog {
    transform: translate(0, 0);
  }
}
