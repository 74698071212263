@use "sass:meta";

@import '../../app/_assets/css/6cricketsIcons.css';
@import '../../app/_assets/css/base.css';
@import '../../app/_assets/css/colors.css';
@import '../../app/_assets/css/custom-widgets.css';

/* Sass styles should be after more general styling (files above),
   but before page specific styling (files below) */
@include meta.load-css('../../app/_assets/sass/main.scss');

@import '../../app/_assets/css/page-search.css';
@import '../../app/_assets/css/page-directory.css';
