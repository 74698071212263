@use "abstracts/variables";

.day-of-week-session {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;

  @media (min-width: 768px) {
    height: 410px;
  }

  .program-name {
    font-size: 16px;
    margin: 0;
    line-height: 1.5em;
    color: variables.$dark-text-color;

    @media (min-width: 768px) {
      max-height: 3em;
      min-height: 1.5em;
      overflow: hidden;
    }

    a {
      color: variables.$dark-text-color;

      &:hover {
        color: variables.$link-hover-color;
      }
    }
  }

  .provider {
    font-size: 14px;
    line-height: 1.5em;

    .provider-name-wrapper {
      color: variables.$dark-text-color;

      @media (min-width: 768px) {
        max-height: 3em;
        min-height: 1.5em;
        overflow: hidden;
      }
    }

    .provider-name {
      font-size: 14px;
      margin: 0;
      display: inline;
      font-weight: normal;
      color: variables.$dark-text-color;

      a {
        color: variables.$dark-text-color;

        &:hover {
          color: variables.$link-hover-color;
        }
      }
    }

    ec-display-rating {
      display: block;
    }

    .provider-more {
      font-size: 13px;
      display: block;

      @media (min-width: 768px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .secondary-actions {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .details {
    flex: 1 1;
    font-size: 14px;
    margin-top: 10px;

    .details-icon {
      display: none;
    }

    .details-seats,
    .details-drop-ins {
      margin-top: 10px;
    }
  }

  .main-actions {

    @media (min-width: 768px) {
      position: relative;
      padding-bottom: 1.5em;
      height: 105px;
    }

    .show-in-schedule {
      font-size: 14px;
      line-height: 1.2em;
      display: inline-block;
    }

    [add-to-cart] .btn {
      width: 100%;
      margin: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .social-cue {
      font-size: 13px;

      @media (min-width: 768px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;

        &:hover {
          white-space: normal;
          left: -21px;
          right: -21px;
          border: 1px solid #e2e2e2;
          border-top: none;
          z-index: 1;
          background: #fff;
          padding: 0 20px 15px;
          min-height: 46px;
        }
      }
    }
  }

  .for-admin {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    background: variables.$highlight-for-admins-color;
    padding: 2px 10px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 0.75;
    transition: opacity 0.35s;

    &:hover {
      opacity: 1;
    }

    .admin-actions {
      float: right;

      a {
        opacity: 0.75;
        transition: opacity 0.35s;
        color: inherit;

        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }

      .fa {
        font-size: 20px;
      }
    }
  }
}
