@use "abstracts/variables";

.social-links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  li {
    text-align: center;
    width: 40px;
    height: 40px;

    a {
      display: block;
      width: 100%;
      height: 100%;
      transition: color variables.$link-hover-transition-time, background-color variables.$link-hover-transition-time;
      color: #fff !important;

      .fa {
        font-size: 20px;
        line-height: 40px;
        vertical-align: middle;
      }

      &.social-link-facebook {
        background-color: variables.$facebook-color;

        &:hover {
          background-color: variables.$facebook-hover-color;
        }
      }

      &.social-link-instagram {
        background-color: variables.$instagram-color;

        &:hover {
          background-color: variables.$instagram-hover-color;
        }
      }

      &.social-link-twitter {
        background-color: variables.$twitter-color;

        &:hover {
          background-color: variables.$twitter-hover-color;
        }
      }

      &.social-link-youtube {
        background-color: variables.$youtube-color;

        &:hover {
          background-color: variables.$youtube-hover-color;
        }
      }
    }
  }
}

.gray-social-links.social-links,
.gray-social-links .social-links {
  li a {
    background-color: #878789;

    &.social-link-facebook:hover {
      background-color: variables.$facebook-color;
    }

    &.social-link-twitter:hover {
      background-color: variables.$twitter-color;
    }

    &.social-link-youtube:hover {
      background-color: variables.$youtube-color;
    }
  }
}

.social-links-sm.social-links,
.social-links-sm .social-links li {
  width: 34px;
  height: 34px;

  a .fa {
    font-size: 18px;
    line-height: 36px;
  }
}
