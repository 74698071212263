.request-demo {
  --request-demo-max-width: 430px;
  padding-bottom: 30px;

  .title-container {
    padding-top: 30px;
    margin-bottom: 10px;
    text-align: center;

    h1 {
      display: inline-block;
      font-size: 30px;
      margin-bottom: 30px;
      max-width: var(--request-demo-max-width);
    }
  }

  .content-container {

    .images {
      grid-column: content;
      text-align: center;
      padding: 0 15px;
    }

    .main-text {
      grid-column: content;
      margin: 40px 0 20px;
      padding: 0 15px;
      position: relative;
      z-index: 1;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .calendly-wrapper {
      grid-column: content;
      position: relative;
      overflow: hidden;

      .svg-stars-many {
        position: absolute;
        fill: #FDD4E2;
        z-index: 0;
        bottom: 1100px;
        left: 50%;
        width: 150px;

        &:nth-of-type(2) {
          bottom: 700px;
          right: 50%;
          left: auto;
          width: 300px;
        }

        &:nth-of-type(3) {
          bottom: 50px;
          width: 400px;
        }
      }
    }

  }

  @media (min-width: 430px) {
    .content-container {
      display: grid;
      grid-template-columns:
        [full-start] 1fr [content-start] var(--request-demo-max-width) [content-end] 1fr [full-end];
    }
  }

  @media (min-width: 710px) {
    --request-demo-max-width: 710px;

    .title-container {
      padding-top: 60px;
      margin-bottom: 20px;

      h1 {
        font-size: 40px;
        margin-bottom: 50px;
      }
    }

    .content-container {

      .main-text {
        margin-bottom: -20px;
      }

      .calendly-wrapper {
        grid-column: full;

        &:after {
          content: "";
          display: block;
          background: white;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 50px;
        }
      }

    }
  }

  @media (min-width: 1000px) {
    --request-demo-max-width: 430px;
  }

  @media (min-width: 1700px) {
    .bottom-waved-inverse-background {
      background-size: cover;
    }
  }

}
