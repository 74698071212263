@use "mixins";

.btn-warning {
  @include mixins.button-color(
    #f0ad4e, #eea236, #ec971f, #d58512
  );
  @include mixins.button-color-border(
    #ffc107, #eea236, #d39e00, #af8300
  );
}
