@keyframes zoomInStartDefaultSize {
  0% {
    opacity: 0;
  }

  5% {
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}
