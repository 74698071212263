@use 'abstracts/variables';

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid variables.$section-border-color;
}
hr.gray {
	border-color: variables.$section-border-color;
}
hr.dashed {
  border-top-style: dashed;
}
hr.dotted {
  border-top-style: dotted;
}

.divider {
	width:100%;
	margin:40px 0;
	position: relative;
}

.divider > span {
	background-color: #fff;
	display:block;
	display:inline-block;
	position: absolute;
	left:50%;
	top:50%;
	transform:translateX(-50%) translateY(-50%);
	-webkit-transform:translateX(-50%) translateY(-50%);
	-ms-transform:translateX(-50%) translateY(-50%);
	font-size:1.2em;
	width: 48px;
	height:48px;
	line-height: 46px;
	border:1px solid variables.$section-border-color;
	text-align: center;
	border-radius: 50%;
}

.divider.line {
	border-bottom:1px solid variables.$section-border-color;
}

.divider.line.double {
	border-top:1px solid variables.$section-border-color;
	padding:4px 0;
	border-bottom:1px solid variables.$section-border-color;
}

.divider.line.dashed {
  border-bottom-style: dashed;
}

.divider > span.smaller {
  font-size: 0.8em;
  width: 40px;
  height: 40px;
  line-height: 37px;
}

.divider > span.tiny {
  font-size: 0.8em;
  width: 25px;
  height: 25px;
  line-height: 24px;
  font-weight: bold;
}
