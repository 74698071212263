@use "abstracts/variables";
@use "base/font-awesome";
@use "base/calendar_picker_button_as_text";

.schedule-modal {
  .modal-dialog,
  .modal-content {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
  }
}
// showing animation
.schedule-modal.fade {
  opacity: 1;

  .modal-dialog {
    transform: translateX(100%);
    transition: transform 0.35s ease-in-out;
  }
}
.schedule-modal.in .modal-dialog {
  transform: translateX(0);
}

.schedule-modal .schedule-container .container {
  position: relative;
  height: 100vh;
}
@media (max-width: 1199px) {
  .schedule-modal .schedule-container .container {
    width: 100%;
  }
}

.schedule-modal .schedule-container .schedule-title {
  font-weight: normal;
  height: 80px;
  margin: 0;
  font-size: 24px;
  padding: 15px 10px 0;
  position: relative;
  color: variables.$header-menu-color;

  .share-modal-icon,
  .print-icon {
    margin-left: 10px;
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    vertical-align: text-top;
  }

  .share-modal-icon {
    svg.svg-share-icon {
      width: 38px;
      position: absolute;
      left: -5px;
      top: -5px;
      stroke: #8A8A8A;
      transition: stroke 0.2s ease;
    }

    &:hover svg.svg-share-icon {
      stroke: #595959;
    }
  }

  .print-icon {
    line-height: 30px;

    .fa-print {
      color: #8A8A8A;
      position: relative;
      top: -2px;
      transition: color 0.2s ease;
    }

    &:hover .fa-print {
      color: #595959;
    }
  }

  .settings-title {
    display: none;
  }

  .calendar-input {
    @include calendar_picker_button_as_text.style-calendar-picker-button-as-text;

    & {
      display: block !important;
      font-size: 18px;
      white-space: nowrap;
      margin-top: 7px;
    }

    .input-group-btn {
      margin-left: 100px;

      .btn {
        position: relative;
        padding-right: 20px;

        &:after {
          @include font-awesome.font-awesome('angle-down');
          & {
            font-size: 1.2em;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .schedule-modal-close {
    position: absolute;
    top: 9px;
    right: 0;
  }

  @media (max-width: 767px) {
    &.calendar-picker-shown {

      .calendar-picker {
        display: none;
      }

      .settings-title {
        display: block;
        font-size: 20px;
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 510px) {
    .calendar-input {
      font-size: 16px;

      .input-group-btn {
        float: right;
        margin-left: 0;
      }
    }
  }

  @media (max-width: 400px) {
    .calendar-input .input-group-btn .of-week {
      display: none;
    }
  }
}

.schedule-modal {
  .table.schedule-table > thead > tr > th div.child-header .search-icon {
    display: none;
  }
  .schedule-table .side-info-container .action-link.search-icon {
    display: none;
  }
  .table.schedule-table tbody > tr > td.week-header-wrapper .week-header {
    display: none;
  }
  .schedule-table .cell-dropdown .dropdown-menu li.suggest-new,
  .schedule-table .cell-dropdown .dropdown-menu li.suggest-new-disabled {
    display: none;
  }

  .schedule-table-container {
    height: calc(100% - 80px);
  }
  .schedule-wrapper,
  .schedule-wrapper > div,
  .schedule-wrapper .tab-content-wrapper,
  .schedule-wrapper .tab-content-wrapper > div {
    height: 100%;
  }
}

.popover.calendarpicker.schedule-modal-calendarpicker {
  @media (max-width: 767px) {
    top: 75px !important;

    .popover-content {
      padding-top: 10px;

      .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    .popover-footer {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

/* styles for printing */
@media print {
  body.schedule-modal-opened {
    overflow: visible;

    #wrapper {
      display: none;
    }

    .schedule-modal {
      position: static;
      overflow: visible;

      .schedule-title {
        .share-modal-icon,
        .print-icon {
          display: none;
        }
      }

      .schedule-wrapper {
        .schedule-table-print-title {
          height: auto;
          position: absolute;
          top: 15px;
          right: 0;
        }
      }
    }
  }
}
