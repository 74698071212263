@use "tables-mixins";

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  // clearing Bootstrap added border from cells as we have borders on rows
  border-top: 0;
}

.table {
	margin-bottom: 30px;
}

.table tbody > tr > td:first-child {
	background-color: #fafafa;
}

.table.table-striped tbody > tr > td:first-child,
.table.table-hover tbody > tr > td:first-child,
.table.table-colored tbody > tr > td:first-child {
	background-color: transparent;
}

.table tbody > tr.active > td:first-child {
	background-color: #f5f5f5;
}

.table tbody > tr.success > td:first-child {
	background-color: #dff0d8;
}

.table tbody > tr.danger > td:first-child {
	background-color: #f2dede;
}

.table tbody > tr.info > td:first-child {
	background-color: #d9edf7;
}

.table tbody > tr.warning > td:first-child {
	background-color: #fcf8e3;
}

.table-hover > tbody > tr:hover {
  background-color: #fafafa;
}

.table.no-bg thead > tr > th,
.table.no-bg tbody > tr > td:first-child {
	background-color: transparent;
}

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border-color:#eaeaea;
}

.table thead > tr,
.table tbody > tr {
	border-top:1px solid #eaeaea;
	border-bottom:1px solid #eaeaea;
}

.table.table-colored > thead > tr,
.table.table-colored > tbody > tr,
.table.table-colored > thead > tr > th,
.table.table-colored > tbody > tr > td {
	border:none !important;
}

.table thead > tr > th {
	border-bottom:none;
	color:#3a3a3a;
	padding:25px 20px;
	text-transform: uppercase;
}

.table thead > tr > th:first-child {
	text-align: left;
}

.table tbody > tr > td {
	padding:30px 20px;
	vertical-align: middle;
}

@media (max-width: 767px) {
	.table-responsive {
		margin-bottom: 20px;
		border-color:#eaeaea;
	}

	.table thead > tr,
	.table tbody > tr {
		border:none;
	}

	.table thead > tr > th {
		font-size:14px;
		line-height: 1.5;
	}

}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fafafa;
}


.table-container {
  /*
  max-height: 800px;
  overflow: auto;
  */
  /* extra room for X icon: */
  padding-right: 10px;
}

table.no-border,
td.no-border {
  border: 0 !important;
}

.table.table-no-expand {
  width: auto;
}

.table tbody > tr > td,
.table tbody > tr > th {
  padding: 15px;
}
.table thead > tr > th:first-child,
.table tbody > tr > td:first-child {
  padding-left: 15px;
  padding-right: 15px;
}
.table thead > tr > th {
  padding: 15px;
  text-transform: none;
  letter-spacing: 0;
}

.table thead,
.table tbody,
.table tbody > tr > td:first-child {
  background-color: #FFF;
}

.table thead > tr > th,
.table tfoot > tr > th {
  background-color: #FCFCFC;
}

.table.table-condensed {
  @include tables-mixins.table-condensed;
}

.table.table-extra-condensed {
  @include tables-mixins.table-extra-condensed;
}

// helpers for table-as-cards mixin
.show-block-on-table-as-cards,
.show-inline-on-table-as-cards {
  display: none;
}

.table-interactive {
  /*table-layout: fixed;*/
  background: white;
  /* 0-width column for un-add icons prevents right border from collapsing. */
  border-right-style: none !important;
}
.table-interactive th.un-add-icon {
  width: 0;
  padding: 0;
  border-style: none !important;
}
.table-interactive td.un-add-icon {
  width: 0;
  vertical-align: middle;
  border-style: none !important;
}
.table-interactive input,
.table-interactive .form-control,
.table-interactive .ui-select-container,
.table-interactive .ui-select-multiple.ui-select-bootstrap {
  display: inline-block;
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
}
.table.small-font {
  font-size: 14px;
}
.table.no-internal-line > thead > tr,
.table.no-internal-line > tbody > tr {
  border-top: none !important;
  border-bottom: none !important;
}
.table.no-internal-line > thead > tr > th,
.table.no-internal-line > tbody > tr > td {
  border-top: none !important;
}

/* Line breaks */
.table-interactive .summary span,
.table-interactive .details span {
  white-space: pre-line;
}

.table.table-top-vertically-aligned tbody > tr > td {
  vertical-align: baseline;
}
.table.table-one-line-headers > thead > tr > th {
  white-space: nowrap;
}
.table.table-no-left-right-padding > thead > tr > th,
.table.table-no-left-right-padding > tbody > tr > th,
.table.table-no-left-right-padding > tfoot > tr > th,
.table.table-no-left-right-padding > thead > tr > td,
.table.table-no-left-right-padding > tbody > tr > td,
.table.table-no-left-right-padding > tfoot > tr > td,
.table.table-no-left-right-padding > thead > tr > th:first-child,
.table.table-no-left-right-padding > tbody > tr > td:first-child {
  padding-left: 0;
  padding-right: 0;
}

.table.table-no-left-right-padding-on-first-and-last {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.table.table-no-borders {
  thead > tr,
  tbody > tr,
  tbody > tr > td {
    border: none;
  }
}

.table.table-no-backgrounds {
  thead > tr > th,
  tfoot > tr > th {
    background: none;
  }
}

.table.table-no-header-borders-and-backgrounds {
  thead > tr {
    border-top: 0;

    > th {
      background: none;
    }
  }
}
