@use "abstracts/variables" as abstracts-variables;
@use "variables";

.btn {
  padding: 9px 16px;
  font-size: 14px;
  transition: color 0.2s ease,
              background-color 0.45s ease,
              border 0.4s ease-in-out,
              box-shadow 0.2s ease;
  border-radius: variables.$button-border-radius;
  text-decoration: none;
}

.btn.btn-border {
  border-width: 2px;
  padding: 8px 15px;
  background-color: transparent;
}

.btn.btn-border.btn-border-light {
  border-width: 1px;
  padding: 9px 16px;
  background-color: transparent;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.btn:focus,
.btn.focus {
  box-shadow: abstracts-variables.$control-focus-box-shadow;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

.btn.active:focus,
.btn.focus:active,
.btn:active:focus,
.btn.active.focus {
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125), abstracts-variables.$control-focus-box-shadow;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  &,
  &.btn-link {
    cursor: not-allowed;
    opacity: .65;
    box-shadow: none;
  }
}

.btn.disabled:not(.btn-link),
.btn:not(.btn-link)[disabled],
fieldset[disabled] .btn:not(.btn-link) {
  background-color: #9B9B9B !important;
  border-color: #9B9B9B !important;
  color: #f1f1f1 !important;
}
