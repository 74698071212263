@use "abstracts/variables";

/*------------- CART -----------------*/
.cart-number-of-items {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  background-color: variables.$hero-color;
  color: #fff;
  border-radius: 50px;

  .ng-hide-remove {
    animation: zoomCartItemsNumber 2s;
  }
}
.cart-dropdown.empty {
  display: none;
}
// we show cart on search page even if cart is empty
body.page-type-summer .cart-dropdown.empty {
  display: block;
}
.cart-dropdown.ng-enter {
  animation: fadeInRight 2s;
}
.cart-dropdown.ng-leave {
  animation: fadeOutRight 1s;
}
.cart-dropdown .cart-button {
  color: variables.$header-menu-color;

  &:hover {
    color: variables.$header-menu-color-hover;
  }

  .loading {
    position: relative;
    top: -3px;
  }
  .cart-info {
    display: inline-block;
    position: relative;
    top: 1px;
  }
  .svg-cart-icon,
  .svg-giftcard-icon {
    fill: variables.$header-menu-color;
    transition: fill variables.$link-hover-transition-time;
  }
  .svg-cart-icon {
    width: 28px;
    height: 26px;
  }
  .svg-giftcard-icon {
    width: 32px;
    position: relative;
    top: -1px;
  }
  .cart-number-of-items {
    right: 3px;
    top: -6px;
  }
}
.cart-dropdown .cart-button .svg-cart-icon:hover,
.cart-dropdown .cart-button .svg-giftcard-icon:hover,
.cart-dropdown:hover .cart-button .svg-cart-icon,
.cart-dropdown:hover .cart-button .svg-giftcard-icon {
  fill: variables.$header-menu-color-hover;
}
.cart-dropdown .cart-button .giftcards-balance {
  display: none;
  margin-right: 20px;
}
body.page-type-summer .cart-dropdown .cart-button .giftcards-balance {
  display: inline;
}


.cart-modal {
  .modal-dialog,
  .modal-content {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
  }
}
// showing animation
.cart-modal.fade {
  opacity: 1;

  .modal-dialog {
    transform: translateX(100%);
    transition: transform 0.35s ease-in-out;
  }
}
.cart-modal.in .modal-dialog {
  transform: translateX(0);
}

.cart-container .container {
  position: relative;
}
@media (max-width: 1199px) {
  .cart-container .container {
    width: 100%;
  }
}

.cart-container .cart-title {
  font-weight: normal;
  height: 70px;
  margin: 0;
  font-size: 24px;
  padding: 23px 20px 0;
  position: relative;
  color: variables.$header-menu-color;

  .cart-modal-close {
    position: absolute;
    top: 17px;
    right: 10px;
  }
}

.cart-container .giftcards-message {
  display: none;
  padding: 5px 15px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  background-color: variables.$link-color;
}
body.page-type-summer .cart-container .giftcards-message {
  display: block;
}
.cart-container .empty-cart {
  padding: 15px 15px 10px;
}
.cart-container .empty-cart .cart-summary {
  margin: 15px -15px 0;
  padding: 0 15px;
}
.cart-container .empty-cart .cart-summary .cart-actions .btn {
  margin: 0;
}
.cart-container .cart-dropdown-desc {
  color: #777;
  font-weight: bold;
  margin: 0;
}
.cart-container .cart-dropdown-desc a {
  font-weight: inherit !important;
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}
.cart-container .nav-tabs {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd
}
.cart-container .nav-tabs li {
  display: table-cell;
  width: 1%;
  float: none;
  margin: 0;
  padding-left: 3px;
}
.cart-container .nav-tabs li:first-child {
  padding-left: 0;
}
.cart-container .nav-tabs li > a {
  margin: 0 !important;
  border: none !important;
}
@media (max-width: 500px) {
  .cart-container .nav-tabs li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .cart-container .nav-tabs li > a > i {
    display: none;
  }
}
.cart-container .nav-tabs li:after {
  position: relative;
  top: 1px;
}
.cart-container .cart-dropdown-items {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 15px;
  padding-bottom: calc(15px + var(--extra-bottom-space, 0px));
}
.cart-container .cart-dropdown-items a {
  display: inline;
  padding: 0;
}
.cart-container .cart-dropdown-items h5 {
  margin-bottom: 5px;
}
.cart-container .cart-dropdown-item {
  position: relative;
  border-radius: 4px;
  padding: 4px 30px 4px 0;
  line-height: 1.2em;
  margin: 2px 0;
}
.cart-container .cart-dropdown-item:before {
  content: "•";
  position: absolute;
  left: -15px;
  top: 6px;
  font-size: 16px;
}
.cart-container .cart-dropdown-items.waitlist .cart-dropdown-item:before {
  color: #478eb1;
}
.cart-container .cart-dropdown-items.unavailable .cart-dropdown-item:before {
  color: #c05b59;
}
.cart-container .cart-dropdown-items.unpaid .cart-dropdown-item:before {
  color: variables.$hero-color;
}
.cart-container .cart-dropdown-items.unpaid .cart-dropdown-item.registration-not-started:before {
  color: #aa884f;
}
.cart-container .cart-dropdown-item:hover {
  background-color: #eee;
}
.cart-container .cart-dropdown-item a.item {
  display: inline-block;
}
.cart-container .cart-dropdown-item strong {
  font-weight: bold;
}
.cart-container .cart-dropdown-item .registration {
  font-style: italic;
}
.cart-container .remove-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.4s ease-in;
  -webkit-transition: all 0.4s ease-in;
  color: #adadad;
  background-color: #eee;
}
.cart-container .remove-btn:hover,
.cart-container .remove-btn:focus {
  color: #303030;
  background-color: #dadada;
}
.cart-container .remove-btn i {
  margin: 0 !important;
}

.cart-container .cart-summary {
  padding: 0 15px 10px;
  position: absolute;
  min-height: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.cart-container .cart-summary .container {
  border-top: 1px solid variables.$section-border-color;
  padding-top: 5px;
}
.cart-container .cart-summary .cart-to-pay {
  padding-top: 8px;
}
@media (min-width: 400px) {
  .cart-container .cart-summary .cart-to-pay .one-currency {
    margin-top: 12px;
  }
}
.cart-container .cart-summary .cart-actions {
  padding-top: 10px;
  text-align: right;
  margin-right: 25px;
}
.cart-container .cart-summary .cart-actions .btn {
  margin-bottom: 5px;
  margin-left: 10px;
}
.cart-container .cart-summary .cart-actions .go-to-schedule-button {
  margin-left: 0;
}

.add-to-cart-child-selection-modal .student-edit-inline-wrapper {
  margin-right: 15px;
}
.add-to-cart-child-selection-modal input.student-name-inline {
  float: left;
  width: calc(100% - 90px);
  margin-right: 10px;
}
.add-to-cart-child-selection-modal input.student-age-inline {
  float: left;
  width: 80px;
}
.add-to-cart-child-selection-modal .student-edit-inline-wrapper.for-adult {
  input.student-name-inline {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}
.add-to-cart-child-selection-modal input.student-age-inline.ng-invalid-required.ng-dirty {
  border-color: #a94442;
}
.add-to-cart-child-selection-modal .student-actions {
  button, .between-buttons {
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .between-buttons {
    color: variables.$text-muted-color;
  }
}
.add-to-cart-child-selection-modal .modal-footer .checkout-button {
  width: 165px;
}
