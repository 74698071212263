@keyframes zoomInBounced {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  66% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}
