.btn-social {
  position: relative;
  padding-left: 38px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;

  & > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 38px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form-lg .btn.btn-social:not(.btn-link) {
  padding-left: 44px;

  & > :first-child {
    width: 44px;
  }
}

@mixin button-social(
  $background-color,
  $background-color-hover,
) {

  &,
  &:focus,
  &.focus {
    color: #fff;
    background-color: $background-color;
  }

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: $background-color-hover;
  }
}

/* Facebook */
.btn-facebook {
  @include button-social(#3b5998, #2d4373);
}

/* Google */
.btn-google {
  @include button-social(#dd4b39, #c23321);
}

/* Twitter */
// .btn-twitter {
//   @include button-social(#55acee, #2795e9);
// }

/* Microsoft */
.btn-microsoft {
  @include button-social(#2f2f2f, #000);
}
