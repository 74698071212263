@media print {
  // override Bootstrap's styling that makes everything black when printing
  // TODO(brsc): this doesn't help for all cases, we need custom build of
  // Bootstrap with styles for printing updated manually by us
  *,
  *:after,
  *:before {
    color: inherit !important;
  }

  // hide ZenDesk widgets
  iframe#launcher,
  iframe#webWidget {
    display: none;
  }
}
