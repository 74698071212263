body.page-type-summer {
  overflow: hidden;
}

body.page-type-summer #content-wrapper {
  background: #fff;
}

/* when we use same animation on the same element,
  browser considers it the same and won't play it */
#switched-content.schedule-visible {
  animation-name: fadeIn;
  animation-duration: 1.5s;
}
#switched-content.programs-visible {
  animation-name: fadeInDuplicate;
  animation-duration: 1.5s;
}
#switched-content.info-visible {
  animation-name: fadeInDuplicate2;
  animation-duration: 1.5s;
}

.portal-header .portal-title {
  margin: 30px 0 10px;
}
.portal-header .portal-description {
  margin-bottom: 10px;
}
.portal-header .portal-description:first-letter {
  text-transform: uppercase;
}

[students-filter] .students {
  width: 100%;
  table-layout: fixed;
}
[students-filter] .students th {
  padding: 0 0 2px 0;
  vertical-align: top;
}
[students-filter] .students th.visible,
[students-filter] .students td.visible {
  width: 25%;
}
[students-filter] .students td {
  padding: 2px 0 2px 0;
}
[students-filter] .students td.name {
  padding-right: 30px;
  padding-left: 15px;
}
[students-filter] .students td.name .age-and-grade {
  font-size: 14px;
  display: inline-block;
}
@media (max-width: 399px) {
 [students-filter] .students td.name {
    padding-right: 10px;
  }
}
[students-filter] .students td.name a {
  float: right;
}
[students-filter] .students .new-student {
  font-size: 14px;
}
[students-filter] .students .new-student td.name {
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 2px solid #91CE91;
}
[students-filter] .students .new-student.empty td.name {
  border-color: transparent;
}
[students-filter] .students .new-student.invalid td.name {
  border-color: #d5908e;
}
[students-filter] .students .new-student .status {
  position: absolute;
  top: 0;
  left: -20px;
  font-size: 16px;
}
[students-filter] .students .new-student .status.valid {
  color: #91ce91;
}
[students-filter] .students .new-student .status.invalid {
  color: #d5908e;
  display: none;
}
[students-filter] .students .new-student.empty .status {
  display: none;
}
[students-filter] .students .new-student.invalid .status.valid {
  display: none;
}
[students-filter] .students .new-student.invalid .status.invalid {
  display: inline-block;
}
[students-filter] .students .new-student .form-group {
  display: block;
  margin-bottom: 5px;
  position: relative;
}
[students-filter] .students .new-student .form-input-error-message {
  left: -15px;
  top: 5px;
}
[students-filter] .students .new-student .form-input-error-message:after {
  display: none;
}
[students-filter] .students .new-student .form-group.grade {
  margin-bottom: 0;
}
[students-filter] .students .new-student .form-group label {
  font-weight: normal;
  margin: 5px 0 0;
}
[students-filter] .students .new-student .form-group .form-control {
  float: right;
  width: 120px;
  max-width: 100%;
  margin: 0;
}
@media (max-width: 399px) {
  [students-filter] .students .new-student .form-group .form-control {
    width: 110px !important;
  }
}
[students-filter] .students .new-student {
  position: relative;
}
[students-filter] .students .new-student .remove {
  position: absolute;
  top: 50%;
  right: -15px;
  margin-top: -10px;
  cursor: pointer;
}
[students-filter] .students .new-student .remove:hover {
  color: red;
}
[students-filter] a.add-student {
  margin-bottom: 10px;
  display: inline-block;
}
[students-filter] .buddies-students {
  font-size: 15px;
}
[students-filter] .buddies-students .students th.name {
  padding: 10px 10px 10px 40px;
  cursor: pointer;
  position: relative;
  line-height: 1em;
}
[students-filter] .buddies-students .students th.name .fa-user-circle {
  position: absolute;
  left: 20px;
  top: 10px;
}
[students-filter] .buddies-students .students th.name a {
  position: absolute;
  left: 0;
}
@media (max-width: 399px) {
 [students-filter] .buddies-students .students th.name {
    padding-right: 30px;
  }
  [students-filter] .buddies-students .students th.name a {
    right: 10px;
  }
}
[students-filter] .buddies-students .students th.actions {
  width: 47%;
  padding-top: 5px;
  font-weight: normal;
}
[students-filter] .buddies-students .students th.actions .btn {
  white-space: normal;
}
[students-filter] .highlighted .table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f9eedb;
}
[students-filter].hide-editing table.students td.name a {
  display: none;
}
[students-filter].hide-adding a.add-student {
  display: none;
}
[students-filter].hide-table-header table .main-table-header {
  display: none;
}
/* end of search filters ==================================================== */

.show-in-schedule-active .schedule-and-map-frame .suggestions-options,
.show-in-schedule-active .schedule-and-map-frame .suggest-new {
  display: none;
}
.schedule-and-map-frame .suggest-new-disabled {
  display: none;
}
.show-in-schedule-active .schedule-and-map-frame .suggest-new-disabled {
  display: block;
}
.show-in-schedule-active .schedule-and-map-frame .schedule-table-wrapper {
  padding-top: 74px;
}
.show-in-schedule-active .schedule-and-map-frame .schedule-table-wrapper.no-dates-header {
  padding-top: 36px;
}

/* end of programs and schedule columns */

.add-friend-friends-table {
  line-height: 1em;
}
.add-friend-friends-table td {
  padding: 5px 0;
}
.add-friend-friends-table .friend-name {
  padding-right: 10px;
}
.add-friend-friends-table .not-shared td {
  padding: 0;
}
.add-friend-friends-table .not-shared .friend-name {
  padding-right: 10px;
}
.add-friend-friends-table .not-shared .friend-name small {
  display: inline-block;
  max-width: 220px;
}

.add-friend-invited-table {
  line-height: 1em;
}
.add-friend-invited-table td {
  padding: 5px 0;
}
.add-friend-invited-table .recipient {
  padding-right: 15px;
}
.add-friend-invited-table .recipient .email {
  word-break: break-all;
  display: inline-block;
}
.add-friend-invited-table .ask {
  padding-right: 15px;
}

body.page-type-summer #footer-wrapper {
  display: none;
}

.modal-tab .provider-info {
  overflow: hidden;
}

/* share schedule modal content */
.share-schedule-listing h4 {
  margin: 3px 0 10px;
  font-weight: 700;
}
.share-schedule-listing h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0 0;
}
.share-schedule-listing .form-inline .radio,
.share-schedule-listing .form-inline .checkbox {
  margin-right: 1em;
  margin-top: 0;
  display: inline-block;
  vertical-align: baseline;
}
/* share schedule buddies */
.buddies-list .buddy .left {
  float: left;
}
.buddies-list .buddy .left > span {
  white-space: nowrap;
}
.buddies-list .buddy .right {
  float: right;
  margin-left: 10px;
}
.buddies-list .buddy .right .btn {
  margin-right: 10px;
}
.buddies-list .buddy .right .ask-to-share-back {
  display: inline-block;
  width: 154px;
}
.buddies-list .add-buddy {
  margin-bottom: 20px;
  position: relative;
}
.buddies-list .add-buddy .left {
  margin-right: 280px;
  position: relative;
}
.buddies-list .add-buddy .left input,
.buddies-list .add-buddy .left select {
  padding-left: 40px;
}
.buddies-list .add-buddy .left select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.buddies-list .add-buddy .left .fa {
  position: absolute;
  top: 12px;
  left: 11px;
}
.buddies-list .add-buddy .left .fa.fa-facebook-square {
  color: #3B5998;
}
.buddies-list .add-buddy .form-control {
  border-color: #74777f;
}
.buddies-list .add-buddy .right {
  position: absolute;
  top: 0;
  right: 0;
  width: 270px;
}
.buddies-list .buddy .label-wrapper {
  display: block;
  line-height: 0;
}
.buddies-list .buddy span.label {
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 0 5px;
  padding: 1px 3px;
  text-transform: none;
  text-align: left;
}
.buddies-list .buddy span.label-success {
  background-color: #6c976d;
}
.buddies-list .buddy span.label-warning {
  background-color: #a6916b;
}
.buddies-list table {
  width: 100%;
}
.buddies-list table tr td {
  padding-bottom: 5px;
}
.buddies-list table tr td.name-col {
  width: 80%;
  position: relative;
}
.buddies-list table tr td.name-col span{
  margin-left: 20px;
}
.buddies-list table tr td.name-col span.small{
  margin-left: 0;
}
.buddies-list table tr td.name-col a {
  color: #808080;
}
.buddies-list table tr td.name-col .dropdown {
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 5px;
}
.buddies-list table tr td.name-col .dropdown .fa-ellipsis-v {
  padding-top: 4px;
  margin-left: -10px;
}
.buddies-list table tr td.action-col {
  width: 20%;
  padding-right: 4px;
}
.buddies-list table tr td.action-col .btn {
  width: 180px;
}
.buddies-list span.error-message {
  color: #a94442;
  margin-top: -15px;
}
.buddies-list span.error-message.facebook {
  margin-top: 2px;
}

.modal-body.share-modal .friend-referral {
  padding: 20px 0;
}
.friend-referral .send-invites-button {
  padding-left: 0;
  padding-right: 0;
}
.friend-referral .send-invites-button .fa {
  font-size: 150%;
}
.friend-referral tags-input.ng-invalid.hide-error .tags {
  border: 1px solid #74777f !important;
}
@media (min-width: 1128px) {
  .friend-referral .send-invites-button .fa {
    top: 12px;
    left: 30px;
  }
}
@media (max-width: 768px) {
  .friend-referral .send-invites-button {
    margin-top: 15px;
    max-width: 100px;
  }
}

@media (max-width: 700px) {
  .buddies-list table tr td.name-col {
    width: 100%;
  }
  .buddies-list table tr td.action-col {
    display: none;
  }
}

@media (max-width: 500px) {
  .buddies-list .add-buddy .left {
    margin-right: 0;
  }
  .buddies-list .add-buddy .right {
    position: static;
    margin-top: 10px;
    width: auto;
  }
}

/* social cue */
.social-cue {
  /* snippet that appears on the program/session modal and at the bottom of
  the program card */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.social-cue-list {
  list-style: none;
  position: relative;
  padding-left: 0.6em;
}
.social-cue-list li {
  margin-bottom: 5px;
}
.social-cue-list li:before {
  content: "\B7";  /* middle dot */
  font-weight: 700;
  position: absolute;
  left: 0;
}
.social-cue-item {
  font-weight: 700;
}
.social-cue-category .row.header {
  background-color: #E5E5E5;
  color: #000;
  font-weight: 700;
  padding: 10px 20px;
  margin: 20px -20px;
  border: 1px solid #E5E5E5;
}
.social-cue-modal hr {
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
}

.social-cue-modal .social-cue-category .child:hover {
  background-color: whitesmoke;
  cursor: pointer;
}
table.student-school {
  width: 90%;
  margin-left: 10px;
}
table.student-school td {
  padding-bottom: 5px;
}
table.student-school td.name {
  width: 35%;
}
table.student-school td.school {
  width: 65%;
}
table.student-school tags-input .tags li {
  margin-top: 4px;
}
table.student-school td .btn {
  margin-top: 20px;
  width: 48%;
  float: left;
  margin-right: 4%;
}
table.student-school td .btn + .btn {
  margin-right: 0;
}
.connect-with-facebook-friends p {
  margin-left: 10px;
}
.connect-with-facebook-friends .container.friend img{
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  background-clip: content-box;
  border: 0 solid transparent;
  border-radius: 50%;
}
.connect-with-facebook-friends .container.facebook-friends-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin: unset;
}
.connect-with-facebook-friends .container.friend {
  width: 250px;
  height: 220px;
  margin: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.connect-with-facebook-friends .container.friend .btn {
  width: 90%;
}
.connect-with-facebook-friends .container.friend span.connected {
  color: #5cb85c;
}

.connect-with-facebook-friends .container.friend span.connected .fa-check {
  font-size: 24px;
}
.connect-with-facebook-friends p {
  text-align: center;
  font-size: 19px;
}
.connect-with-facebook-friends .checkbox-inline {
  padding: 20px 0 20px 45px;
}
/* day selection */
.popover.days-selection .form-group label {
  width: 100%;
}
.popover.days-selection .form-group label:not(.text-muted) {
  cursor: pointer;
}
.popover.days-selection .form-group label:not(.text-muted):hover {
  background-color: #eee;
}
.popover.days-selection .form-group label.text-muted {
  pointer-events: none;
}
.popover.days-selection .form-group label.text-muted input {
  visibility: hidden;
}
.popover.days-selection .btn {
  max-width: none !important;
}
/* styles for printing */
@media print {
  /* hide everything except schedule table  */
  body.page-type-summer #wrapper {
    padding-top: 0;
    display: block;
  }
  body.page-type-summer #header-wrapper {
    display: none;
  }
  body.page-type-summer #content-wrapper {
    display: block;
  }
  body.page-type-summer,
  body.page-type-summer #content {
    overflow: visible !important;
  }

  .filters-panel-wrapper {
    display: none;
  }

}
