@media
(any-pointer: coarse),
not all
    and (hover: hover)
    and (pointer: fine)
    and (min-width: 800px) {

  #goog-gt-tt {
    display: none !important;
  }

  .goog-text-highlight {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

}
