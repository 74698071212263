// When the Google Translate banner is shown (even if "Show original"
// has been clicked and the page is not currently translated) it adds
// "top: 40px" to body style.
body[style*="top: 40px"] {
  --extra-bottom-space: 40px;
}

body > div.skiptranslate > iframe.skiptranslate {
  bottom: 0 !important;
  top: auto !important;
  right: 0;
  left: auto !important;
  min-width: 400px;
}

// Hide the Google Translate banner when printing pages.
@media print {
  body[style*="top: 40px"] {
    --extra-bottom-space: 0;
  }
  body > div.skiptranslate > iframe.skiptranslate {
    display: none;
  }
}
