.simple-children-details-form .simple-children-details-form-row {
  margin: 0 auto;
  max-width: 470px;
  text-align: left;
}
.simple-children-details-form .child-details {
  padding: 10px 0;
  position: relative;
}
.simple-children-details-form .child-details.headings {
  padding: 0;
}
.simple-children-details-form .child-details.headings .grade {
  text-transform: lowercase;
}
.simple-children-details-form .child-details.headings .grade:first-letter {
  text-transform: uppercase;
}
.simple-children-details-form-left-aligned .simple-children-details-form .simple-children-details-form-row,
.simple-children-details-form.simple-children-details-form-left-aligned .simple-children-details-form-row {
  margin: 0;
  max-width: none;
}

.simple-children-details-form .child-details.ng-valid .form-control {
  opacity: 0.9;
}
.simple-children-details-form .child-details .form-control.ng-invalid-required {
  border-color: #00b4e5;
  box-shadow: 0 0 5px #00b4e5;
}
.simple-children-details-form .child-details .form-input-error-message {
  display: none;
  top: -10px;
  left: auto;
}
.simple-children-details-form .child-details .form-input-error-message.for-age {
  right: 0;
}
.simple-children-details-form .child-details .form-input-error-message.for-age:after {
  right: 169px;
}
.simple-children-details-form .children-details.show-errors .child-details.age-invalid:not(.empty) .form-input-error-message.for-age {
  display: block;
}
.simple-children-details-form .children-details.show-errors .child-details.age-invalid:not(.empty) .form-control.ng-invalid {
  background: #ffdada;
  box-shadow: 0 0 5px #c9211e;
  border-color: #a94442;
}
.simple-children-details-form .child-details .first-name {
  float: left;
  display: block;
  margin-right: 15px;
  width: calc(100% - 335px);
}
.simple-children-details-form .child-details .adult {
  float: left;
  display: block;
  margin-right: 15px;
  width: 40px;
  text-align: center;

  input[type=checkbox] {
    transform: scale(1.5);
    margin-top: 12px;
  }
}
.simple-children-details-form .child-details .age {
  float: left;
  display: block;
  width: 90px;
  margin-right: 15px;
}
.simple-children-details-form .child-details .grade {
  float: left;
  display: block;
  width: 155px;
  padding-right: 10px;
}
@media (max-width: 450px) {
  .simple-children-details-form .child-details .form-control {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .simple-children-details-form .child-details .first-name {
    width: calc(100% - 250px);
    margin-right: 10px;
  }
  .simple-children-details-form .child-details .age {
    width: 65px;
    margin-right: 10px;
  }
  .simple-children-details-form .child-details input.age {
    text-align: center;
  }
  .simple-children-details-form .child-details .grade {
    width: 110px;
  }
}
