.front-page-content.front-districts-schools {

  overflow: hidden;

  .single-col {
    display: flex;
    flex-direction: column;
    //max-width: 500px; //960
    max-width: 800px;
    width: 84%;
    margin: auto;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    max-width: 100%;
  }

  .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
  }

  .video-container iframe, .video-container object, .video-container embed {
      position: absolute; top: 0; left: 0; width: 80%; height: 80%;
  }

  .em-strong {
    font-weight:bold;
    font-style:italic;
    color: #3fae2a;
    //color: #f7a802;
    //color:#017549;
    //color:#017549;

  }
  .multi-block-wrapper {

    max-width: 1200px;

    .title {
      max-width: 500px;
    }

    .text-wrapper {
      max-width: 534px;
      margin-bottom: 30px;
    }

    .image {
      .pink-girl-science {
        object-fit: scale-down;
      }

      img.flipped-h {
        transform: scaleX(-1);
      }
    }

    .colored-circles-list {
      margin-bottom: 20px;
    }

  }

  .section-actions {
    margin-top: 0;
  }

  .contact-us-section {
    margin-top: 30px;
    margin-bottom: 80px;
    text-align: center;

    h2 {
      font-size: 28px;
    }
  }

  .every-child-section {
    background: none;
    padding: 0;
  }
}
