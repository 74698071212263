/* provider directory media */
.provider-directory .panel.program {
  .media {
    width: 300px;
    height: 200px;
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;

    @media (max-width: 420px) {
      width: 280px;
      height: 187px;
    }
  }

  .media-summary-container {
    padding-left: 15px;
  }
}
