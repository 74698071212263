@use "abstracts/functions";

.landing-search-buttons {
  max-width: 700px;
  position: relative;
  margin-top: 20px;

  &.horizontal {
    max-width: 100%;
  }

  school-portal-selector .form .btn,
  .location-form-wrapper .btn-custom {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 499px) {
    school-portal-selector .form {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    }

    .location-form-wrapper .btn-custom,
    school-portal-selector .form .btn {
      width: 60px;
      text-indent: -9999px;
      position: relative;
      background-image: url(functions.svg-encode(functions.svg-arrow-right-factory(#fff)));
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 15px 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .location-form-wrapper span[geo-location-input],
    school-portal-selector .form .inputs {
      width: calc(100% - 60px);
      margin-right: 0;
    }
  }

  @media (min-width: 500px) {
    .location-form-wrapper span[geo-location-input],
    school-portal-selector .form .inputs {
      width: calc(100% - 110px);
    }
    .location-form-wrapper .btn-custom,
    school-portal-selector .form .btn {
      width: 100px;
    }
  }
  @media (min-width: 1200px) {
    .location-form-wrapper span[geo-location-input],
    school-portal-selector .form .inputs {
      width: calc(100% - 160px);
    }

    .location-form-wrapper .btn-custom,
    school-portal-selector .form .btn {
      width: 150px;
    }
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    overflow: hidden;

    a:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media (max-width: 499px) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &.horizontal {
      display: flex;
      gap: 15px;
      justify-content: space-evenly;

      @media (max-width: 700px) {
        flex-direction: column;
      }
    }
  }

  .detailed-actions-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    background-color: white;

    &.active {
      opacity: 1;
      z-index: 2;
      transition: opacity 0.25s ease;

      .location-form-wrapper, .school-form-wrapper {
        max-width: 700px;
      }
    }
  }
}
