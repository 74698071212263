@use "abstracts/variables";

svg.svg-social-icon {

  .bg {
    opacity: 0.7;
  }

  .icons {
    transform: translate(-1px, -1px);
  }

  &:hover .icons {
    transform: translate(0, 0);
  }

}

svg.svg-facebook-icon .bg {
  fill: #23599c;
}

svg.svg-twitter-icon .bg {
  fill: #09f;
}

svg.svg-youtube-icon .bg {
  fill: #d11c1c;
}

svg.svg-instagram-icon .bg {
  fill: #e4405f;
}

svg.svg-hamburger-menu-icon {
  width: 30px;
  height: 30px;
  fill: variables.$header-menu-color;
  transition: fill variables.$link-hover-transition-time;

  &:hover {
    fill: variables.$header-menu-color-hover;
  }
}

svg.svg-large-icon,
svg.svg-illustration {
  .bg {
    fill: #ddd;
  }
  .icons {
    fill: #58595b;
  }
}

svg.svg-large-icon-2 {
  .bg {
    fill: #f6a81c;
  }
  .lines {
    fill: none;
    stroke: #58595b;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
  }
}

svg.svg-share-icon,
svg.svg-schedule-icon {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke: #595959;
  fill: none;
}

svg.svg-share-icon {
  width: 38px;
}

svg.svg-schedule-icon {
  width: 34px;
}

svg.svg-rectangles-many rect {
  opacity: 0.5;
}

svg.svg-checkbox {
  width: 18px;
  height: 18px;
  display: inline-block;
  .bg {
    fill: #fff;
  }
  .mark,
  .frame {
    fill: variables.$text-color;
    opacity: 0.7;
  }
  .mark {
    display: none;
  }
}
.svg-checkbox-checked svg.svg-checkbox,
svg.svg-checkbox.svg-checkbox-checked {
  .mark {
    display: block;
  }
}
.svg-checkbox-label:hover svg.svg-checkbox,
svg.svg-checkbox:hover {
  .mark,
  .frame {
    opacity: 1;
  }
}
.svg-checkbox-label {
  cursor: pointer;
}
.svg-checkbox-color-filled.svg-checkbox-checked svg.svg-checkbox,
.svg-checkbox-color-filled svg.svg-checkbox.svg-checkbox-checked,
.svg-checkbox-checked svg.svg-checkbox.svg-checkbox-color-filled,
svg.svg-checkbox.svg-checkbox-color-filled.svg-checkbox-checked {
  .mark {
    fill: #fff;
  }
  .bg,
  .frame {
    fill: variables.$link-color;
  }
  .mark,
  .frame {
    opacity: 1;
  }
}
.svg-checkbox-color-checked.svg-checkbox-checked svg.svg-checkbox,
.svg-checkbox-color-checked svg.svg-checkbox.svg-checkbox-checked,
.svg-checkbox-checked svg.svg-checkbox.svg-checkbox-color-checked,
svg.svg-checkbox.svg-checkbox-color-checked.svg-checkbox-checked {
  .mark,
  .frame {
    fill: variables.$link-color;
    opacity: 1;
  }
}

svg.svg-filters-icon {
  width: 20px;
  fill: variables.$text-color;
}
