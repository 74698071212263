@use "abstracts/mixins";

.testimonials-page-content {
  font-size: 16px;
  line-height: 28px;

  .testimonials-page-header {
    padding-top: 40px;
    margin-bottom: 20px;
  }

  .testimonials-page-title {
    font-size: 32px;
    line-height: 38px;
    font-weight: normal;
    padding: 0 25px;
    margin: 0 auto;
    max-width: 530px;
    text-align: center;
  }

  .testimonials-page-icon {
    text-align: center;
    position: relative;
    top: 20px;

    svg {
      width: 60px;
      display: inline-block;
    }
  }

  .testimonials-container {
    padding: 0 25px 100px;
    max-width: 530px;

    .read-more {
      margin-top: 50px;

      .colored-circles-list {
        margin-top: 10px;
      }

    }
  }

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 29px;

    .testimonials-page-header {
      padding-top: 80px;
      margin-bottom: 70px;
    }

    .testimonials-page-icon {
      top: 50px;
    }

    .testimonials-page-title {
      font-size: 36px;
      line-height: 42px;
    }

    .testimonials-container {
      padding-bottom: 120px;

      .read-more {
        margin-top: 80px;
      }
    }
  }

  @media (min-width: 992px) {

    .testimonials-page-header {
      padding-top: 150px;
      padding-bottom: 40px;
    }

    .testimonials-page-icon {
      top: 70px;

      svg {
        width: 100px;
      }
    }

    .testimonials-page-title {
      font-size: 48px;
      line-height: 56px;
      max-width: 580px;
    }

    .testimonials-container {
      padding-bottom: 120px;
      max-width: 940px;

      .read-more {
        margin-top: 80px;
        @include mixins.clearfix;

        .intro {
          float: left;
          width: 300px;
        }

        .colored-circles-list {
          float: right;
          width: calc(100% - 330px);
          margin-top: 0;
        }
      }
    }
  }

}
