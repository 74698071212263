@use 'abstracts/variables';
@use 'components/base/buttons/types/x-button-mixins';
@use 'components/base/buttons/types/unstyled-button-mixins';

.ec-toast-container {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999999999;

  > :not(:last-child) {
    margin-bottom: 15px;
  }
}

.toast {
  // important! is needed because .show class sets display: block !important
  display: grid !important;
  grid-template-rows: 0fr;
  width: 350px;
  color: #ffffff;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: variables.$base-border-radius;
  text-decoration: none;
  background: darkgray;

  .btn-close {
    @include unstyled-button-mixins.unstyled-button;
    @include x-button-mixins.x-button-inverse;
  }

  .toast-header,
  .toast-body {
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;

    .btn-close {
      margin: -8px -18px -8px 10px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .toast-header {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &.show {
    opacity: 1;
    grid-template-rows: 1fr;
  }

  &.showing {
    opacity: 0;
    grid-template-rows: 1fr;
  }

  &.error {
    background: #BD362F;
  }

  &.warning {
    background: #F89406;
  }

  &.info {
    background: #2F96B4;
  }

  &.success {
    background: #51A351;
  }
}
