@use "sass:color";
@use "abstracts/mixins";
@use "abstracts/variables";

@mixin ec-theme($theme-color, $theme-color-light) {
  &.svg-bg-illustration,
  .svg-bg-illustration,
  &.svg-large-icon .bg,
  .svg-large-icon .bg,
  &.svg-large-icon-2 .bg,
  .svg-large-icon-2 .bg,
  &.svg-illustration .bg,
  .svg-illustration .bg {
    fill: $theme-color;
  }

  &.light-carousel .carousel .carousel-indicators li.active:after,
  .light-carousel .carousel .carousel-indicators li.active:after {
    background-color: $theme-color;
  }

  &.colored-circles-list > li:before,
  .colored-circles-list > li:before {
    background-color: $theme-color;
  }

  &.simple-chart .chart-row .chart-line-fill:before,
  .simple-chart .chart-row .chart-line-fill:before {
    background-color: color.adjust($theme-color, $lightness: -20%);
  }

  &.background-light,
  .background-light {
    background-color: $theme-color-light;
  }

  &.bottom-waved-background,
  .bottom-waved-background {
    @include mixins.bottom-waved-background($theme-color-light);
  }

  &.bottom-waved-inverse-background,
  .bottom-waved-inverse-background {
    @include mixins.bottom-waved-inverse-background($theme-color-light);
  }

  &.top-bottom-same-waved-background,
  .top-bottom-same-waved-background {
    @include mixins.top-bottom-same-waved-background($theme-color-light);
  }
}

.ec-theme-blue {
  @include ec-theme(variables.$theme-color-blue, variables.$theme-color-blue-light);
}

.ec-theme-yellow {
  @include ec-theme(variables.$theme-color-yellow, variables.$theme-color-yellow-light);
}

.ec-theme-green {
  @include ec-theme(variables.$theme-color-green, variables.$theme-color-green-light);
}

.ec-theme-pink {
  @include ec-theme(variables.$theme-color-pink, variables.$theme-color-pink-light);
}
