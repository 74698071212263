@use "abstracts/variables";

.title-underblock {
  position: relative;
  padding-bottom: 0.6em;

  &:after {
    content:'';
    display: block;
    width: 50px;
    height: 3px;
    background-color: transparent;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
}

.title-underblock.text-center:after,
.text-center > .title-underblock:after  {
  left: 50%;
  margin-left: -25px;
}

.title-underblock.text-right:after,
.text-right > .title-underblock:after{
  left: auto;
  right: 0;
}

.title-bottom-border {
  border-bottom: 1px dashed transparent;

  &.dashed {
    border-bottom-style: dashed;
  }

  &.dotted {
    border-bottom-style: dotted;
  }
}

// CUSTOM
.title-bottom-border.custom {
  border-color: variables.$text-muted-color;
}
.title-underblock.custom:after {
  background-color: variables.$text-muted-color;
}
.title-text-color-custom {
  color: variables.$text-muted-color;
}

// DARK
.title-bottom-border.dark {
  border-color: variables.$dark-text-color;
}
.title-underblock.dark:after {
  background-color: variables.$dark-text-color;
}
.title-text-color-dark {
  color: variables.$dark-text-color;
}


.title-block {
  background: #F5F5F5;
  padding: 10px 15px;
}

.title-block > .light,
.title-bottom-border > .light,
.title-underblock > .light {
  font-weight: normal;
}
