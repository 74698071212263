@use "sass:color";

.btn.btn-as-checkbox {

  .btn-as-checkbox__checked-icon {
    display: none;
  }

  .btn-as-checkbox__unchecked-icon {
    display: inline-block;
  }

  &.checked {
    .btn-as-checkbox__checked-icon {
      display: inline-block;
    }

    .btn-as-checkbox__unchecked-icon {
      display: none;
    }
  }

  @mixin btn-as-checkbox-flavor($color) {
    &:hover,
    &:active {
      color: color.adjust($color, $lightness: -10%);
      border-color: color.adjust($color, $lightness: -10%);
      background-color: inherit;
    }

    &.checked {
      border-color: $color;
      background-color: $color;
      color: #fff;

      &:hover,
      &:active {
        border-color: color.adjust($color, $lightness: -10%);
        background-color: color.adjust($color, $lightness: -10%);
      }
    }
  }

  &.btn-as-checkbox-positive {
    @include btn-as-checkbox-flavor(#27AE60);
  }

  &.btn-as-checkbox-negative {
    @include btn-as-checkbox-flavor(#828282);
  }
}
