body.portal-restricted {

  #header li.header-signup {
    display: none;
  }

}

body.portal-restricted:not(.page-type-summer) {

  .header-referral {
    display: block;
  }

  .header-logo-container .logo-wrapper {
    display: none;
  }

}
