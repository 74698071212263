@use "abstracts/variables";
@use "buttons/types/x-button-mixins";

.modal {
  bottom: var(--extra-bottom-space, 0);
}

.modal-header {
	padding:22px 20px;
	position: relative;
	border-bottom: none;
  border-radius: variables.$base-border-radius variables.$base-border-radius 0 0;
}

.modal-header,
.modal-footer {
	background-color: #2a2a2a;
	color:#eaeaea;
}

.modal-header h2,
.modal-header h3,
.modal-header h4 {
	color:#eaeaea;
}

.modal-body {
	padding: 30px 20px;
}

.modal-footer {
	padding:15px 20px;
  border-radius: 0 0 variables.$base-border-radius variables.$base-border-radius;
}

.modal-footer.text-center {
	text-align: center;
}

.modal-footer.text-left {
	text-align: left;
}

.modal-content {
	background-color: #fafafa;
	border: 1px solid #2a2a2a;
	border-radius: variables.$base-border-radius;
	box-shadow: none;
	-webkit-box-shadow: none;
}

.modal .input-group:last-child {
	margin-bottom: 0;
}

.modal-open,
.modal-open-old {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-backdrop.dark {
  background-color: #333;
}
.modal-backdrop.dark.in {
  opacity: .95;
}

@media (min-width: 768px) {
  .modal-dialog {
    margin: 20px auto;
  }
}
.modal-content {
  border: 1px solid #707070;
  background-color: #fff;
}
.modal-header {
  background-color: #F5F5F5;
  color: #505050;
  border-bottom: 1px solid #E5E5E5;
  &.plain {
    background-color: #ffffff;
    border-bottom: none;
    padding-bottom: 0;
    h1, h2, h3, h4 {
      color: #505050;
      margin-bottom: 8px;
    }
  }
}
.modal-header .close {
  @include x-button-mixins.x-button;
  & {
    background-position: center;
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0;
    text-shadow: none;
  }

  &:focus {
    box-shadow: variables.$control-focus-box-shadow;
  }

  * {
    display: none;
  }
}
.modal-header .modal-title {
  padding-right: 40px;
  color: #505050;
  margin: 0;
  line-height: 1.42857143;

  .share-icon-wrapper {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-right: 10px;

    svg.svg-share-icon {
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }
}
.modal-header .modal-title .light {
  font-weight: 400;
}
.modal-footer {
  background-color: #F5F5F5;
  color: #505050;
  border-top: 1px solid #E5E5E5;
}
@media (max-width: 767px) {
  .modal-footer .col-xs-3,
  .modal-footer .col-xs-4 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.modal-footer .btn {
  margin-bottom: 5px;
}
.modal-footer .btn + .btn {
  margin-bottom: 5px;
}
.modal-footer .equal-width-buttons {
  display: inline-grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 5px;

  .btn + .btn {
    margin-left: 0;
  }
}
.modal-body {
  padding: 20px;
}
.modal-body.with-tabs {
  padding: 0;
}
.modal-body.with-tabs > .nav-pills {
  margin: 0;
}
.modal-body.with-tabs .modal-tab {
  padding: 20px;
}
/* customizing the content nav tab for the modals (program details, share-template, and provider's program modal)*/
.modal-header.with-tabs .content-nav {
  background-color: #f5f5f5;
  margin: 0;
}
.modal-header.with-tabs {
  border-bottom: none;
  padding: 22px 0 0 0;
}
.modal-header.with-tabs .modal-title {
  margin-left: 22px;
  padding-bottom: 15px;
}

@media (min-width: 768px) { @media not all and (min-width: 992px) {
  .modal-lg {
    width: 700px;
  }
}}

@media (min-width: 768px) {
  .modal-xl95 {
    width: 95%;
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .modal-dialog {
    margin: 0;
  }

  .modal-header.with-tabs .modal-title {
    padding-bottom: 5px;
  }
  .modal-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.modal-body .form-group:last-child .input-group .form-control {
  margin-bottom: 0;
}

/* ------------------- full size modal ------------------------------------- */
.full-size-modal .modal-dialog,
.modal-dialog.full-size-modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.full-size-modal .modal-content,
.modal-dialog.full-size-modal-dialog .modal-content {
  height: 100%;
  border-radius: 0;
}
.full-size-modal .modal-body,
.modal-dialog.full-size-modal-dialog .modal-body {
  height: calc(100% - 172px);
  overflow: auto;
}
@media (max-width: 767px) {
  .full-size-modal .modal-body,
  .modal-dialog.full-size-modal-dialog .modal-body {
    height: calc(100% - 161px);
  }
}
.full-size-modal.narrow .modal-body.edit-mode {
  height: calc(100% - 193px);
}
.full-size-modal .modal-header,
.modal-dialog.full-size-modal-dialog .modal-header {
  height: 95px;
}
.full-size-modal.narrow .modal-dialog {
  width: 80%;
  left: 10vw;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------------------- */

/* ------------------- auth modal ------------------------------------------ */
.auth-modal .modal-dialog {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.auth-modal .modal-dialog.modal-sm,
.auth-modal.modal-dialog.modal-sm {
  width: 400px;
}
@media (max-width: 767px) {
  .auth-modal .modal-dialog,
  .auth-modal.modal-dialog,
  .auth-modal .modal-dialog.modal-sm,
  .auth-modal.modal-dialog.modal-sm {
    width: 100%;
  }
}
.auth-modal .modal-content {
  background-color: #f5f5f5;
}
.auth-modal .modal-header {
  background: transparent;
  border-bottom: none;
  min-height: 70px;
}
.auth-modal .modal-body {
  padding-top: 0;
}
.auth-modal .modal-dialog .modal-body {
  .auth-container,
  .redirect-info-container {
    max-width: 350px;
    margin: 0 auto;
  }
}
@media (min-width: 600px) {
  .auth-modal .modal-dialog:not(.modal-sm) .modal-body {
    .auth-container,
    .redirect-info-container {
      width: 50%;
    }
    .auth-container {
      float: right;
      padding-left: 20px;
    }
    .redirect-info-container {
      float: left;
      padding-right: 20px;
    }
  }
}
.auth-modal .modal-body .auth-container {
  font-size: 14px;
  color: #999;

  .auth-embed-mode-info {
    position: relative;
    top: -15px;
    font-size: 16px;
  }
}
.auth-modal .modal-body .auth-title {
  font-size: 22px;
  margin-bottom: 25px;
  font-weight: normal;
  color: variables.$text-color;

  .auth-logo {
    width: 110px;
    vertical-align: baseline;
    position: relative;
    top: 1px;
  }
}
.auth-modal .modal-body .auth-intro {
  margin: -10px 0 20px;
}
.auth-modal .modal-body .first-last-name-wrapper {
  .first-name-wrapper {
    padding-right: 5px;
  }
  .last-name-wrapper {
    padding-left: 5px;
  }
  .form-control {
    margin-bottom: 0;
  }
}
.auth-modal .modal-body .forgot-password-link-container {
  text-align: right;
}
.auth-modal .modal-body .divider {
  margin-top: 30px;
  margin-bottom: 20px;

  > span {
    font-size: 14px;
    background: #f5f5f5;
    border: none;
    border-radius: 0;
    width: 80px;
  }
}
.auth-modal .modal-body .form-intro {
  margin-bottom: 5px;
}
.auth-modal .modal-body .mode-switcher {
  margin-top: 15px;

  a {
    font-weight: bold;
    margin-left: 5px;
  }
}
.auth-modal .modal-body .alert {
  font-size: 16px;
}

.auth-modal .modal-body .redirect-info-container .main-info {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 30px;
}
.auth-modal .modal-body .redirect-info-container .no-thanks {
  font-size: 16px;
  margin-bottom: 30px;
}
/* ------------------- auth modal ------------------------------------------ */

/* -------------------  confirminator modal  ------------------------------- */
.modal-backdrop.confirminator {
  background-color: variables.$link-color;
}
.modal-backdrop.confirminator.in {
  opacity: 1;
  top: 0;
  z-index: 2040 !important;
}
.modal.confirminator {
  top: 0;
  z-index: 2050 !important;
  padding: 0 10px;
  background-color: variables.$link-color;
}
.modal.confirminator .modal-dialog {
  margin: 100px auto 30px;
}
.modal.confirminator.fade .modal-dialog {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.modal.confirminator.in .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal.confirminator .modal-dialog .modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.modal.confirminator .modal-dialog .modal-footer .btn {
  width: 80px;
}
/* ------------------------------------------------------------------------- */

/* ------------------- video embed modal ----------------------------------- */
.video-embed-modal {

  .modal-content {
    border: none;
    background: none;
    margin-top: 30px;
  }

  button.close-button {
    float: right;
  }

  .modal-title {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
    float: left;
    color: #ddd;
  }

  .embed-responsive {
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    text-align: center;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }

    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }

    .modal-content {
      margin: 0;
    }
  }
}
/* ------------------------------------------------------------------------- */

/* ------------------- roster modals --------------------------------------- */
.session-modal-window-narrow {

  .modal-dialog {
    width: 80%;
    left: 10%;
    height: 100%;
    margin: 0;
    padding: 0;

    @media (max-width: 1300px)  {
      width: 90%;
      left: 5%;
    }

    @media (max-width: 767px) {
      width: 100%;
      left: 0;
    }
  }

  .modal-header {
    height: 145px;
  }

  .modal-body {
    height: calc(100% - 221px);
  }
}

.session-modal-window-narrow.roster .modal-body,
.add-session-modal.modal-body.roster {
  height: calc(100% - 145px);
}
.add-session-modal.modal-body.edit {
  height: calc(100% - 211px);
}


.program-modal-window {

  .modal-header {
    height: 95px;
  }

  .modal-body.edit-mode {
    height: calc(100% - 187px);
  }

  .content-nav .content-nav-tab {

    @media (max-width: 660px) {
      span.optional {
        display: none;
      }
    }

    @media (max-width: 440px) {
      span.photos {
        display: none;
      }
    }

    @media (min-width: 441px) {
      span.media {
        display: none;
      }
    }
  }
}

.modal-header.add-session-modal {
  display: flex;
  height: 155px;
}
