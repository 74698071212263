.media-play-icon {
  font-size: 30px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 0;
  vertical-align: super;

  &:before {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    left: 0;
    top: -0.5em;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.3em 0 0.3em 0.5em;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    top: -0.3em;
    left: 0.333333em;
  }
}
