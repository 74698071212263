.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: wrap;
}

.parent-height {
  height: 100%;
}

.force-parent-width {
  width: 100% !important;
}
