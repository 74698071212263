@use "abstracts/variables";

// Content tabs ============================================================ //
.content-nav {
  width: 100%;
  height: 45px;
  margin: 0 0 5px;
  padding: 0 0 1px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.content-nav:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 15;
  background-color: #ccc;
}
.content-nav .content-nav-tab {
  display: block;
  float: left;
  overflow: hidden;
  margin: 0;
  height: 45px;
  position: relative;
  z-index: 20;
  padding-left: 3px;
}
.content-nav .content-nav-tab:first-child {
  padding-left: 0;
}
.content-nav .content-nav-tab:after {
  content: "";
  display: block;
  height: 4px;
  width: 0;
  margin: -4px auto 0;
  background-color: transparent;
  transition: width 1s;
}
.content-nav.with-2-tabs .content-nav-tab {
  width: 50%;
}
.content-nav.with-3-tabs .content-nav-tab {
  width: 33.3333333%;
}
.content-nav.with-4-tabs .content-nav-tab {
  width: 25%;
}

.content-nav .content-nav-tab .btn,
.content-nav .content-nav-tab a {
  display: flex;
  justify-content: center;
  padding: 4px 10px 0;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-nav .content-nav-tab.active .btn,
.content-nav .content-nav-tab.active a {
  cursor: default;
}
.content-nav .content-nav-tab:hover .btn,
.content-nav .content-nav-tab:hover a,
.content-nav .content-nav-tab.active .btn,
.content-nav .content-nav-tab.active a {
  color: variables.$text-color;
  text-decoration: none;
}
.content-nav .content-nav-tab.active .btn:focus {
  text-decoration: underline;
}
.content-nav .content-nav-tab.active:after,
.content-nav .content-nav-tab:hover:after {
  width: 100%;
  background-color: variables.$active-tab-border;
}
.main-container-wrapper > .content-nav {
  top: -40px;
}
@media (min-width: 768px) {
  .main-container-wrapper > .content-nav {
    top: -60px;
  }
}
.main-container-wrapper.no-top-padding > .content-nav {
  top: 0;
}
.content-nav .content-nav-tab a .ec-spinner {
  font-size: 22px;
}

// Nav tabs ================================================================ //
.nav-tabs {
  border-bottom: 1px solid variables.$section-border-color;

  > li {
    float: left;
    margin: 0 3px -1px 0;

    > a,
    > .a {
      margin: 0;
      border-radius: 0;
      border: none;
      color: variables.$tab-color;
      transition: background 0.35s ease, color 0.04s;
      -webkit-transition: background 0.35s ease, color 0.04s;
      padding: 10px 20px;
      cursor: pointer;
      line-height: 1.42857143;

      &:hover,
      &:focus {
        border: none;
        background-color: transparent;
        color: variables.$active-tab-color;
      }
    }

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 0;
      margin: 0 auto;
      background-color: transparent;
      transition: width 1s;
    }
  }

  > li.active {
    > a,
    > a:hover,
    > a:focus,
    > .a,
    > .a:hover,
    > .a:focus {
      color: variables.$active-tab-color;
      border: none;
      margin: 0;
      background-color: transparent;
      cursor: default;
    }
  }

  > li.active:after,
  > li:hover:after {
    width: 100%;
    background-color: variables.$active-tab-border;
  }
}

.nav-tabs.nav-justified {
  border-bottom: 1px solid variables.$section-border-color;
}
.nav-tabs.nav-justified > li > a {
  border: none;
}
.nav-tabs.nav-justified > li:after {
  position: relative;
  top: 1px;
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover {
  border: none;
}
