@use "functions";
@use "variables";

/// Helper to clear inner floats
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
@mixin fading-to-white {
  &::after {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 20%, #fff 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 20%, #fff 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, #fff 100%);
  }
}
@mixin fading-gradient {
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    pointer-events: none;
  }
}

@mixin limit-viewable-height($max-height) {
  max-height: $max-height;
  overflow: hidden;
  position: relative;
}

@mixin left-right-padding($padding) {
  padding-left: $padding!important;
  padding-right: $padding!important;
}

@mixin centered-no-repeat-background($path) {
  background-image: url($path);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@mixin waved-background($fill-color) {
  background-color: $fill-color;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin top-waved-background($fill-color, $bg-color: #fff) {
  background-image: url(functions.svg-encode(functions.svg-wave-top-factory($fill-color, $bg-color)));
  background-position: top center;
  @include waved-background($fill-color);
}

@mixin bottom-waved-background($fill-color, $bg-color: #fff) {
  background-image: url(functions.svg-encode(functions.svg-wave-bottom-factory($fill-color, $bg-color)));
  background-position: bottom center;
  @include waved-background($fill-color);
}

@mixin bottom-waved-inverse-background($fill-color, $bg-color: #fff) {
  background-image: url(functions.svg-encode(functions.svg-wave-bottom-inverse-factory($fill-color, $bg-color)));
  background-position: bottom center;
  @include waved-background($fill-color);
}

@mixin top-bottom-waved-background($fill-color, $bg-color: #fff) {
  background-image:
    url(functions.svg-encode(functions.svg-wave-top-factory($fill-color, $bg-color))),
    url(functions.svg-encode(functions.svg-wave-bottom-factory($fill-color, $bg-color)));
  background-position:
    top center,
    bottom center;
  @include waved-background($fill-color);
}

@mixin top-bottom-same-waved-background($fill-color, $bg-color: #fff) {
  background-image:
    url(functions.svg-encode(functions.svg-wave-bottom-factory($bg-color, $fill-color))),
    url(functions.svg-encode(functions.svg-wave-bottom-factory($fill-color, $bg-color)));
  background-position:
    top center,
    bottom center;
  @include waved-background($fill-color);
}

@mixin hidden-width-down($width) {
  @media (max-width: $width) {
    display: none !important;
  }
}
@mixin hidden-width-up($width) {
  @media (min-width: $width) {
    display: none!important;
  }
}

@mixin skeleton-animated-item {
  // this adds GPU acceleration
  transform: translate3d(0, 0, 0);
  position: relative;
  background-color: variables.$skeleton-base-color;
  overflow: hidden;

  &:before {
    content: '';
    width: 100px;
    height: 100%;
    background-size: 100px 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-image:
      linear-gradient(90deg, transparent, rgba(255,255,255,0.6), transparent);
    background-repeat: no-repeat;
    animation: shineLines 2s ease-in-out infinite;

    // Removing animation if user enabled the `Reduce Motion` option
    // via operation system and/or browser
    // More details about `prefers-reduced-motion` in https://web.dev/prefers-reduced-motion/
    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

// Hide content visually while keeping it accessible to assistive technologies.
// Copied from bootstrap v5: {
@mixin visually-hidden() {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;

  &:not(caption) {
    position: absolute !important;
  }
}
// }
