ec-single-media-of-program-or-provider {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #F2F2F2;

  iframe,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: center top;

    &.logo {
      margin: 0;
      background-color: #fff;
      object-fit: contain;
    }
  }
}
