@use "sass:math";
@use 'abstracts/variables';

.time-input-clock {
  $angle: 30deg;
  $n: 12;
  $size: 2.5em;
  $hour-raduis: 2.8 * $size;
  $minute-raduis: $hour-raduis - 1.1 * $size;
  $hour-size: 2 * $hour-raduis + $size;
  $minute-size: 2 * $minute-raduis + $size;
  $ampm-size: $size * 2;

  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 101;
  border: variables.$raised-widget-border;
  background: #fff;
  box-shadow: variables.$raised-widget-box-shadow;
  padding: 8px;
  border-radius: variables.$base-border-radius;
  align-items: center;
  // width: 100%;
  min-width: calc(#{$hour-size} + 22px);
  bottom: calc(-#{$hour-size} - 24px);

  .mask {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: var(--extra-bottom-space, 0);
  }

  .clock-face {
    position: relative;
    display: block;
    width: calc(#{$hour-size} + 6px);
    height: calc(#{$hour-size} + 6px);
  }

  .hours, .minutes, .ampm {
    display: flex;
    z-index: 1;
    position: absolute;
    border-radius: 50%;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $size;
      height: $size;
      cursor: pointer;
      border-radius: 50%;

      &.selected {
        background-color: variables.$link-color !important;
        color: #fff;
        z-index: 2;

        &:hover {
          color: #fafafa;
          background-color: variables.$link-hover-color !important;
        }
      }
    }
  }

  .hours, .minutes {
    & > * {
      position: absolute;
      top: calc(50% - #{math.div($size, 2)});
      left: calc(50% - #{math.div($size, 2)});
    }
  }

  .minutes, .ampm {
    & > * {
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }

  .hours {
    width: calc(#{$hour-size} + 6px);
    height: calc(#{$hour-size} + 6px);
    background: #f7f7f7;
    top: 0;
    left: 0;

    & > * {
      &:hover {
        background-color: #eaeaea;
      }
    }
  }

  .minutes {
    width: calc(#{$minute-size} + 6px);
    height: calc(#{$minute-size} + 6px);
    background: #f0f0f0;
    top: calc( 50% - #{math.div($minute-size, 2)} - 3px);
    left: calc( 50% - #{math.div($minute-size, 2)} - 3px);
  }

  .ampm {
    flex-direction: column;
    align-items: center;
    width: $ampm-size;
    height: $ampm-size;
    top: calc( 50% - #{math.div($ampm-size, 2)});
    left: calc( 50% - #{math.div($ampm-size, 2)});
  }

  @for $i from 0 to $n {
    .hours > *:nth-child(#{$i + 1}) {
      $rot: $i * $angle;
      transform: rotate($rot) translate(0, -$hour-raduis) rotate(-$rot);
    }
  }

  @for $i from 0 to $n {
    .minutes > *:nth-child(#{$i + 1}) {
      $rot: $i * $angle;
      transform: rotate($rot) translate(0, -$minute-raduis) rotate(-$rot);
    }
  }
}

.time-input-toggle {
  position: relative;
  max-width: variables.$date-and-time-inputs-max-width;

  &.input-group {
    width:100%;
  }

  .form-control {
    border-radius: variables.$form-control-border-radius !important;
  }

  .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 0 variables.$base-border-radius variables.$base-border-radius 0;
    z-index: 3;
    height: 100%;
    width: auto;
    padding: 0;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
