.user-is-interested-feedback {
  max-width: 510px;
  margin-bottom: 10px;

  label small {
    font-weight: normal;
  }

  .buttons {
    text-align: right;

    .fa-circle-o-notch {
      display: none;
    }
  }

  &.processing .buttons .btn {
    width: 40px;
    height: 40px;
    padding: 0;

    .text {
      display: none;
    }
    .fa-circle-o-notch {
      display: inline-block;
    }
  }
}
