.iti-flag-for-country-input {
  display: inline-block;
  margin-right: 5px;
}

.country-input {
  position: relative;

  > input.country-selected {
    padding-left: 44px;
  }

  > .iti__flag {
    position: absolute;
    left: 16px;
    top: 14px;
  }
}
