.list-style {
	padding-left:20px;
}

.list-style.list-disc {
	list-style-type: disc;
}

.list-style.list-circle {
	list-style-type: circle;
}

.list-style.list-square {
	list-style-type: square;
}

.list-style.list-decimal {
	list-style-type: decimal;
}

.list-style.list-lower-alpha {
	list-style-type: lower-alpha;
}

.list-style.list-upper-alpha {
	list-style-type: upper-alpha;
}

.list-style.list-lower-roman {
	list-style-type: lower-roman;
}

.list-style.list-upper-roman {
	list-style-type: upper-roman;
}

.fa-ul > li {
	margin-bottom:10px;
}

.fa-ul {
	margin-left:20px;
}

.fa-li {
	top:.2785714em;
}

.list-group {
	margin-bottom: 20px;
}

.list-group-item {
	padding: 10px 15px;
}

a.list-group-item {
	color: #505050;
}

a.list-group-item .list-group-item-heading {
	color: #303030;
	margin-bottom:10px;
}

a.list-group-item:hover,
a.list-group-item:focus {
	background-color: #f4f4f4;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #fff;
}

.colored-circles-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    display: grid;
    grid-template-columns: 25px auto;
    align-items: baseline;

    > * {
      grid-column: 2;
    }

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border: 2px solid #595959;
      border-radius: 10px;
      background-color: #ddd;
      grid-column: 1;
    }
  }

  &.colored-circles-list-small > li:before {
    width: 8px;
    height: 8px;
    position: relative;
    top: -2px;
  }
}

ul.chips li {
  display: inline-block;
  color: #595959;
  font-size: 14px;
  line-height: 18px;
  background: #ddd;
  border-radius: 3px;
  margin: 1px 3px 1px 0;
  padding: 0 5px 0 5px;
  height: 20px;
}
