@use "sass:map";
$font-awesome-icons: (
  '500px': "\f26e",
  'address-book': "\f2b9",
  'address-book-o': "\f2ba",
  'address-card': "\f2bb",
  'address-card-o': "\f2bc",
  'adjust': "\f042",
  'adn': "\f170",
  'align-center': "\f037",
  'align-justify': "\f039",
  'align-left': "\f036",
  'align-right': "\f038",
  'amazon': "\f270",
  'ambulance': "\f0f9",
  'american-sign-language-interpreting': "\f2a3",
  'anchor': "\f13d",
  'android': "\f17b",
  'angellist': "\f209",
  'angle-double-down': "\f103",
  'angle-double-left': "\f100",
  'angle-double-right': "\f101",
  'angle-double-up': "\f102",
  'angle-down': "\f107",
  'angle-left': "\f104",
  'angle-right': "\f105",
  'angle-up': "\f106",
  'apple': "\f179",
  'archive': "\f187",
  'area-chart': "\f1fe",
  'arrow-circle-down': "\f0ab",
  'arrow-circle-left': "\f0a8",
  'arrow-circle-o-down': "\f01a",
  'arrow-circle-o-left': "\f190",
  'arrow-circle-o-right': "\f18e",
  'arrow-circle-o-up': "\f01b",
  'arrow-circle-right': "\f0a9",
  'arrow-circle-up': "\f0aa",
  'arrow-down': "\f063",
  'arrow-left': "\f060",
  'arrow-right': "\f061",
  'arrow-up': "\f062",
  'arrows': "\f047",
  'arrows-alt': "\f0b2",
  'arrows-h': "\f07e",
  'arrows-v': "\f07d",
  'asl-interpreting': "\f2a3",
  'assistive-listening-systems': "\f2a2",
  'asterisk': "\f069",
  'at': "\f1fa",
  'audio-description': "\f29e",
  'automobile': "\f1b9",
  'backward': "\f04a",
  'balance-scale': "\f24e",
  'ban': "\f05e",
  'bandcamp': "\f2d5",
  'bank': "\f19c",
  'bar-chart': "\f080",
  'bar-chart-o': "\f080",
  'barcode': "\f02a",
  'bars': "\f0c9",
  'bath': "\f2cd",
  'bathtub': "\f2cd",
  'battery': "\f240",
  'battery-0': "\f244",
  'battery-1': "\f243",
  'battery-2': "\f242",
  'battery-3': "\f241",
  'battery-4': "\f240",
  'battery-empty': "\f244",
  'battery-full': "\f240",
  'battery-half': "\f242",
  'battery-quarter': "\f243",
  'battery-three-quarters': "\f241",
  'bed': "\f236",
  'beer': "\f0fc",
  'behance': "\f1b4",
  'behance-square': "\f1b5",
  'bell': "\f0f3",
  'bell-o': "\f0a2",
  'bell-slash': "\f1f6",
  'bell-slash-o': "\f1f7",
  'bicycle': "\f206",
  'binoculars': "\f1e5",
  'birthday-cake': "\f1fd",
  'bitbucket': "\f171",
  'bitbucket-square': "\f172",
  'bitcoin': "\f15a",
  'black-tie': "\f27e",
  'blind': "\f29d",
  'bluetooth': "\f293",
  'bluetooth-b': "\f294",
  'bold': "\f032",
  'bolt': "\f0e7",
  'bomb': "\f1e2",
  'book': "\f02d",
  'bookmark': "\f02e",
  'bookmark-o': "\f097",
  'braille': "\f2a1",
  'briefcase': "\f0b1",
  'btc': "\f15a",
  'bug': "\f188",
  'building': "\f1ad",
  'building-o': "\f0f7",
  'bullhorn': "\f0a1",
  'bullseye': "\f140",
  'bus': "\f207",
  'buysellads': "\f20d",
  'cab': "\f1ba",
  'calculator': "\f1ec",
  'calendar': "\f073",
  'calendar-check-o': "\f274",
  'calendar-minus-o': "\f272",
  'calendar-o': "\f133",
  'calendar-plus-o': "\f271",
  'calendar-times-o': "\f273",
  'camera': "\f030",
  'camera-retro': "\f083",
  'car': "\f1b9",
  'caret-down': "\f0d7",
  'caret-left': "\f0d9",
  'caret-right': "\f0da",
  'caret-square-o-down': "\f150",
  'caret-square-o-left': "\f191",
  'caret-square-o-right': "\f152",
  'caret-square-o-up': "\f151",
  'caret-up': "\f0d8",
  'cart-arrow-down': "\f218",
  'cart-plus': "\f217",
  'cc': "\f20a",
  'cc-amex': "\f1f3",
  'cc-diners-club': "\f24c",
  'cc-discover': "\f1f2",
  'cc-jcb': "\f24b",
  'cc-mastercard': "\f1f1",
  'cc-paypal': "\f1f4",
  'cc-stripe': "\f1f5",
  'cc-visa': "\f1f0",
  'certificate': "\f0a3",
  'chain': "\f0c1",
  'chain-broken': "\f127",
  'check': "\f00c",
  'check-circle': "\f058",
  'check-circle-o': "\f05d",
  'check-square': "\f14a",
  'check-square-o': "\f046",
  'chevron-circle-down': "\f13a",
  'chevron-circle-left': "\f137",
  'chevron-circle-right': "\f138",
  'chevron-circle-up': "\f139",
  'chevron-down': "\f078",
  'chevron-left': "\f053",
  'chevron-right': "\f054",
  'chevron-up': "\f077",
  'child': "\f1ae",
  'chrome': "\f268",
  'circle': "\f111",
  'circle-o': "\f10c",
  'circle-o-notch': "\f1ce",
  'circle-thin': "\f1db",
  'clipboard': "\f0ea",
  'clock-o': "\f017",
  'clone': "\f24d",
  'close': "\f00d",
  'cloud': "\f0c2",
  'cloud-download': "\f0ed",
  'cloud-upload': "\f0ee",
  'cny': "\f157",
  'code': "\f121",
  'code-fork': "\f126",
  'codepen': "\f1cb",
  'codiepie': "\f284",
  'coffee': "\f0f4",
  'cog': "\f013",
  'cogs': "\f085",
  'columns': "\f0db",
  'comment': "\f075",
  'comment-o': "\f0e5",
  'commenting': "\f27a",
  'commenting-o': "\f27b",
  'comments': "\f086",
  'comments-o': "\f0e6",
  'compass': "\f14e",
  'compress': "\f066",
  'connectdevelop': "\f20e",
  'contao': "\f26d",
  'copy': "\f0c5",
  'copyright': "\f1f9",
  'creative-commons': "\f25e",
  'credit-card': "\f09d",
  'credit-card-alt': "\f283",
  'crop': "\f125",
  'crosshairs': "\f05b",
  'css3': "\f13c",
  'cube': "\f1b2",
  'cubes': "\f1b3",
  'cut': "\f0c4",
  'cutlery': "\f0f5",
  'dashboard': "\f0e4",
  'dashcube': "\f210",
  'database': "\f1c0",
  'deaf': "\f2a4",
  'deafness': "\f2a4",
  'dedent': "\f03b",
  'delicious': "\f1a5",
  'desktop': "\f108",
  'deviantart': "\f1bd",
  'diamond': "\f219",
  'digg': "\f1a6",
  'dollar': "\f155",
  'dot-circle-o': "\f192",
  'download': "\f019",
  'dribbble': "\f17d",
  'drivers-license': "\f2c2",
  'drivers-license-o': "\f2c3",
  'dropbox': "\f16b",
  'drupal': "\f1a9",
  'edge': "\f282",
  'edit': "\f044",
  'eercast': "\f2da",
  'eject': "\f052",
  'ellipsis-h': "\f141",
  'ellipsis-v': "\f142",
  'empire': "\f1d1",
  'envelope': "\f0e0",
  'envelope-o': "\f003",
  'envelope-open': "\f2b6",
  'envelope-open-o': "\f2b7",
  'envelope-square': "\f199",
  'envira': "\f299",
  'eraser': "\f12d",
  'etsy': "\f2d7",
  'eur': "\f153",
  'euro': "\f153",
  'exchange': "\f0ec",
  'exclamation': "\f12a",
  'exclamation-circle': "\f06a",
  'exclamation-triangle': "\f071",
  'expand': "\f065",
  'expeditedssl': "\f23e",
  'external-link': "\f08e",
  'external-link-square': "\f14c",
  'eye': "\f06e",
  'eye-slash': "\f070",
  'eyedropper': "\f1fb",
  'fa': "\f2b4",
  'facebook': "\f09a",
  'facebook-f': "\f09a",
  'facebook-official': "\f230",
  'facebook-square': "\f082",
  'fast-backward': "\f049",
  'fast-forward': "\f050",
  'fax': "\f1ac",
  'feed': "\f09e",
  'female': "\f182",
  'fighter-jet': "\f0fb",
  'file': "\f15b",
  'file-archive-o': "\f1c6",
  'file-audio-o': "\f1c7",
  'file-code-o': "\f1c9",
  'file-excel-o': "\f1c3",
  'file-image-o': "\f1c5",
  'file-movie-o': "\f1c8",
  'file-o': "\f016",
  'file-pdf-o': "\f1c1",
  'file-photo-o': "\f1c5",
  'file-picture-o': "\f1c5",
  'file-powerpoint-o': "\f1c4",
  'file-sound-o': "\f1c7",
  'file-text': "\f15c",
  'file-text-o': "\f0f6",
  'file-video-o': "\f1c8",
  'file-word-o': "\f1c2",
  'file-zip-o': "\f1c6",
  'files-o': "\f0c5",
  'film': "\f008",
  'filter': "\f0b0",
  'fire': "\f06d",
  'fire-extinguisher': "\f134",
  'firefox': "\f269",
  'first-order': "\f2b0",
  'flag': "\f024",
  'flag-checkered': "\f11e",
  'flag-o': "\f11d",
  'flash': "\f0e7",
  'flask': "\f0c3",
  'flickr': "\f16e",
  'floppy-o': "\f0c7",
  'folder': "\f07b",
  'folder-o': "\f114",
  'folder-open': "\f07c",
  'folder-open-o': "\f115",
  'font': "\f031",
  'font-awesome': "\f2b4",
  'fonticons': "\f280",
  'fort-awesome': "\f286",
  'forumbee': "\f211",
  'forward': "\f04e",
  'foursquare': "\f180",
  'free-code-camp': "\f2c5",
  'frown-o': "\f119",
  'futbol-o': "\f1e3",
  'gamepad': "\f11b",
  'gavel': "\f0e3",
  'gbp': "\f154",
  'ge': "\f1d1",
  'gear': "\f013",
  'gears': "\f085",
  'genderless': "\f22d",
  'get-pocket': "\f265",
  'gg': "\f260",
  'gg-circle': "\f261",
  'gift': "\f06b",
  'git': "\f1d3",
  'git-square': "\f1d2",
  'github': "\f09b",
  'github-alt': "\f113",
  'github-square': "\f092",
  'gitlab': "\f296",
  'gittip': "\f184",
  'glass': "\f000",
  'glide': "\f2a5",
  'glide-g': "\f2a6",
  'globe': "\f0ac",
  'google': "\f1a0",
  'google-plus': "\f0d5",
  'google-plus-circle': "\f2b3",
  'google-plus-official': "\f2b3",
  'google-plus-square': "\f0d4",
  'google-wallet': "\f1ee",
  'graduation-cap': "\f19d",
  'gratipay': "\f184",
  'grav': "\f2d6",
  'group': "\f0c0",
  'h-square': "\f0fd",
  'hacker-news': "\f1d4",
  'hand-grab-o': "\f255",
  'hand-lizard-o': "\f258",
  'hand-o-down': "\f0a7",
  'hand-o-left': "\f0a5",
  'hand-o-right': "\f0a4",
  'hand-o-up': "\f0a6",
  'hand-paper-o': "\f256",
  'hand-peace-o': "\f25b",
  'hand-pointer-o': "\f25a",
  'hand-rock-o': "\f255",
  'hand-scissors-o': "\f257",
  'hand-spock-o': "\f259",
  'hand-stop-o': "\f256",
  'handshake-o': "\f2b5",
  'hard-of-hearing': "\f2a4",
  'hashtag': "\f292",
  'hdd-o': "\f0a0",
  'header': "\f1dc",
  'headphones': "\f025",
  'heart': "\f004",
  'heart-o': "\f08a",
  'heartbeat': "\f21e",
  'history': "\f1da",
  'home': "\f015",
  'hospital-o': "\f0f8",
  'hotel': "\f236",
  'hourglass': "\f254",
  'hourglass-1': "\f251",
  'hourglass-2': "\f252",
  'hourglass-3': "\f253",
  'hourglass-end': "\f253",
  'hourglass-half': "\f252",
  'hourglass-o': "\f250",
  'hourglass-start': "\f251",
  'houzz': "\f27c",
  'html5': "\f13b",
  'i-cursor': "\f246",
  'id-badge': "\f2c1",
  'id-card': "\f2c2",
  'id-card-o': "\f2c3",
  'ils': "\f20b",
  'image': "\f03e",
  'imdb': "\f2d8",
  'inbox': "\f01c",
  'indent': "\f03c",
  'industry': "\f275",
  'info': "\f129",
  'info-circle': "\f05a",
  'inr': "\f156",
  'instagram': "\f16d",
  'institution': "\f19c",
  'internet-explorer': "\f26b",
  'intersex': "\f224",
  'ioxhost': "\f208",
  'italic': "\f033",
  'joomla': "\f1aa",
  'jpy': "\f157",
  'jsfiddle': "\f1cc",
  'key': "\f084",
  'keyboard-o': "\f11c",
  'krw': "\f159",
  'language': "\f1ab",
  'laptop': "\f109",
  'lastfm': "\f202",
  'lastfm-square': "\f203",
  'leaf': "\f06c",
  'leanpub': "\f212",
  'legal': "\f0e3",
  'lemon-o': "\f094",
  'level-down': "\f149",
  'level-up': "\f148",
  'life-bouy': "\f1cd",
  'life-buoy': "\f1cd",
  'life-ring': "\f1cd",
  'life-saver': "\f1cd",
  'lightbulb-o': "\f0eb",
  'line-chart': "\f201",
  'link': "\f0c1",
  'linkedin': "\f0e1",
  'linkedin-square': "\f08c",
  'linode': "\f2b8",
  'linux': "\f17c",
  'list': "\f03a",
  'list-alt': "\f022",
  'list-ol': "\f0cb",
  'list-ul': "\f0ca",
  'location-arrow': "\f124",
  'lock': "\f023",
  'long-arrow-down': "\f175",
  'long-arrow-left': "\f177",
  'long-arrow-right': "\f178",
  'long-arrow-up': "\f176",
  'low-vision': "\f2a8",
  'magic': "\f0d0",
  'magnet': "\f076",
  'mail-forward': "\f064",
  'mail-reply': "\f112",
  'mail-reply-all': "\f122",
  'male': "\f183",
  'map': "\f279",
  'map-marker': "\f041",
  'map-o': "\f278",
  'map-pin': "\f276",
  'map-signs': "\f277",
  'mars': "\f222",
  'mars-double': "\f227",
  'mars-stroke': "\f229",
  'mars-stroke-h': "\f22b",
  'mars-stroke-v': "\f22a",
  'maxcdn': "\f136",
  'meanpath': "\f20c",
  'medium': "\f23a",
  'medkit': "\f0fa",
  'meetup': "\f2e0",
  'meh-o': "\f11a",
  'mercury': "\f223",
  'microchip': "\f2db",
  'microphone': "\f130",
  'microphone-slash': "\f131",
  'minus': "\f068",
  'minus-circle': "\f056",
  'minus-square': "\f146",
  'minus-square-o': "\f147",
  'mixcloud': "\f289",
  'mobile': "\f10b",
  'mobile-phone': "\f10b",
  'modx': "\f285",
  'money': "\f0d6",
  'moon-o': "\f186",
  'mortar-board': "\f19d",
  'motorcycle': "\f21c",
  'mouse-pointer': "\f245",
  'music': "\f001",
  'navicon': "\f0c9",
  'neuter': "\f22c",
  'newspaper-o': "\f1ea",
  'object-group': "\f247",
  'object-ungroup': "\f248",
  'odnoklassniki': "\f263",
  'odnoklassniki-square': "\f264",
  'opencart': "\f23d",
  'openid': "\f19b",
  'opera': "\f26a",
  'optin-monster': "\f23c",
  'outdent': "\f03b",
  'pagelines': "\f18c",
  'paint-brush': "\f1fc",
  'paper-plane': "\f1d8",
  'paper-plane-o': "\f1d9",
  'paperclip': "\f0c6",
  'paragraph': "\f1dd",
  'paste': "\f0ea",
  'pause': "\f04c",
  'pause-circle': "\f28b",
  'pause-circle-o': "\f28c",
  'paw': "\f1b0",
  'paypal': "\f1ed",
  'pencil': "\f040",
  'pencil-square': "\f14b",
  'pencil-square-o': "\f044",
  'percent': "\f295",
  'phone': "\f095",
  'phone-square': "\f098",
  'photo': "\f03e",
  'picture-o': "\f03e",
  'pie-chart': "\f200",
  'pied-piper': "\f2ae",
  'pied-piper-alt': "\f1a8",
  'pied-piper-pp': "\f1a7",
  'pinterest': "\f0d2",
  'pinterest-p': "\f231",
  'pinterest-square': "\f0d3",
  'plane': "\f072",
  'play': "\f04b",
  'play-circle': "\f144",
  'play-circle-o': "\f01d",
  'plug': "\f1e6",
  'plus': "\f067",
  'plus-circle': "\f055",
  'plus-square': "\f0fe",
  'plus-square-o': "\f196",
  'podcast': "\f2ce",
  'power-off': "\f011",
  'print': "\f02f",
  'product-hunt': "\f288",
  'puzzle-piece': "\f12e",
  'qq': "\f1d6",
  'qrcode': "\f029",
  'question': "\f128",
  'question-circle': "\f059",
  'question-circle-o': "\f29c",
  'quora': "\f2c4",
  'quote-left': "\f10d",
  'quote-right': "\f10e",
  'ra': "\f1d0",
  'random': "\f074",
  'ravelry': "\f2d9",
  'rebel': "\f1d0",
  'recycle': "\f1b8",
  'reddit': "\f1a1",
  'reddit-alien': "\f281",
  'reddit-square': "\f1a2",
  'refresh': "\f021",
  'registered': "\f25d",
  'remove': "\f00d",
  'renren': "\f18b",
  'reorder': "\f0c9",
  'repeat': "\f01e",
  'reply': "\f112",
  'reply-all': "\f122",
  'resistance': "\f1d0",
  'retweet': "\f079",
  'rmb': "\f157",
  'road': "\f018",
  'rocket': "\f135",
  'rotate-left': "\f0e2",
  'rotate-right': "\f01e",
  'rouble': "\f158",
  'rss': "\f09e",
  'rss-square': "\f143",
  'rub': "\f158",
  'ruble': "\f158",
  'rupee': "\f156",
  's15': "\f2cd",
  'safari': "\f267",
  'save': "\f0c7",
  'scissors': "\f0c4",
  'scribd': "\f28a",
  'search': "\f002",
  'search-minus': "\f010",
  'search-plus': "\f00e",
  'sellsy': "\f213",
  'send': "\f1d8",
  'send-o': "\f1d9",
  'server': "\f233",
  'share': "\f064",
  'share-alt': "\f1e0",
  'share-alt-square': "\f1e1",
  'share-square': "\f14d",
  'share-square-o': "\f045",
  'shekel': "\f20b",
  'sheqel': "\f20b",
  'shield': "\f132",
  'ship': "\f21a",
  'shirtsinbulk': "\f214",
  'shopping-bag': "\f290",
  'shopping-basket': "\f291",
  'shopping-cart': "\f07a",
  'shower': "\f2cc",
  'sign-in': "\f090",
  'sign-language': "\f2a7",
  'sign-out': "\f08b",
  'signal': "\f012",
  'signing': "\f2a7",
  'simplybuilt': "\f215",
  'sitemap': "\f0e8",
  'skyatlas': "\f216",
  'skype': "\f17e",
  'slack': "\f198",
  'sliders': "\f1de",
  'slideshare': "\f1e7",
  'smile-o': "\f118",
  'snapchat': "\f2ab",
  'snapchat-ghost': "\f2ac",
  'snapchat-square': "\f2ad",
  'snowflake-o': "\f2dc",
  'soccer-ball-o': "\f1e3",
  'sort': "\f0dc",
  'sort-alpha-asc': "\f15d",
  'sort-alpha-desc': "\f15e",
  'sort-amount-asc': "\f160",
  'sort-amount-desc': "\f161",
  'sort-asc': "\f0de",
  'sort-desc': "\f0dd",
  'sort-down': "\f0dd",
  'sort-numeric-asc': "\f162",
  'sort-numeric-desc': "\f163",
  'sort-up': "\f0de",
  'soundcloud': "\f1be",
  'space-shuttle': "\f197",
  'spinner': "\f110",
  'spoon': "\f1b1",
  'spotify': "\f1bc",
  'square': "\f0c8",
  'square-o': "\f096",
  'stack-exchange': "\f18d",
  'stack-overflow': "\f16c",
  'star': "\f005",
  'star-half': "\f089",
  'star-half-empty': "\f123",
  'star-half-full': "\f123",
  'star-half-o': "\f123",
  'star-o': "\f006",
  'steam': "\f1b6",
  'steam-square': "\f1b7",
  'step-backward': "\f048",
  'step-forward': "\f051",
  'stethoscope': "\f0f1",
  'sticky-note': "\f249",
  'sticky-note-o': "\f24a",
  'stop': "\f04d",
  'stop-circle': "\f28d",
  'stop-circle-o': "\f28e",
  'street-view': "\f21d",
  'strikethrough': "\f0cc",
  'stumbleupon': "\f1a4",
  'stumbleupon-circle': "\f1a3",
  'subscript': "\f12c",
  'subway': "\f239",
  'suitcase': "\f0f2",
  'sun-o': "\f185",
  'superpowers': "\f2dd",
  'superscript': "\f12b",
  'support': "\f1cd",
  'table': "\f0ce",
  'tablet': "\f10a",
  'tachometer': "\f0e4",
  'tag': "\f02b",
  'tags': "\f02c",
  'tasks': "\f0ae",
  'taxi': "\f1ba",
  'telegram': "\f2c6",
  'television': "\f26c",
  'tencent-weibo': "\f1d5",
  'terminal': "\f120",
  'text-height': "\f034",
  'text-width': "\f035",
  'th': "\f00a",
  'th-large': "\f009",
  'th-list': "\f00b",
  'themeisle': "\f2b2",
  'thermometer': "\f2c7",
  'thermometer-0': "\f2cb",
  'thermometer-1': "\f2ca",
  'thermometer-2': "\f2c9",
  'thermometer-3': "\f2c8",
  'thermometer-4': "\f2c7",
  'thermometer-empty': "\f2cb",
  'thermometer-full': "\f2c7",
  'thermometer-half': "\f2c9",
  'thermometer-quarter': "\f2ca",
  'thermometer-three-quarters': "\f2c8",
  'thumb-tack': "\f08d",
  'thumbs-down': "\f165",
  'thumbs-o-down': "\f088",
  'thumbs-o-up': "\f087",
  'thumbs-up': "\f164",
  'ticket': "\f145",
  'times': "\f00d",
  'times-circle': "\f057",
  'times-circle-o': "\f05c",
  'times-rectangle': "\f2d3",
  'times-rectangle-o': "\f2d4",
  'tint': "\f043",
  'toggle-down': "\f150",
  'toggle-left': "\f191",
  'toggle-off': "\f204",
  'toggle-on': "\f205",
  'toggle-right': "\f152",
  'toggle-up': "\f151",
  'trademark': "\f25c",
  'train': "\f238",
  'transgender': "\f224",
  'transgender-alt': "\f225",
  'trash': "\f1f8",
  'trash-o': "\f014",
  'tree': "\f1bb",
  'trello': "\f181",
  'tripadvisor': "\f262",
  'trophy': "\f091",
  'truck': "\f0d1",
  'try': "\f195",
  'tty': "\f1e4",
  'tumblr': "\f173",
  'tumblr-square': "\f174",
  'turkish-lira': "\f195",
  'tv': "\f26c",
  'twitch': "\f1e8",
  'twitter': "\f099",
  'twitter-square': "\f081",
  'umbrella': "\f0e9",
  'underline': "\f0cd",
  'undo': "\f0e2",
  'universal-access': "\f29a",
  'university': "\f19c",
  'unlink': "\f127",
  'unlock': "\f09c",
  'unlock-alt': "\f13e",
  'unsorted': "\f0dc",
  'upload': "\f093",
  'usb': "\f287",
  'usd': "\f155",
  'user': "\f007",
  'user-circle': "\f2bd",
  'user-circle-o': "\f2be",
  'user-md': "\f0f0",
  'user-o': "\f2c0",
  'user-plus': "\f234",
  'user-secret': "\f21b",
  'user-times': "\f235",
  'users': "\f0c0",
  'vcard': "\f2bb",
  'vcard-o': "\f2bc",
  'venus': "\f221",
  'venus-double': "\f226",
  'venus-mars': "\f228",
  'viacoin': "\f237",
  'viadeo': "\f2a9",
  'viadeo-square': "\f2aa",
  'video-camera': "\f03d",
  'vimeo': "\f27d",
  'vimeo-square': "\f194",
  'vine': "\f1ca",
  'vk': "\f189",
  'volume-control-phone': "\f2a0",
  'volume-down': "\f027",
  'volume-off': "\f026",
  'volume-up': "\f028",
  'warning': "\f071",
  'wechat': "\f1d7",
  'weibo': "\f18a",
  'weixin': "\f1d7",
  'whatsapp': "\f232",
  'wheelchair': "\f193",
  'wheelchair-alt': "\f29b",
  'wifi': "\f1eb",
  'wikipedia-w': "\f266",
  'window-close': "\f2d3",
  'window-close-o': "\f2d4",
  'window-maximize': "\f2d0",
  'window-minimize': "\f2d1",
  'window-restore': "\f2d2",
  'windows': "\f17a",
  'won': "\f159",
  'wordpress': "\f19a",
  'wpbeginner': "\f297",
  'wpexplorer': "\f2de",
  'wpforms': "\f298",
  'wrench': "\f0ad",
  'xing': "\f168",
  'xing-square': "\f169",
  'y-combinator': "\f23b",
  'y-combinator-square': "\f1d4",
  'yahoo': "\f19e",
  'yc': "\f23b",
  'yc-square': "\f1d4",
  'yelp': "\f1e9",
  'yen': "\f157",
  'yoast': "\f2b1",
  'youtube': "\f167",
  'youtube-play': "\f16a",
  'youtube-square': "\f166"
);

// this mixin allows using FontAwesome on some elements that for some reason
// can not have fa classes added in HTML code (conditional styling or styling
// of pseudo elements (:after, :before)).
@mixin font-awesome($icon) {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "#{map.get($font-awesome-icons, $icon)}";

  &:before {
    content: "#{map.get($font-awesome-icons, $icon)}";
  }
}

@mixin fa-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%;
}

