.front-page-content.front-parents {
  overflow: hidden;

  .top-section {
    // top-section can include different things depending on the user login
    // status. should keep the height fixed to avoid the following sections
    // jump
    min-height: 600px;

    .multi-block-wrapper {
      margin-bottom: 0;
    }
  }

  .single-col {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: auto;

    img {
      margin-left: auto;
      margin-right: auto;
      height: auto;
      max-width: 100%;
    }
    .search, .upcoming-classes {
      margin-bottom: 40px;
      @media (max-width: 1100px) {
        margin-left: 10%;
        margin-right: 10%;
      }
    }

    .upcoming-classes {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        h2 {
          margin-bottom: 0;
        }

        .fa {
          font-size: 80%;
        }
      }
    }
  }

  .value-propositions-section {
    padding-bottom: 100px;
  }

}
