@use "sass:meta";
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@include meta.load-css('animations/fade-in');
@include meta.load-css('animations/fade-in-duplicate');
@include meta.load-css('animations/fade-in-duplicate2');
@include meta.load-css('animations/fade-in-right');
@include meta.load-css('animations/fade-in-right-short');
@include meta.load-css('animations/fade-in-down');
@include meta.load-css('animations/fade-in-up');

@include meta.load-css('animations/fade-out');
@include meta.load-css('animations/fade-out-right');

@include meta.load-css('animations/zoom-in');
@include meta.load-css('animations/zoom-in-bounced');
@include meta.load-css('animations/zoom-in-start-default-size');

@include meta.load-css('animations/zoom-out');

@include meta.load-css('animations/shine-lines');

@include meta.load-css('animations/provider-top-to-bottom');
@include meta.load-css('animations/provider-bottom-to-top-inverted');
@include meta.load-css('animations/zoom-in-cart-items-number');
