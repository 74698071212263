@mixin narrow-container {
  padding-left: 25px;
  padding-right: 25px;

  @media (min-width: 768px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.narrow-container {
  @include narrow-container;
}

@media (min-width: 1280px) {
	.container {
		width: 1200px;
	}
}
