.pac-container {
  width: 550px !important;
  max-width: 75% !important;
  z-index: 10000 !important;
}
.custom-prediction-label {
  font-weight: bold;
}

[g-places-autocomplete-drawer].after-input {
  position: absolute;

  .pac-container {
    position: static !important;
  }
}
