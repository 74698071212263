@use "abstracts/variables";
@use "base/forms-mixins";

@media (max-width: 767px) {
  .value-propositions-wrapper {
    text-align: center;
  }
}

.value-propositions {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  .value-proposition-wrapper {
    float: left;
    clear: both;

    .value-proposition {
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    .value-proposition-wrapper {
      float: none;
      flex-basis: calc(100% / 2);
      padding-bottom: 70px;

      .value-proposition {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  @media (min-width: 992px) {
    margin-left: 50px;
    margin-right: 50px;

    .value-proposition-wrapper {
      flex-basis: calc(100% / 3.01);

      .value-proposition {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  @media (min-width: 1200px) {
    margin-left: 100px;
    margin-right: 100px;
  }

  &.four-in-a-row {
    @media (min-width: 992px) {
      margin-left: -80px;
      margin-right: -80px;

      .value-proposition-wrapper {
        flex-basis: calc(100% / 4);

        .value-proposition {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    @media (min-width: 1200px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.value-proposition {
  position: relative;
  min-height: 40px;
  padding: 0 0 0 70px;
  text-align: left;
  max-width: 540px;
  margin: 0 auto 40px;

  .value-proposition-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .value-proposition-title {
    font-size: 18px;
    line-height: 1.2em;
    color: variables.$dark-text-color;
    display: table-cell;
    height: 40px;
    vertical-align: middle;
  }

  .value-proposition-content {
    margin: 0;
  }

  @media (min-width: 576px) {
    padding: 0 70px;
  }

  @media (min-width: 768px) {
    @include forms-mixins.form-control-decoration;
    padding: 65px 25px 45px;
    margin-bottom: 0;

    .value-proposition-icon {
      width: 80px;
      height: 80px;
      top: -45px;
      left: 20px;
    }

    .value-proposition-title {
      font-size: 20px;
      display: block;
      height: auto;
    }
  }

  @media (min-width: 992px) {
    .value-proposition-title {
      font-size: 22px;
    }
  }
}
