@use "abstracts/variables";

.alert {
  border-radius: variables.$base-border-radius;
}

.alerts-stack {
  position: relative;
  white-space: normal;
  background-color: rgba(255,255,255,0.5);
}
.alerts-stack .close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 40px;
  height: 30px;
  padding-top: 3px;
  text-align: center;
  background: #FFF;
  border-bottom: 1px solid rgb(170, 170, 170);
  border-left: 1px solid rgb(170, 170, 170);
  border-radius: 0 0 0 4px;
}
.alerts-stack .alert.details {
  padding: 0;
}
.alerts-stack .alert:last-child {
  margin-bottom: 0;
}
.alerts-combined .alert:not(:last-child) {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.alerts-combined .alert:last-child {
  border-top: 0;
  padding-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.alert.alert-help {
  color: #31708f;
  background-color: #f0f6f9;
  border-color: #e1f0f3;
  padding-left: 55px;
  font-size: 0.9em;
  position: relative;
  margin-bottom: 10px;
}
.alert.alert-help:before {
  content: "\f059";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 10px;
  left: 15px;
  color: #87b2c8;
}

.alert.alert-success .alert-link {
  color: #519e52;
}
.alert.alert-success .alert-link:hover {
  color: #2b542c;
}
.alert.alert-info .alert-link {
  color: #59abd4;
}
.alert.alert-info .alert-link:hover {
  color: #245269;
}

.alert-warning {
  color: variables.$alert-warning-color;
  background-color: variables.$alert-warning-bg;
  border-color: variables.$alert-warning-border;
}
