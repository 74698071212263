// five columns grid
.row-5-cols-xs {
  .col-xs-1 {
    width: 10%;
  }
  .col-xs-2 {
    width: 20%;
  }
  .col-xs-3 {
    width: 30%;
  }
  .col-xs-4 {
    width: 40%;
  }
  .col-xs-5 {
    width: 50%;
  }
  .col-xs-6 {
    width: 60%;
  }
  .col-xs-7 {
    width: 70%;
  }
  .col-xs-8 {
    width: 80%;
  }
  .col-xs-9 {
    width: 90%;
  }
  .col-xs-10 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .row-5-cols-md {
    .col-md-1 {
      width: 10%;
    }
    .col-md-2 {
      width: 20%;
    }
    .col-md-3 {
      width: 30%;
    }
    .col-md-4 {
      width: 40%;
    }
    .col-md-5 {
      width: 50%;
    }
    .col-md-6 {
      width: 60%;
    }
    .col-md-7 {
      width: 70%;
    }
    .col-md-8 {
      width: 80%;
    }
    .col-md-9 {
      width: 90%;
    }
    .col-md-10 {
      width: 100%;
    }
  }
}
