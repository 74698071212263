@use "sass:color";
@use "abstracts/variables";
@use "mixins";

.btn-yellow {
  $color-1: color.adjust(variables.$theme-color-yellow, $lightness: -10%);
  $color-2: color.adjust(variables.$theme-color-yellow, $lightness: -20%);
  $color-3: color.adjust(variables.$theme-color-yellow, $lightness: -25%);

  @include mixins.button-color(
    $color-1, $color-1, $color-2, $color-2
  );
  @include mixins.button-color-border(
    $color-3, $color-3, $color-2, $color-2
  );

  &.btn.btn-border {
    .media-play-icon:before {
      background-color: $color-1;
    }
  }
}
