@use "portals-common";

body.page-type-provider-page {
  @include portals-common.portals-common;
  @include portals-common.portal-with-page-header;
  @include portals-common.disable-suggestions-feature;
}

// do not show provider info on provider portals
body.page-type-provider-page .schedule-cell.suggested .session-details .provider {
  display: none;
}
