@use 'buttons/sizes-mixins';
@use 'buttons/variables';


@mixin btn-group-base {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .btn {
    position: relative;
    flex: 1 1 auto;
  }
}

@mixin btn-group {
  @include btn-group-base;

  > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .btn:nth-child(n+3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Vertical button group
@mixin btn-group-vertical {
  @include btn-group-base;
  & {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  > .btn {
    width: 100%;
    // We are repeating default button border radius here in case this mixin
    // is used on element that already has btn-group class.
    border-radius: variables.$button-border-radius;
    // Same for margin.
    margin: 0;
  }

  > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .btn ~ .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  > .btn:not(:first-child) {
    margin-top: -1px;
  }
}



// Small button group
@mixin btn-group-sm {
  > .btn {
    @include sizes-mixins.btn-sm;
  }
  > .btn.btn-border {
    @include sizes-mixins.btn-sm-border;
  }
  > .btn.btn-border-light {
    @include sizes-mixins.btn-sm-border-light;
  }
}
// Small padding button group
@mixin btn-group-sm-padding {
  > .btn {
    @include sizes-mixins.btn-sm-padding;
  }
  .btn.btn-border {
    @include sizes-mixins.btn-sm-padding-border;
  }
  > .btn.btn-border-light {
    @include sizes-mixins.btn-sm-padding-border-light;
  }
}


// Extra small button group
@mixin btn-group-xs {
  > .btn {
    @include sizes-mixins.btn-xs;
  }
  > .btn.btn-border {
    @include sizes-mixins.btn-xs-border;
  }
  > .btn.btn-border-light {
    @include sizes-mixins.btn-xs-border-light;
  }
}
// Extra small padding button group
@mixin btn-group-xs-padding {
  > .btn {
    @include sizes-mixins.btn-xs-padding;
  }
  > .btn.btn-border {
    @include sizes-mixins.btn-xs-padding-border;
  }
  > .btn.btn-border-light {
    @include sizes-mixins.btn-xs-padding-border-light;
  }
}
