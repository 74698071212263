@use "abstracts/variables";

// should be used on .calendar-input
@mixin style-calendar-picker-button-as-text {
  display: inline !important;
  margin: 0;

  .calendar-picker-btn,
  .input-group-btn .btn {
    border: 0;
    background: transparent;
    box-shadow: none;
    display: inline;
    color: variables.$link-color;
    width: auto;
    height: auto;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    vertical-align: inherit;

    &:hover {
      color: variables.$link-hover-color;
    }
  }

  .input-group-btn {
    width: auto;
    display: inline;
    vertical-align: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .calendar-picker-btn {
    .calendar-text {
      position: static;
    }

    .fa {
      display: none;
    }

    .full {
      span {
        display: none;
      }
      strong {
        font-weight: normal;
      }
      strong:first-child:after {
        content: " - ";
      }
    }
  }

  .input-group-btn .btn {
    strong {
      font-weight: normal;
    }

    .fa {
      display: none;
    }
  }
}
