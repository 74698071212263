@use "abstracts/functions";
@use "abstracts/variables";

@mixin x-button {
  background-image: url(functions.svg-encode(functions.svg-cross-out-factory(#000)));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  transition: opacity variables.$link-hover-transition-time;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
}

@mixin x-button-inverse {
  @include x-button;
  & {
    background-image: url(functions.svg-encode(functions.svg-cross-out-factory(#fff)));
  }
}
