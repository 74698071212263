.front-page-content.front-homeschool {
  .hero-blocks-section {
    overflow: hidden;
    position: relative;

    .svg-bg-illustration {
      width: 180px;
      position: absolute;
      left: 0;
      top: 70px;

      @media (max-width: 1100px) {
        left: -30px;
      }

      @media (max-width: 992px) {
        width: 110px;
      }

      @media (max-width: 850px) {
        left: -60px;
      }

      @media (max-width: 767px) {
        width: 70px;
        left: auto;
        right: -20px;
        top: 10px;
      }
    }
  }
}
