/* when we use same animation on the same element,
  browser considers it the same and won't play it */
@keyframes fadeInDuplicate2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
