@use "mixins";

.btn-info {
  @include mixins.button-color(
    #5bc0de, #46b8da, #31b0d5, #269abc
  );
  @include mixins.button-color-border(
    #2196f3, #46b8da, #0c7cd5, #0a68b4
  );
}
