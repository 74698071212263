@use "abstracts/variables";

body {
  // body is needed to have higher priority over default angular material styles

  .mat-mdc-form-field {
    font-family: inherit;
    font-size: 14px;
    line-height: 1.42857;
    --mdc-shape-small: 0;
    --mat-form-field-container-height: auto;

    &.mat-form-field-appearance-outline {

      .mdc-text-field--outlined {
        padding: 0 16px;
        --mdc-outlined-text-field-outline-color: #{variables.$form-control-border-color};
        --mdc-outlined-text-field-focus-outline-color: #{variables.$form-control-focus-border-color};
        --mdc-outlined-text-field-hover-outline-color: #{variables.$form-control-border-color};
        --mdc-outlined-text-field-error-outline-color: #{variables.$form-error-color};
        --mdc-outlined-text-field-outline-width: 1px;
        --mdc-outlined-text-field-focus-outline-width: 1px;
        --mdc-outlined-text-field-container-shape: #{variables.$form-control-border-radius};
      }

      .mat-mdc-form-field-infix {
        padding: 10px 0;
      }

      .mdc-notched-outline {
        border-radius: variables.$form-control-border-radius;
        transition: box-shadow 0.4s ease;
      }

    }

    &.mat-form-field-appearance-outline.mat-focused {
      .mdc-notched-outline {
        box-shadow: variables.$control-focus-box-shadow;
      }
    }
  }

  .has-error .mat-mdc-form-field.mat-form-field-appearance-outline,
  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid {
    .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-color: #{variables.$form-error-color};
      --mdc-outlined-text-field-focus-outline-color: #{variables.$form-error-color};
    }
  }
}
