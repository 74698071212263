@use "abstracts/variables";

/* Copied from theme */

.popover {
	box-shadow:none;
	-webkit-box-shadow:none;
	z-index: 900;
	border: 1px solid transparent;
	border-color: #dcdcdc;
	background-color: #fff;
	border-radius:0;
	padding:0;
	font-family: inherit;
	min-width: 270px;
}

.popover-title {
	padding: 10px 14px 9px;
	margin: 0;
	font:700 14px/1 "Lato", Arial, Helvetica, sans-serif;
	color:#f1f1f1;
	border-bottom: 1px solid transparent;
	border-radius: 0;
	box-shadow:none;
	-webkit-box-shadow:none;
}

.popover-content {
	padding: 9px 14px;
}

.popover.top > .arrow:after {
	border-top-color: #f6f6f6;
}

.popover.right > .arrow:after {
	border-right-color: #f6f6f6;
}

.popover.left > .arrow:after {
	border-left-color: #f6f6f6;
}

/* End of copied from theme */

.popover {
  min-width: 150px;
  font: 400 15px/1.6 'Lato', Arial, Helvetica, sans-serif;
  z-index: 1000;
  border-radius: variables.$base-border-radius;
  background-color: #fff;
  border: variables.$raised-widget-border;
  box-shadow: variables.$raised-widget-box-shadow;

  transition: background-color 0.15s, color 0.15s;

  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}
.popover.highlighted {
  color: orange;
}
.popover.blink {
  border-color: #EEA236;
  background-color: #F5E5D0;
  color: #EEA236;
}
.popover.zoomed {
  -webkit-animation-name: zoomInStartDefaultSize;
  animation-name: zoomInStartDefaultSize;
}
.popover.bounced {
  -webkit-animation-name: zoomInBounced;
  animation-name: zoomInBounced;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.popover .popover-title,
.popover h3.popover-title {
  background-color: transparent;
  border-bottom: 1px solid #ededed;
  color: #666;
  font-size: 16px;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.popover .popover-content {
  border-radius: 4px;
}
.popover .popover-footer {
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  border-radius: 0 0 4px 4px;
}
.popover-footer .divider > span {
  background-color: #F5F5F5;
}
.popover.above-modal {
  z-index: 1051;
}
.popover.bottom {
  margin-top: 0;
}
.popover.bottom > .arrow {
  display: none;
}
.popover.top > .arrow {
  border-top-color: #aaa;
}
.popover.top > .arrow:after {
  border-top-color: #fff;
}
.popover.right > .arrow {
  border-right-color: #aaa;
}
.popover.right > .arrow::after {
  border-right-color: #fff;
}
.popover.left > .arrow {
  border-left-color: #aaa;
}
.popover.left > .arrow::after {
  border-left-color: #fff;
}


.popover:not(.right):not(.bottom):not(.left):not(.top) > .arrow {
  display: none;
}

/* camps or classes popover */
.camps-or-classes-popover .popover-content {
  text-align: right;
  padding-top: 12px;
}
.camps-or-classes-popover .popover-content .info {
  border-top: 1px solid #ececec;
  margin: 7px -15px 0;
  padding: 12px 15px 5px;
}
.camps-or-classes-popover .popover-content .info .question {
  margin-bottom: 7px;
  font-size: 14px;
}
.camps-or-classes-popover .popover-content .info .answer {
  margin-bottom: 0;
  color: #aeaeae;
  font-size: 13px;
  line-height: 1.3em;
}


/* drop-in dates popover */
.popover.drop-in-dates {
  max-width: 338px;

  ec-date-picker .main-input-container {
    display: none;
  }

  .flatpickr-calendar.inline {
    border: none;
  }
}


/* spinner popover */
.popover.spinner-popover {
  min-width: 50px;
  max-width: 50px;

  .popover-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    height: 48px;
    text-align: center;
  }
}


/* generic errors popover */
.popover.generic-errors-popover {
  border: 4px solid #fff;

  .popover-content {
    padding: 9px 14px;
    margin: 0;
  }

  .arrow {
    display: none;
  }

  @media (min-width: 480px) {
    max-width: 380px;
  }
}


/* not logged popover */
.popover.not-logged-popover {
  border: none;
  background: transparent;

  .popover-content {
    padding: 0;

    .alert {
      margin: 0 !important;
    }
  }
}


.popover.portal-add-children-popover {
  max-width: none;
}
