@use "abstracts/variables";
@use "containers";

.is-referral-portal #footer-wrapper {
  display: none;
}

#footer .container {
  @include containers.narrow-container;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#footer {
  font-size: 12px;
  color: variables.$text-color;
  padding-bottom: var(--extra-bottom-space, 0);

  a {
    color: variables.$text-color;

    &:hover {
      color: #000;
    }
  }

  .svg-wave-footer {
    display: block;
    fill: variables.$wave-bg-color;
  }

  #footer-inner {
    background-color: variables.$wave-bg-color;
  }

  .footer-bottom {
    background-color: #F5F5F5;
    padding: 30px 0;
  }

  .footer-menu-container {
    padding-top: 50px;
  }

  .footer-menu {
    margin-bottom: 50px;

    .footer-menu-header {
      font-size: 12px;
      color: variables.$text-color;
      margin: 0;
    }

    ul {
      li {
        margin: 15px 0 0;

        &.active a {
          color: #000;
        }
      }
    }
  }

  .footer-questions {
    display: none;
  }

  .footer-copyright-container {
    padding-top: 7px;
  }

  .footer-social-container {
    white-space: nowrap;
  }

}

@media (max-width: 991px) { // tablets
  #footer {
    font-size: 16px;

    .footer-menu .footer-menu-header {
      font-size: 16px;
    }

    .footer-menu.districts {
      clear: left;
    }

    .footer-copyright-container {
      font-size: 14px;
    }
  }
}

@media (max-width: 767px) {
  #footer {
    .svg-wave-footer {
      display: none;
    }

    #footer-inner > .container {
      display: none;
    }

    .footer-bottom {
      position: relative;
      padding-top: 50px;
    }

    .footer-questions {
      display: block;
      text-align: center;
      font-size: 16px;

      a {
        color: #19557B;
        font-weight: bold;

        &:hover {
          color: #081c29;
        }
      }
    }

    .footer-copyright-container {
      font-size: 12px;
      text-align: center;
      position: absolute;
      bottom: 10px;
      line-height: 1.5em;
      padding: 0;
      width: 100%;

      span {
        display: block;
      }
    }

    .footer-social-container {
      text-align: center;
      padding: 20px 0 40px;
    }
  }
}
