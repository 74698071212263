@use "abstracts/variables";
@use "buttons/sizes-mixins";

@mixin form-control-decoration {
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  border: 1px solid variables.$form-control-border-color;
  background-color: #fff;
  background-image: none;
  border-radius: variables.$form-control-border-radius;
}

@mixin form-control {
  @include form-control-decoration;
  height: 40px;
  padding: 9px 16px;
  font-size: 14px;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  color: variables.$text-color;
}
@mixin inline-form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
@mixin form-control-focus {
  border-color: variables.$form-control-focus-border-color;
  box-shadow: variables.$control-focus-box-shadow;
}

@mixin input-lg {
  font-size: 16px;
  line-height: 1.5;
  padding: 10px 18px;
  height: 46px;
}

// all inputs and buttons within this form should be lg
@mixin form-lg {

  .btn:not(.btn-link) {
    @include sizes-mixins.btn-lg;
  }

  .btn.btn-border:not(.btn-link) {
    @include sizes-mixins.btn-lg-border;
  }

  .btn.btn-border.btn-border-light:not(.btn-link) {
    @include sizes-mixins.btn-lg-border-light;
  }

  .form-control {
    @include input-lg;
  }

  // geo location input
  span[geo-location-input] {
    .form-control {
      @include input-lg;
    }
    .location-getter {
      height: 44px;
    }
  }

  // tags input
  tags-input .tags,
  .tags-input .tags,
  .ui-select-container,
  .ui-select-multiple.ui-select-bootstrap {
    @include input-lg;
    padding: 7px 10px;
  }
  tags-input .tags .tag-item,
  .tags-input .tags .tag-item,
  .ui-select-container .ui-select-match-item,
  .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    height: 28px;
    padding: 4px 22px 0 10px;
  }
  tags-input .tags .tag-item .remove-button,
  .tags-input .tags .tag-item .remove-button,
  .ui-select-container .ui-select-match-item .ui-select-match-close,
  .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 40px;
    top: 4px;
    right: 6px;
  }
  tags-input .tags .input,
  tags-input .tags .input:disabled,
  .tags-input .tags .input,
  .tags-input .tags .input:disabled,
  .ui-select-container input.ui-select-search,
  .ui-select-container input.ui-select-search:disabled,
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search,
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search:disabled {
    height: 28px;
    padding-left: 4px;
  }
}

@mixin input-lger {
  font-size: 18px;
  padding: 17px 20px;
  height: 56px;
}

// all inputs and buttons within this form should be lger
@mixin form-lger {

  .btn:not(.btn-link) {
    @include sizes-mixins.btn-lger;
  }

  .btn.btn-border:not(.btn-link) {
    @include sizes-mixins.btn-lger-border;
  }

  .btn.btn-border.btn-border-light:not(.btn-link) {
    @include sizes-mixins.btn-lger-border-light;
  }

  .form-control {
    @include input-lger;
  }

  // geo location input
  span[geo-location-input] {
    .form-control {
      @include input-lger;
    }
    .location-getter {
      height: 54px;
      padding-right: 15px;
    }
  }

  // tags input
  tags-input .tags,
  .tags-input .tags,
  .ui-select-container,
  .ui-select-multiple.ui-select-bootstrap {
    @include input-lger;
    padding: 10px 18px;
  }
  tags-input .tags .tag-item,
  .tags-input .tags .tag-item,
  .ui-select-container .ui-select-match-item,
  .ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    height: 32px;
    padding: 7px 22px 0 10px;
  }
  tags-input .tags .tag-item .remove-button,
  .tags-input .tags .tag-item .remove-button,
  .ui-select-container .ui-select-match-item .ui-select-match-close,
  .ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 40px;
    top: 7px;
    right: 6px;
  }
  tags-input .tags .input,
  tags-input .tags .input:disabled,
  .tags-input .tags .input,
  .tags-input .tags .input:disabled,
  .ui-select-container input.ui-select-search,
  .ui-select-container input.ui-select-search:disabled,
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search,
  .ui-select-multiple.ui-select-bootstrap input.ui-select-search:disabled {
    height: 32px;
  }
}
