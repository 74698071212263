/*

	Padding And Margin css helper file

	.pt class = padding-top
	.pb class = padding-bottom
	.mt class = margin-top
	.mb class = margin bottom
	add number below at the end of classes to add padding or margin // top value 200px
	0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 110 120 130 140 150 160 170 180 190 200

	Example: .pt30 = padding-top:30px;
	Example: .mb140 = margin-bottom:140px;

	Same as Bootstrap Framework (These classes will overwrite the values above for small devices)

	.pt-sm (max-width:991px) for tablets and smaller devices
	.pt-xs (max-width: 767px) for mobile devices

	Example: .pt50-xs = for (max-width: 767px) padding-top:50px;
	Example: .mt80-sm = for (max-width:991px) margin-top 80px;

*/

/* Padding Top */
.pt0 {
	padding-top: 0;
}

.pt5 {
	padding-top: 5px;
}

.pt10 {
	padding-top: 10px;
}

.pt15 {
	padding-top: 15px;
}

.pt20 {
	padding-top: 20px;
}

.pt25 {
	padding-top: 25px;
}

.pt30 {
	padding-top: 30px;
}

.pt35 {
	padding-top: 35px;
}

.pt40 {
	padding-top: 40px;
}

.pt45 {
	padding-top: 45px;
}

.pt50 {
	padding-top: 50px;
}

.pt55 {
	padding-top: 55px;
}

.pt60 {
	padding-top:60px;
}

.pt65 {
	padding-top: 65px;
}

.pt70 {
	padding-top: 70px;
}

.pt75 {
	padding-top: 75px;
}

.pt80 {
	padding-top: 80px;
}

.pt85 {
	padding-top: 85px;
}

.pt90 {
	padding-top: 90px;
}

.pt95 {
	padding-top: 95px;
}

.pt100 {
	padding-top: 100px;
}

.pt110 {
	padding-top: 110px;
}

.pt120 {
	padding-top: 120px;
}

.pt130 {
	padding-top: 130px;
}

.pt140 {
	padding-top: 140px;
}

.pt150 {
	padding-top: 150px;
}

.pt160 {
	padding-top: 160px;
}

.pt170 {
	padding-top: 170px;
}

.pt180 {
	padding-top: 180px;
}

.pt190 {
	padding-top: 190px;
}

.pt200 {
	padding-top: 200px;
}



/* Padding Bottom */
.pb0 {
	padding-bottom: 0;
}

.pb5 {
	padding-bottom: 5px;
}

.pb10 {
	padding-bottom: 10px;
}

.pb15 {
	padding-bottom: 15px;
}

.pb20 {
	padding-bottom: 20px;
}

.pb25 {
	padding-bottom: 25px;
}

.pb30 {
	padding-bottom: 30px;
}

.pb35 {
	padding-bottom: 35px;
}

.pb40 {
	padding-bottom: 40px;
}

.pb45 {
	padding-bottom: 45px;
}

.pb50 {
	padding-bottom: 50px;
}

.pb55 {
	padding-bottom: 55px;
}

.pb60 {
	padding-bottom:60px;
}

.pb65 {
	padding-bottom: 65px;
}

.pb70 {
	padding-bottom: 70px;
}

.pb75 {
	padding-bottom: 75px;
}

.pb80 {
	padding-bottom: 80px;
}

.pb85 {
	padding-bottom: 85px;
}

.pb90 {
	padding-bottom: 90px;
}

.pb95 {
	padding-bottom: 95px;
}

.pb100 {
	padding-bottom: 100px;
}

.pb110 {
	padding-bottom: 110px;
}

.pb120 {
	padding-bottom: 120px;
}

.pb130 {
	padding-bottom: 130px;
}

.pb140 {
	padding-bottom: 140px;
}

.pb150 {
	padding-bottom: 150px;
}

.pb160 {
	padding-bottom: 160px;
}

.pb170 {
	padding-bottom: 170px;
}

.pb180 {
	padding-bottom: 180px;
}

.pb190 {
	padding-bottom: 190px;
}

.pb200 {
	padding-bottom: 200px;
}


/* For Small Devices Tablet and mobile  (max-width: 991px) */
/* (max-width: 991px)  */
@media (max-width: 991px) {

	/* Padding Top */
	.pt0-sm {
		padding-top:0;
	}

	.pt5-sm {
		padding-top: 5px;
	}

	.pt10-sm {
		padding-top: 10px;
	}

	.pt15-sm {
		padding-top: 15px;
	}

	.pt20-sm {
		padding-top: 20px;
	}

	.pt25-sm {
		padding-top: 25px;
	}

	.pt30-sm {
		padding-top: 30px;
	}

	.pt35-sm {
		padding-top: 35px;
	}

	.pt40-sm {
		padding-top: 40px;
	}

	.pt45-sm {
		padding-top: 45px;
	}

	.pt50-sm {
		padding-top: 50px;
	}

	.pt55-sm {
		padding-top: 55px;
	}

	.pt60-sm {
		padding-top:60px;
	}

	.pt65-sm {
		padding-top: 65px;
	}

	.pt70-sm {
		padding-top: 70px;
	}

	.pt75-sm {
		padding-top: 75px;
	}

	.pt80-sm {
		padding-top: 80px;
	}

	.pt85-sm {
		padding-top: 85px;
	}

	.pt90-sm {
		padding-top: 90px;
	}

	.pt95-sm {
		padding-top: 95px;
	}

	.pt100-sm {
		padding-top: 100px;
	}

	.pt110-sm {
		padding-top: 110px;
	}

	.pt120-sm {
		padding-top: 120px;
	}

	.pt130-sm {
		padding-top: 130px;
	}

	.pt140-sm {
		padding-top: 140px;
	}

	.pt150-sm {
		padding-top: 150px;
	}

	.pt160-sm {
		padding-top: 160px;
	}

	.pt170-sm {
		padding-top: 170px;
	}

	.pt180-sm {
		padding-top: 180px;
	}

	.pt190-sm {
		padding-top: 190px;
	}

	.pt200-sm {
		padding-top: 200px;
	}



	/* Padding Bottom */
	.pb0-sm {
		padding-bottom:0;
	}

	.pb5-sm {
		padding-bottom: 5px;
	}

	.pb10-sm {
		padding-bottom: 10px;
	}

	.pb15-sm {
		padding-bottom: 15px;
	}

	.pb20-sm {
		padding-bottom: 20px;
	}

	.pb25-sm {
		padding-bottom: 25px;
	}

	.pb30-sm {
		padding-bottom: 30px;
	}

	.pb35-sm {
		padding-bottom: 35px;
	}

	.pb40-sm {
		padding-bottom: 40px;
	}

	.pb45-sm {
		padding-bottom: 45px;
	}

	.pb50-sm {
		padding-bottom: 50px;
	}

	.pb55-sm {
		padding-bottom: 55px;
	}

	.pb60-sm {
		padding-bottom:60px;
	}

	.pb65-sm {
		padding-bottom: 65px;
	}

	.pb70-sm {
		padding-bottom: 70px;
	}

	.pb75-sm {
		padding-bottom: 75px;
	}

	.pb80-sm {
		padding-bottom: 80px;
	}

	.pb85-sm {
		padding-bottom: 85px;
	}

	.pb90-sm {
		padding-bottom: 90px;
	}

	.pb95-sm {
		padding-bottom: 95px;
	}

	.pb100-sm {
		padding-bottom: 100px;
	}

	.pb110-sm {
		padding-bottom: 110px;
	}

	.pb120-sm {
		padding-bottom: 120px;
	}

	.pb130-sm {
		padding-bottom: 130px;
	}

	.pb140-sm {
		padding-bottom: 140px;
	}

	.pb150-sm {
		padding-bottom: 150px;
	}

	.pb160-sm {
		padding-bottom: 160px;
	}

	.pb170-sm {
		padding-bottom: 170px;
	}

	.pb180-sm {
		padding-bottom: 180px;
	}

	.pb190-sm {
		padding-bottom: 190px;
	}

	.pb200-sm {
		padding-bottom: 200px;
	}
}


/* For Small Devices Phone and mobile  (max-width: 767px) */
/* (max-width: 767px)  */
@media (max-width: 767px) {

	/* Padding Top */
	.pt0-xs {
		padding-top: 0;
	}

	.pt5-xs {
		padding-top: 5px;
	}

	.pt10-xs {
		padding-top: 10px;
	}

	.pt15-xs {
		padding-top: 15px;
	}

	.pt20-xs {
		padding-top: 20px;
	}

	.pt25-xs {
		padding-top: 25px;
	}

	.pt30-xs {
		padding-top: 30px;
	}

	.pt35-xs {
		padding-top: 35px;
	}

	.pt40-xs {
		padding-top: 40px;
	}

	.pt45-xs {
		padding-top: 45px;
	}

	.pt50-xs {
		padding-top: 50px;
	}

	.pt55-xs {
		padding-top: 55px;
	}

	.pt60-xs {
		padding-top:60px;
	}

	.pt65-xs {
		padding-top: 65px;
	}

	.pt70-xs {
		padding-top: 70px;
	}

	.pt75-xs {
		padding-top: 75px;
	}

	.pt80-xs {
		padding-top: 80px;
	}

	.pt85-xs {
		padding-top: 85px;
	}

	.pt90-xs {
		padding-top: 90px;
	}

	.pt95-xs {
		padding-top: 95px;
	}

	.pt100-xs {
		padding-top: 100px;
	}

	.pt110-xs {
		padding-top: 110px;
	}

	.pt120-xs {
		padding-top: 120px;
	}

	.pt130-xs {
		padding-top: 130px;
	}

	.pt140-xs {
		padding-top: 140px;
	}

	.pt150-xs {
		padding-top: 150px;
	}

	.pt160-xs {
		padding-top: 160px;
	}

	.pt170-xs {
		padding-top: 170px;
	}

	.pt180-xs {
		padding-top: 180px;
	}

	.pt190-xs {
		padding-top: 190px;
	}

	.pt200-xs {
		padding-top: 200px;
	}


	/* Padding Bottom */
	.pb0-xs {
		padding-bottom: 0;
	}

	.pb5-xs {
		padding-bottom: 5px;
	}

	.pb10-xs {
		padding-bottom: 10px;
	}

	.pb15-xs {
		padding-bottom: 15px;
	}

	.pb20-xs {
		padding-bottom: 20px;
	}

	.pb25-xs {
		padding-bottom: 25px;
	}

	.pb30-xs {
		padding-bottom: 30px;
	}

	.pb35-xs {
		padding-bottom: 35px;
	}

	.pb40-xs {
		padding-bottom: 40px;
	}

	.pb45-xs {
		padding-bottom: 45px;
	}

	.pb50-xs {
		padding-bottom: 50px;
	}

	.pb55-xs {
		padding-bottom: 55px;
	}

	.pb60-xs {
		padding-bottom:60px;
	}

	.pb65-xs {
		padding-bottom: 65px;
	}

	.pb70-xs {
		padding-bottom: 70px;
	}

	.pb75-xs {
		padding-bottom: 75px;
	}

	.pb80-xs {
		padding-bottom: 80px;
	}

	.pb85-xs {
		padding-bottom: 85px;
	}

	.pb90-xs {
		padding-bottom: 90px;
	}

	.pb95-xs {
		padding-bottom: 95px;
	}

	.pb100-xs {
		padding-bottom: 100px;
	}

	.pb110-xs {
		padding-bottom: 110px;
	}

	.pb120-xs {
		padding-bottom: 120px;
	}

	.pb130-xs {
		padding-bottom: 130px;
	}

	.pb140-xs {
		padding-bottom: 140px;
	}

	.pb150-xs {
		padding-bottom: 150px;
	}

	.pb160-xs {
		padding-bottom: 160px;
	}

	.pb170-xs {
		padding-bottom: 170px;
	}

	.pb180-xs {
		padding-bottom: 180px;
	}

	.pb190-xs {
		padding-bottom: 190px;
	}

	.pb200-xs {
		padding-bottom: 200px;
	}
}



/*  ------------- Seperator --------------- */
/*  Via Margin
	To overwrite the values had to add important
	to use with elements which have margin
*/

/* fix for empy spaces  otherwise it will collapse */
div[class^="mb"],
div[class^="mt"] {
	min-height: 1px;
}

/* Margin Top */
.mt0 {
	margin-top: 0 !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt10-r {
	margin-top:-10px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mt15-r {
	margin-top:-15px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mt35 {
	margin-top: 35px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mt45 {
	margin-top: 45px !important;
}

.mt50 {
	margin-top: 50px !important;
}

.mt55 {
	margin-top: 55px !important;
}

.mt60 {
	margin-top:60px !important;
}

.mt65 {
	margin-top: 65px !important;
}

.mt70 {
	margin-top: 70px !important;
}

.mt75 {
	margin-top: 75px !important;
}

.mt80 {
	margin-top: 80px !important;
}

.mt85 {
	margin-top: 85px !important;
}

.mt90 {
	margin-top: 90px !important;
}

.mt95 {
	margin-top: 95px !important;
}

.mt100 {
	margin-top: 100px !important;
}

.mt110 {
	margin-top: 110px !important;
}

.mt120 {
	margin-top: 120px !important;
}

.mt130 {
	margin-top: 130px !important;
}

.mt140 {
	margin-top: 140px !important;
}

.mt150 {
	margin-top: 150px !important;
}

.mt160 {
	margin-top: 160px !important;
}

.mt170 {
	margin-top: 170px !important;
}

.mt180 {
	margin-top: 180px !important;
}

.mt190 {
	margin-top: 190px !important;
}

.mt200 {
	margin-top: 200px !important;
}



/* Margin Bottom */
.mb0 {
	margin-bottom:0 !important;
}

.mb5 {
	margin-bottom: 5px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb25 {
	margin-bottom: 25px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mb35 {
	margin-bottom: 35px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.mb45 {
	margin-bottom: 45px !important;
}

.mb50 {
	margin-bottom: 50px !important;
}

.mb55 {
	margin-bottom: 55px !important;
}

.mb60 {
	margin-bottom:60px !important;
}

.mb65 {
	margin-bottom: 65px !important;
}

.mb70 {
	margin-bottom: 70px !important;
}

.mb75 {
	margin-bottom: 75px !important;
}

.mb80 {
	margin-bottom: 80px !important;
}

.mb85 {
	margin-bottom: 85px !important;
}

.mb90 {
	margin-bottom: 90px !important;
}

.mb95 {
	margin-bottom: 95px !important;
}

.mb100 {
	margin-bottom: 100px !important;
}

.mb110 {
	margin-bottom: 110px !important;
}

.mb120 {
	margin-bottom: 120px !important;
}

.mb130 {
	margin-bottom: 130px !important;
}

.mb140 {
	margin-bottom: 140px !important;
}

.mb150 {
	margin-bottom: 150px !important;
}

.mb160 {
	margin-bottom: 160px !important;
}

.mb170 {
	margin-bottom: 170px !important;
}

.mb180 {
	margin-bottom: 180px !important;
}

.mb190 {
	margin-bottom: 190px !important;
}

.mb200 {
	margin-bottom: 200px !important;
}



/* For Small Devices Tablet and mobile  (max-width: 991px) */
/* (max-width: 991px)  */
@media (max-width: 991px) {

	/* Margin Top */
	.mt0-sm {
		margin-top:0 !important;
	}

	.mt5-sm {
		margin-top: 5px !important;
	}

	.mt10-sm {
		margin-top: 10px !important;
	}

	.mt15-sm {
		margin-top: 15px !important;
	}

	.mt20-sm {
		margin-top: 20px !important;
	}

	.mt25-sm {
		margin-top: 25px !important;
	}

	.mt30-sm {
		margin-top: 30px !important;
	}

	.mt35-sm {
		margin-top: 35px !important;
	}

	.mt40-sm {
		margin-top: 40px !important;
	}

	.mt45-sm {
		margin-top: 45px !important;
	}

	.mt50-sm {
		margin-top: 50px !important;
	}

	.mt55-sm {
		margin-top: 55px !important;
	}

	.mt60-sm {
		margin-top:60px !important;
	}

	.mt65-sm {
		margin-top: 65px !important;
	}

	.mt70-sm {
		margin-top: 70px !important;
	}

	.mt75-sm {
		margin-top: 75px !important;
	}

	.mt80-sm {
		margin-top: 80px !important;
	}

	.mt85-sm {
		margin-top: 85px !important;
	}

	.mt90-sm {
		margin-top: 90px !important;
	}

	.mt95-sm {
		margin-top: 95px !important;
	}

	.mt100-sm {
		margin-top: 100px !important;
	}

	.mt110-sm {
		margin-top: 110px !important;
	}

	.mt120-sm {
		margin-top: 120px !important;
	}

	.mt130-sm {
		margin-top: 130px !important;
	}

	.mt140-sm {
		margin-top: 140px !important;
	}

	.mt150-sm {
		margin-top: 150px !important;
	}

	.mt160-sm {
		margin-top: 160px !important;
	}

	.mt170-sm {
		margin-top: 170px !important;
	}

	.mt180-sm {
		margin-top: 180px !important;
	}

	.mt190-sm {
		margin-top: 190px !important;
	}

	.mt200-sm {
		margin-top: 200px !important;
	}



	/* Margin Bottom */
	.mb0-sm {
		margin-bottom:0 !important;
	}

	.mb5-sm {
		margin-bottom: 5px !important;
	}

	.mb10-sm {
		margin-bottom: 10px !important;
	}

	.mb15-sm {
		margin-bottom: 15px !important;
	}

	.mb20-sm {
		margin-bottom: 20px !important;
	}

	.mb25-sm {
		margin-bottom: 25px !important;
	}

	.mb30-sm {
		margin-bottom: 30px !important;
	}

	.mb35-sm {
		margin-bottom: 35px !important;
	}

	.mb40-sm {
		margin-bottom: 40px !important;
	}

	.mb45-sm {
		margin-bottom: 45px !important;
	}

	.mb50-sm {
		margin-bottom: 50px !important;
	}

	.mb55-sm {
		margin-bottom: 55px !important;
	}

	.mb60-sm {
		margin-bottom:60px !important;
	}

	.mb65-sm {
		margin-bottom: 65px !important;
	}

	.mb70-sm {
		margin-bottom: 70px !important;
	}

	.mb75-sm {
		margin-bottom: 75px !important;
	}

	.mb80-sm {
		margin-bottom: 80px !important;
	}

	.mb85-sm {
		margin-bottom: 85px !important;
	}

	.mb90-sm {
		margin-bottom: 90px !important;
	}

	.mb95-sm {
		margin-bottom: 95px !important;
	}

	.mb100-sm {
		margin-bottom: 100px !important;
	}

	.mb110-sm {
		margin-bottom: 110px !important;
	}

	.mb120-sm {
		margin-bottom: 120px !important;
	}

	.mb130-sm {
		margin-bottom: 130px !important;
	}

	.mb140-sm {
		margin-bottom: 140px !important;
	}

	.mb150-sm {
		margin-bottom: 150px !important;
	}

	.mb160-sm {
		margin-bottom: 160px !important;
	}

	.mb170-sm {
		margin-bottom: 170px !important;
	}

	.mb180-sm {
		margin-bottom: 180px !important;
	}

	.mb190-sm {
		margin-bottom: 190px !important;
	}

	.mb200-sm {
		margin-bottom: 200px !important;
	}
}


/* For Small Devices Phone and mobile  (max-width: 767px) */
/* (max-width: 767px)  */
@media (max-width: 767px) {

	/* Margin Top */
	.mt0-xs {
		margin-top:0 !important;
	}

	.mt5-xs {
		margin-top: 5px !important;
	}

	.mt10-xs {
		margin-top: 10px !important;
	}

	.mt15-xs {
		margin-top: 15px !important;
	}

	.mt20-xs {
		margin-top: 20px !important;
	}

	.mt25-xs {
		margin-top: 25px !important;
	}

	.mt30-xs {
		margin-top: 30px !important;
	}

	.mt35-xs {
		margin-top: 35px !important;
	}

	.mt40-xs {
		margin-top: 40px !important;
	}

	.mt45-xs {
		margin-top: 45px !important;
	}

	.mt50-xs {
		margin-top: 50px !important;
	}

	.mt55-xs {
		margin-top: 55px !important;
	}

	.mt60-xs {
		margin-top:60px !important;
	}

	.mt65-xs {
		margin-top: 65px !important;
	}

	.mt70-xs {
		margin-top: 70px !important;
	}

	.mt75-xs {
		margin-top: 75px !important;
	}

	.mt80-xs {
		margin-top: 80px !important;
	}

	.mt85-xs {
		margin-top: 85px !important;
	}

	.mt90-xs {
		margin-top: 90px !important;
	}

	.mt95-xs {
		margin-top: 95px !important;
	}

	.mt100-xs {
		margin-top: 100px !important;
	}

	.mt110-xs {
		margin-top: 110px !important;
	}

	.mt120-xs {
		margin-top: 120px !important;
	}

	.mt130-xs {
		margin-top: 130px !important;
	}

	.mt140-xs {
		margin-top: 140px !important;
	}

	.mt150-xs {
		margin-top: 150px !important;
	}

	.mt160-xs {
		margin-top: 160px !important;
	}

	.mt170-xs {
		margin-top: 170px !important;
	}

	.mt180-xs {
		margin-top: 180px !important;
	}

	.mt190-xs {
		margin-top: 190px !important;
	}

	.mt200-xs {
		margin-top: 200px !important;
	}



	/* Margin Bottom */
	.mb0-xs {
		margin-bottom:0 !important;
	}

	.mb5-xs {
		margin-bottom: 5px !important;
	}

	.mb10-xs {
		margin-bottom: 10px !important;
	}

	.mb15-xs {
		margin-bottom: 15px !important;
	}

	.mb20-xs {
		margin-bottom: 20px !important;
	}

	.mb25-xs {
		margin-bottom: 25px !important;
	}

	.mb30-xs {
		margin-bottom: 30px !important;
	}

	.mb35-xs {
		margin-bottom: 35px !important;
	}

	.mb40-xs {
		margin-bottom: 40px !important;
	}

	.mb45-xs {
		margin-bottom: 45px !important;
	}

	.mb50-xs {
		margin-bottom: 50px !important;
	}

	.mb55-xs {
		margin-bottom: 55px !important;
	}

	.mb60-xs {
		margin-bottom:60px !important;
	}

	.mb65-xs {
		margin-bottom: 65px !important;
	}

	.mb70-xs {
		margin-bottom: 70px !important;
	}

	.mb75-xs {
		margin-bottom: 75px !important;
	}

	.mb80-xs {
		margin-bottom: 80px !important;
	}

	.mb85-xs {
		margin-bottom: 85px !important;
	}

	.mb90-xs {
		margin-bottom: 90px !important;
	}

	.mb95-xs {
		margin-bottom: 95px !important;
	}

	.mb100-xs {
		margin-bottom: 100px !important;
	}

	.mb110-xs {
		margin-bottom: 110px !important;
	}

	.mb120-xs {
		margin-bottom: 120px !important;
	}

	.mb130-xs {
		margin-bottom: 130px !important;
	}

	.mb140-xs {
		margin-bottom: 140px !important;
	}

	.mb150-xs {
		margin-bottom: 150px !important;
	}

	.mb160-xs {
		margin-bottom: 160px !important;
	}

	.mb170-xs {
		margin-bottom: 170px !important;
	}

	.mb180-xs {
		margin-bottom: 180px !important;
	}

	.mb190-xs {
		margin-bottom: 190px !important;
	}

	.mb200-xs {
		margin-bottom: 200px !important;
	}
}

/* CLASSES FOR HORIZONTAL MARGINS AND PADDING ------------------------------ */
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.ml25 {
  margin-left: 25px;
}
.mr25 {
  margin-right: 25px;
}
.ml30 {
  margin-left: 30px;
}
.mr30 {
  margin-right: 30px;
}
.ml40 {
  margin-left: 40px;
}
.mr40 {
  margin-right: 40px;
}
.ml80 {
  margin-left: 80px;
}
.mr80 {
  margin-right: 80px;
}
.ml100 {
  margin-left: 100px;
}
.mr100 {
  margin-right: 100px;
}
.pl0 {
  padding-left: 0;
}
.pr0 {
  padding-right: 0;
}
.pl5 {
  padding-left: 5px;
}
.pr5 {
  padding-right: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pr20 {
  padding-right: 20px;
}
.pl25 {
  padding-left: 25px;
}
.pr25 {
  padding-right: 25px;
}
.pl30 {
  padding-left: 30px;
}
.pr30 {
  padding-right: 30px;
}

.cl {
  clear: left;
}

@media (max-width: 767px) {
  .ml20-xs {
    margin-left: 20px !important;
  }
}
