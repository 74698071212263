@use "abstracts/variables";

/* we want to have footer always at the bottom of the page even for pages with short content */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#wrapper {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
	background-color: #fff;
}
#content-wrapper,
#footer-wrapper {
  display: table-row;
}
#content-wrapper {
  height: 100%;
}
/* end of sticky bottom footer solution */

body {
  // Google Translate widget wants to change that to have space at the top,
  // but we want to have it at the bottom.
  top: 0 !important;
}

#wrapper {
  padding-top: variables.$header-height;

  @media (max-width: 767px) {
    padding-top: variables.$mobile-header-height;
  }
}
