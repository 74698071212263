@use "abstracts/variables";

/* Copied from theme */

.tooltip {
	z-index:900;
	font: 13px/1.4 'Open Sans', Verdana, sans-serif;
}

.tooltip.in {
	filter: alpha(opacity=94);
	opacity: .94;
}

.tooltip.top {
	padding: 8px 0;
}

.tooltip.right {
	padding: 0 8px;
}

.tooltip.bottom {
	padding: 8px 0;
}

.tooltip.left {
	padding: 0 8px;
}

.tooltip-inner {
	padding: 3px 10px;
	color: #fff;
	border-radius:0;
	white-space: nowrap;
}

.tooltip.top .tooltip-arrow {
	margin-left: -5px;
	border-width: 0 10px 10px 0;
}

.tooltip.right .tooltip-arrow {
	margin-top: -7.5px;
	border-width: 0 0 10px 10px;
}

.tooltip.left .tooltip-arrow {
	margin-top: -7.5px;
	border-width: 10px 0 0 10px;
}

.tooltip.bottom .tooltip-arrow {
	margin-left: -5px;
	border-width: 0 0 10px 10px;
}

/* End of copied from theme */


.tooltip {
  z-index: 2000;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}
.tooltip.animated {
  animation-duration: 0.3s;
}
.tooltip.in,
.tooltip.show {
  filter: alpha(opacity=1);
  opacity: 1;
}
.tooltip-inner {
  padding: 8px 10px;
  max-width: 300px;
  border-radius: variables.$base-border-radius;
  white-space: pre-wrap;
  color: #fff;
  background-color: variables.$link-color;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #efefef;
      text-decoration: underline;
    }
  }
}
.tooltip.top {
	padding: 0 0 10px 0;
}
.tooltip.right {
	padding: 0 0 0 15px;
}
.tooltip.bottom {
	padding: 10px 0 0 0;
}
.tooltip.left {
	padding: 0 15px 0 0;
}
.tooltip.right .tooltip-inner {
  text-align: left;
}
.tooltip.left .tooltip-inner {
  text-align: right;
}
.tooltip.top .tooltip-arrow {
  margin: 0 0 -8px -5px;
  border-width: 10px;
  border-color: variables.$link-color transparent transparent transparent;
}
.tooltip.right .tooltip-arrow {
	margin: -8px 0 0 -5px;
	border-width: 10px;
  border-color: transparent variables.$link-color transparent transparent;
}
.tooltip.left .tooltip-arrow {
  margin: -8px -5px 0 0;
	border-width: 10px;
  border-color: transparent transparent transparent variables.$link-color;
}
.tooltip.bottom .tooltip-arrow {
	margin: -8px 0 0 -10px;
  border-color: transparent transparent variables.$link-color transparent;
	border-width: 10px;
}
.tooltip.persistent-tooltip {
  z-index: 1010;
}
.tooltip.persistent-tooltip .tooltip-inner {
  position: relative;
  padding-top: 12px;
  padding-right: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
}
.tooltip.persistent-tooltip .tooltip-inner .close-button {
  position: absolute;
  right: 0;
  top: -10px;
  cursor: pointer;
  font-size: 22px;
  background: variables.$link-color;
  display: block;
  width: 26px;
  height: 22px;
  border-radius: 0;
}

.tooltip.tooltip-below-popover {
  z-index: 999;
}

.tooltip.low-z-index-tooltip {
  z-index: 1;
}
