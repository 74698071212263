@mixin header-switch-to-mobile-version {

  .header-menu-container {

    .header-user-menu {
      margin-right: 0;

      > li:not(.continue-booking-button) {
        display: none;
      }
    }

    .header-menu-open {
      display: inline-block;
    }

    .header-extra-buttons-menu {
      display: none;
    }

  }

  .header-menu-container-mobile {
    display: block;
  }

}
