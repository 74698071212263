@use "abstracts/variables";
@use "base/font-awesome";

.help-icon {
  color: variables.$help-icon-color;
  @include font-awesome.font-awesome('question-circle');
  & {
    font-size: 1.1em;
  }

  &.large {
    transform: scale(1.2);
  }
}
