@use "abstracts/mixins";
@use "abstracts/variables";
@use "base/font-awesome";
@use "header-switch-to-mobile-version";

$header-mobile-breakpoint: 767px;
$header-tablet-breakpoint: 991px;
$header-element-horizontal-spacing: 11px;

#header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: variables.$header-height;
  background: #fff;
  z-index: 1000;

  @media (max-width: $header-mobile-breakpoint) {
    height: variables.$mobile-header-height;
  }
}

#header {
  height: 100%;
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  > .header-inner {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;

    &.container,
    &.portal-container {

      // tablets
      @media (max-width: 991px) {
        width: auto;
      }
    }
  }
}

/* icons */
#header {

  li.share-icon {
    svg.svg-share-icon {
      stroke: variables.$header-menu-color;
      transition: stroke variables.$link-hover-transition-time;
      width: 38px;
      position: relative;
      top: -6px;
    }

    &:hover svg.svg-share-icon {
      stroke: variables.$header-menu-color-hover;
    }
  }

  li.schedule-icon {
    svg.svg-schedule-icon {
      stroke: variables.$header-menu-color;
      transition: stroke variables.$link-hover-transition-time;
      width: 34px;
      position: relative;
      top: -2px;
    }

    &:hover svg.svg-schedule-icon {
      stroke: variables.$header-menu-color-hover;
    }
  }
}

/* logo */
.header-logo-container {
  margin: 0;
  height: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.35s;
  flex-shrink: 1;

  &.initialized {
    opacity: 1;
  }

  .logo-wrapper {
    display: block;
    height: 24px;
    width: 108px;
    margin-top: 22px;

    @media (max-width: $header-mobile-breakpoint) {
      margin-top: 18px;
    }

    .logo {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .near-logo-page-title {
    font-size: 14px;
    position: absolute;
    top: 44px;
    left: 0;
    letter-spacing: 0.05em;
    white-space: nowrap;
    color: #828282;
    display: none;

    @media (max-width: $header-mobile-breakpoint) {
      top: 40px;
    }
  }
}

.header-referral {
  display: none;
  float: left;
  margin-right: 5px;
  margin-top: 14px;

  a {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    font-size: 16px;
    height: 38px;

    &.with-logo {
      padding-left: 40px;
    }

    .referral-logo {
      width: 36px;
      height: 36px;
      overflow: hidden;
      line-height: 1em;
      font-size: 12px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }

    &.with-logo .referral-logo {
      display: block;
    }

    .referral-title {
      display: inline-block;
      color: #666;
      font-size: 16px;
      line-height: 1.1em;
      font-weight: bold;
    }

    .referral-details {
      font-size: 18px;
      position: relative;
      top: 1px;
      color: variables.$link-color;
      margin-left: 3px;

      &:hover {
        color: variables.$link-hover-color;
      }
    }
  }

  .powered-by {
    display: table-cell;
    padding-left: 10px;
    font-size: 12px;
    line-height: 12px;
    position: relative;
    top: 12px;
    color: #888;
    font-weight: 400;
    white-space: nowrap;

    @media (max-width: 500px) {
      display: none;
    }

    &.with-logo {
      top: 0;
    }

    .district-logo {
      vertical-align: text-bottom;
      height: 30px;
    }

    .crickets {
      display: block;
    }

    &.with-district .crickets {
      display: inline;
    }
  }
}

.header-menu-container {
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-left: $header-element-horizontal-spacing;

  .header-menu {
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;

    > li {
      float: left;
      height: 100%;
      margin: 1px $header-element-horizontal-spacing 0;
      font-size: 18px;
      position: relative;
      white-space: nowrap;

      > a:not(.btn) {
        color: variables.$header-menu-color;
        display: block;
        height: 100%;
        padding: 23px 0 0;
        border-bottom: 4px solid transparent;
      }
    }

    > li:hover > a:not(.btn),
    > li.active > a:not(.btn) {
      color: variables.$header-menu-color-hover;
      text-decoration: none;
    }

    > li:not(.top-menu-icon):hover > a:not(.btn),
    > li:not(.top-menu-icon).active > a:not(.btn) {
      border-bottom-color: variables.$header-active-menu-bottom-border-color;
    }

    > li.button-menu-item {
      padding-top: 16px;

      @media (max-width: $header-mobile-breakpoint) {
        padding-top: 9px;
      }

      .btn {
        margin-top: 4px;
      }

      .btn-sm {
        margin-top: 6px;
      }

      .btn-lg {
        font-size: 18px;
        padding: 8px 19px;
        line-height: 1.333333em;
        margin-top: 0;
      }
    }

    > li.dropdown > a:after {
      @include font-awesome.font-awesome('angle-down');
    }

    > li.dropdown.open > a:after {
      @include font-awesome.font-awesome('angle-up');
    }

    > li.dropdown > a:after,
    > li.dropdown.open > a:after {
      font-size: 18px;
      margin-left: 5px;
      color: #ccc;
    }

    > li.dropdown.active > a:after,
    > li.dropdown:hover > a:after,
    > li.dropdown.header-account > a:after {
      color: #aaa;
    }

    > li.top-menu-icon {
      margin-left: 10px;
      margin-right: 10px;

      .tooltip {
        margin-top: -10px;

        .tooltip-inner {
          white-space: pre;
          text-align: left;
        }
      }
    }

    > li.header-login {
      margin-left: 20px;
    }

    > li.header-signup {
      margin-right: 30px;
    }

    @media (max-width: $header-tablet-breakpoint) {
      > li {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    @media (max-width: $header-mobile-breakpoint) {

      > li:not(.top-menu-icon) > a:not(.btn) {
        font-size: 16px;
        padding-top: 20px;
      }

      > li.top-menu-icon > a:not(.btn) {
        padding-top: 18px;
      }
    }
  }

  .header-main-menu-wrapper {
    display: flex;
    flex-grow: 1;
    height: 100%;

    @media (max-width: $header-tablet-breakpoint) {
      .header-main-menu {
        li.right-link.only-on-main-front-page {
          display: none;
        }
      }
    }

  }

  .header-user-menu {
    margin-right: -15px;
  }

  .header-user-menu > li.dropdown.header-account {
    > a:after {
      @include font-awesome.font-awesome('angle-down');
    }

    &.open > a:after {
      @include font-awesome.font-awesome('angle-up');
    }

    &.open .tooltip {
      /* We need !important to override a dynamically-added inline style. */
      display: none !important;
    }

    > .dropdown-toggle {
      padding: 0;
      display: flex;
      align-items: center;

      > .circle {
        max-width: 90px;
        text-transform: uppercase;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2px;
        opacity: 0.95;

        .letter {
          color: #fff;
          font-weight: 700;
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }

        .district-user-indicator {
          position: absolute;
          top: -10px;
          right: -10px;
          display: block;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }
      }


    }

    > .dropdown-menu {
      left: auto;
      right: -2px;
      height: auto;
      max-height: calc(100vh - 80px - var(--extra-bottom-space, 0px));
      overflow-x: hidden;
    }

  }

  .header-menu-open {
    display: none;
    float: right;
    width: 40px;
    height: 50px;
    margin-top: 14px;
    margin-right: -10px;
    position: relative;

    .cart-number-of-items {
      right: 11px;
      top: 5px;
      display: none;
    }

    @media (max-width: $header-mobile-breakpoint) {
      margin-top: 6px;

      .cart-number-of-items {
        display: block;
      }
    }
  }
}

#header .header-menu-container {
  opacity: 0;
  transition: opacity 0.35s;
}
// initialized class is added by TopMenu service when menus are ready
#header.initialized .header-menu-container {
  opacity: 1;
}

.header-menu-container .dropdown-menu {
  margin-top: -1px;
  padding: 10px 0;
  min-width: 220px;

  @media (max-width: 450px) {
    position: fixed;
    top: variables.$mobile-header-height;
    left: 25px;
    right: 25px;
  }

  > li > a,
  .dropdown-item {
    font-size: 18px;
    color: variables.$text-color;
    padding: 6px 20px;
  }
  > li.user-details {
    font-size: 16px;
    color: variables.$text-color;
    padding: 0 20px;

    .account-info {
      font-size: 75%;
      margin-top: 5px;
    }

    ec-display-referral {
      font-size: 16px;
      white-space: normal;
      margin-top: 10px;
    }
  }
  > li > a:hover,
  .dropdown-item:hover,
  > li.active > a,
  li.active .dropdown-item {
    background-color: #F5F5F5;
    color: variables.$header-menu-color-hover;
  }

  .menu-divider {
    border-bottom: 1px solid #ccc;
    margin: 15px 20px;

    &.for-email {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.header-menu-container-mobile {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: var(--extra-bottom-space, 0);
  left: 0;
  z-index: 10;
  background: #fff;
  font-size: 18px;
  overflow: auto;
  transform: translateX(100%);
  transition: transform 0.35s ease-in-out;

  .header-menu-container-mobile-inner {
    padding-top: 20px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  .header-menu-close {
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 1;
  }

  &.open {
    transform: translateX(0);
  }

  .greetings-container,
  .header-menu {
    padding-left: 25px;
    padding-right: 25px;
  }

  .greetings-container {
    min-height: 20px;
    padding-bottom: 20px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #eee;

    .greetings,
    .user-details,
    .login-or-signup {
      padding-right: 65px;
    }

    .greetings {
      font-style: italic;
    }

    .user-details {
      font-size: 14px;
    }

    .account-info {
      font-size: 16px;
      margin-top: 5px;

      ec-display-referral {
        font-size: 20px;
        margin-top: 10px;
      }
    }

    .login-or-signup {
      font-size: 20px;
      @include mixins.clearfix;

      > li {
        float: left;
        margin-right: 20px;

        > a {
          color: variables.$text-color;
          text-decoration: none;

          &:hover {
            color: #000;
          }
        }
      }
    }

    .header-icons {
      margin-top: 30px;
      @include mixins.clearfix;

      > li {
        float: left;
        margin-right: 15px;

        a {
          display: block;
          height: 34px;
          text-decoration: none;
          color: variables.$header-menu-color;
        }
      }
    }
  }

  .header-menu {
    background: #fff;

    > li {
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom-color: transparent;
        padding-bottom: 15px;
      }
    }

    > li.has-submenu > a {
      position: relative;

      &:after {
        content: "+";
        color: variables.$link-color;
        position: absolute;
        right: 12px;
        top: 6px;
        font-weight: bold;
        font-size: 22px;
      }
    }

    > li.has-submenu.open > a:after {
      content: "–";
    }

    li.user-account {
      background-color: #F9F9F9;
      margin-left: -25px;
      padding-left: 25px;
      margin-right: -25px;
      padding-right: 25px;

      li.user-details {
        display: none;
      }
    }

    li.menu-divider {
      border-bottom: 1px solid #ddd;
      margin: 10px 20px;

      &:first-child {
        border: none;
        margin: 0;
      }

      &.for-email {
        display: none;
      }
    }

    a:not(.btn) {
      color: variables.$text-color;
      display: block;
      padding: 10px 0 5px;
    }

    li.active > a:not(.btn),
    a:not(.btn):hover {
      text-decoration: none;
      color: #000;
    }

    .submenu {
      font-size: 15px;
      position: relative;
      top: -5px;

      &:not(.collapse) {
        display: block;
      }

      li a:not(.btn) {
        padding: 5px 0 5px 20px;
        margin-top: 5px;
      }

      li.active a,
      li:hover a,
      li a:active {
        background: #F6F6F6;
      }

      li:last-child {
        margin-bottom: 10px;
      }
    }

    li.button-menu-item {
      padding-top: 15px;
      padding-bottom: 15px;

      .btn {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

  }
}

@media (max-width: $header-mobile-breakpoint) {
  @include header-switch-to-mobile-version.header-switch-to-mobile-version;
}

// only main front page
body.page-type-front-main {
  #header {
    .header-main-menu-wrapper {
      .header-main-menu {
        @media (max-width: $header-tablet-breakpoint) {
          li.right-link.only-on-main-front-page {
            display: none;
          }
        }
        @media (max-width: $header-mobile-breakpoint) {
          li.right-link {
            display: none;
          }
        }
      }
    }
  }
}

// all pages except main front page
body:not(.page-type-front-main) {
  #header {

    &:not(.main-menu-active) .header-menu-container .header-main-menu  > li {
      display: none;
    }

    &.main-menu-active .header-menu-container .header-main-menu > li:not(.active) {
      display: none;
    }

    .header-menu-container .header-menu > li.active {
      > a {
        border-bottom-color: transparent;
      }
      > a:hover {
        border-bottom-color: variables.$header-active-menu-bottom-border-color;
      }
    }

    .header-menu-container .header-main-menu > li.active {

      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 20px;
        background: #DCDCDC;
        position: absolute;
        top: 26px;
        left: -$header-element-horizontal-spacing;

        @media (max-width: $header-mobile-breakpoint) {
          top: 22px;
        }
      }
    }
  }
}

body:not(.page-type-front-main):not(.is-referral-portal) {
  @media (max-width: 420px) {
    #header .header-menu-container .header-user-menu {
      display: none;
    }
  }
}

body.mobile-menu-opened {
  overflow: hidden;
}

// header changes for referral portals
body.is-referral-portal {

  .header-logo-container .logo-wrapper {
    display: none;
  }

  .header-referral {
    display: block;
  }

  .header-menu-container {
    .header-main-menu-wrapper {
      display: none;
    }
  }

  .header-menu-container-mobile .header-main-menu > li:not(.user-account) {
    display: none;
  }

}

body.page-type-summer:not(.is-referral-portal) #header .near-logo-page-title {
  display: inline;
}

// there are special 'My schedule' links just for portal pages (that show
// schedule from the portal instead of schedule modal)
.open-portal-schedule,
#header .open-portal-schedule { // this is needed to override some styles from header
  display: none;
}
body.page-type-summer,
body.page-type-provider-page,
body.page-type-school-page,
body.page-type-district-page {
  .open-schedule-modal,
  #header .open-schedule-modal {
    display: none;
  }
  .open-portal-schedule,
  #header .open-portal-schedule {
    display: inline-block;
  }
}

body:not(.page-type-provider-page):not(.page-type-school-page):not(.page-type-district-page) {
  .header-menu-container .header-menu > li.scholarship {
    display: none;
  }

  .header-menu-container-mobile .greetings-container .header-icons > li.scholarship {
    display: none;
  }
}

// some changes for unlogged in users
body.user-not-logged {
  .header-menu-container .header-menu > li.top-menu-icon {
    &.share-icon,
    &.schedule-icon,
    &.cart-dropdown {
      display: none;
    }
  }
  .header-menu-container-mobile .greetings-container .header-icons {
    .share-icon,
    .schedule-icon,
    .cart-dropdown {
      display: none;
    }
  }
}

/* continue booking button visible only on checkout page */
#header .continue-booking-button {
  display: none;
}
