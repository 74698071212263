span[geo-location-input] {
  display: block;
  position: relative;
}
span[geo-location-input] .form-control {
  margin-bottom: 0;
  width: 100%;
  padding-right: 35px;
}
span[geo-location-input].geo-input-lg .form-control {
  font-size: 16px;
  line-height: 1.5;
  height: 46px;
}
span[geo-location-input] .location-getter {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 38px;
  width: 45px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 10px;
  text-align: right;
  color: #9B9B9B !important;
  border: none !important;
  border-radius: 0 4px 4px 0;
  box-shadow: none !important;
  transition: all 0.4s ease;
  background: transparent !important;
}
span[geo-location-input] .location-getter:hover,
span[geo-location-input] .location-getter:focus,
span[geo-location-input] .location-getter:active {
  color: #656565 !important;
}
span[geo-location-input].geo-input-lg .location-getter {
  height: 44px;
  font-size: 1.1em;
  line-height: 1em;
}
