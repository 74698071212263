@use "abstracts/variables";
@use "base/base";

.btn.btn-link {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  vertical-align: baseline;
  user-select: text;
  @include base.link-like;

  &:active,
  &.active {
    box-shadow: none;
  }
  &:not(.btn-link-keep-focus-decoration) {
    box-shadow: none;

    &:focus,
    &.focus {
      box-shadow: none;
    }
  }

  &.btn-link-as-text {
    color: variables.$text-color;

    &.btn-link-keep-focus-decoration {
      text-decoration: none;
    }
  }

  &.text-muted {
    color: variables.$text-muted-color;
  }

  &.danger-on-hover {
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: variables.$text-danger-color;
    }
  }

  &.small {
    font-size: variables.$small-font-size;
  }
}

.btn.btn-link.btn-icon {
  width: 20px;
  height: 20px;
  padding: 0;
  position: relative;
  border-radius: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-underline-position: under;

  // Increases touch/click area.
  &:after {
    content: "";
    width: 200%;
    height: 200%;
    position: absolute;
    top: -50%;
    left: -50%;
    border-radius: 50px;
  }

  &.btn-link-keep-focus-decoration {
    &:focus,
    &.focus {
      box-shadow: none;

      &:after {
        box-shadow: variables.$control-focus-box-shadow;
      }
    }
  }
}
