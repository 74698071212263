@use "abstracts/variables";

.filters-popover-opened { /* class used on other elements on the page to fade them when filters popover is opened */
  opacity: 0.25;
  pointer-events: none;
}
.filter-popover {
  max-width: 589px;
  width: 589px;
  -webkit-transition: top 0.35s;
  -moz-transition: top 0.35s;
  -ms-transition: top 0.35s;
  -o-transition: top 0.35s;
  transition: top 0.35s;
}
.filter-popover.popover.right {
  margin-left: 20px;
}
.filter-popover.popover.right > .arrow {
  display: none;
}
.filter-popover .popover-content {
  padding: 14px;
}
.filter-popover .popover-footer,
.popover.calendarpicker .popover-footer {
  padding-top: 10px;
  padding-bottom: 10px;
}
.filter-popover .popover-footer .btn,
.popover.calendarpicker .popover-footer .btn {
  padding-left: 0;
  padding-right: 0;
  min-width: 100px;
}
.filter-popover .popover-footer .apply-button,
.popover.calendarpicker .popover-footer .apply-button {
  float: right;
}
.filter-popover.processing .popover-content,
.filter-popover.processing .popover-footer {
  opacity: 0.25;
  pointer-events: none;
}
.filter-popover .info-tooltip {
  color: #c5c5c5;
  margin-left : 10px;
}
.filter-popover label > input[type=checkbox] {
  margin: 0 5px 0 0;
}
.filter-popover .missing-provider-alert {
  margin-bottom: 0;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.filter-popover .missing-provider-alert p {
  margin-bottom: 10px;
}
.filter-popover .missing-provider-alert .form-inline {
  margin-right: -20px;
}

.filter-popover.filter-date-range-popover.popover .popover-content {
  padding: 14px 0 0;
}
.filter-popover.filter-date-range-popover.popover.right > .arrow {
  top: 50px !important;
}
.filter-popover.filter-weeks-popover {
  width: 580px;
}
.filter-popover.filter-weeks-popover .popover-content {
  overflow-y: auto;
  height: calc(100% - 55px);
}

@media (max-width: 767px) {
  .filter-popover,
  .popover.calendarpicker {
    position: fixed !important;
    margin: 0 !important;
    left: 0 !important;
    top: variables.$mobile-header-height !important;
    bottom: var(--extra-bottom-space, 0) !important;
    right: 0 !important;
    max-width: none !important;
    width: 100% !important;
    box-shadow: none;
    z-index: 1020;
    border: none;
    border-radius: 0;
  }
  .filter-popover .popover-content,
  .popover.calendarpicker .popover-content {
    max-height: none !important;
    height: calc(100% - 55px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .popover.calendarpicker .popover-content {
    -webkit-overflow-scrolling: auto;
  }
}
@media (max-width: 400px) {
  .filter-popover .popover-footer .btn {
    min-width: 70px;
  }
}
