// typography ============================================================== //

$default-font-size: 16px;
$small-font-size: 92%;

// base variables that may be overridden by more specific variables
$base-border-radius: 4px;
$raised-widget-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
$raised-widget-border: 1px solid rgba(0, 0, 0, 0.1);

// Forms, fieldsets, panels, separators, dividers, accordions, etc.
$section-border-color: #eaeaea;

// colors ================================================================== //

// text
$text-color: #595959;
$dark-text-color: #333;
$text-muted-color: #7a7a7a;
$text-success-color: #3c763d;
$text-info-color: #31708f;
$text-warning-color: #cc7700;
$text-danger-color: #a94442;
$text-primary-color: #31708f;

// main colors
$link-color: #4498AD;
$link-hover-color: #44ACC7;
$hero-color: #F96E9E; // TODO: replace all usages of #f067a6 in old css files with $hero-color
$hero-color-hover: #DC6991;

$highlight-for-admins-color: #d7b0d4;

// forms
$placeholder-color: $text-muted-color;
$form-error-color: $text-danger-color;
$form-edited-color: $text-warning-color;
$form-required-color: #00b4e5;
$control-focus-box-shadow: 0 0 0 .3rem rgba(13,110,253,.25);
$form-control-border-radius: $base-border-radius;
$form-control-border-color: #74777f;
$form-control-focus-border-color: rgba(13,110,253,.5);

// form fields
$date-and-time-inputs-max-width: 180px;

// some icons
$heart-color: $hero-color;
$rating-stars-color: $link-color;
$details-icon-color: $text-color;
$help-icon-color: #b4b4b4;

// backgrounds
$default-bg-color: #FFFFFF;
$secondary-bg-color: #F5F5F5;
$highlighted-bg-color: #FFF5E4;
$selected-bg-color: #F7F7D3;
$wave-bg-color: #EAF6FE;
$skeleton-base-color: #ddd;

// tabs
$tab-color: $link-color;
$active-tab-color: $text-color;
$active-tab-border: #999;

// alerts
$alert-warning-color: $text-warning-color;
$alert-warning-bg: #fcf8e3;
$alert-warning-border: #faebcc;

// social sharing related colors
$facebook-color: #3b5998;
$instagram-color: #f42c43;
$facebook-hover-color: #1b4579;
$instagram-hover-color: #EB4A5C  ;
$twitter-color: #1da1f2;
$twitter-hover-color: #0380d3;
$youtube-color: #c4302b;
$youtube-hover-color: #92201c;
$email-color: #ab3427;

// theming
$theme-color-yellow: #ffd156;
$theme-color-yellow-light: #FEFEEF;
$theme-color-blue: #6FC3F9;
$theme-color-blue-light: #F0F9FF;
$theme-color-green: #5FE3B2;
$theme-color-green-light: #F3FCF9;
$theme-color-pink: #F9C2D5;
$theme-color-pink-light: #FCF2F6;

// ============================= Fonts ==================================== //

$default-font-size: 16px;
$default-font-weight: 400;
$default-font-family: 'Lato', Arial, Helvetica, sans-serif;
$default-line-height: 1.6;
$default-font: #{$default-font-weight}
               #{$default-font-size}/#{$default-line-height}
               #{$default-font-family};

// ========================================================================//

$link-hover-transition-time: 0.35s;

// header
$header-height: 75px;
$mobile-header-height: 62px;
$header-menu-color: $text-color;
$header-menu-color-hover: $dark-text-color;
$header-active-menu-bottom-border-color: $active-tab-border;

// dashboards
$dashboard-aside-menu-width: 50px;

// ========================================================================//

// portals' program cards
$program-card-padding-rl: 15px;
$program-card-padding-tb: 10px;
