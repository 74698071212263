@use "abstracts/variables";
@use "dropdown-menu-mixins";

.btn-group.open .dropdown-toggle {
  $inner-shadow: inset 0 3px 5px rgba(0,0,0,.125);

  box-shadow: $inner-shadow;

  &:focus {
    box-shadow: $inner-shadow, variables.$control-focus-box-shadow;
  }
}

.dropdown-menu {
  border: variables.$raised-widget-border;
  border-radius: variables.$base-border-radius;
  box-shadow: variables.$raised-widget-box-shadow;

  .divider.with-margins {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
}

.dropdown.open > .dropdown-menu,
.schedule-cell .context-menu {
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

.dropdown-item,
.dropdown-menu > li > .dropdown-item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  margin: 0;
  cursor: pointer;
  width: 100%; // For `<button>`s
  text-align: inherit; // For `<button>`s
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  &:hover,
  &:focus,
  &:focus-within,
  &.active {
    @include dropdown-menu-mixins.dropdown-item-hover;
  }

  &.disabled,
  &:disabled {
    color: variables.$text-muted-color;
    pointer-events: none;
    background-color: transparent;
  }
}
