/* =============================================================================
-------------------  WIDGETS ---------------------------------------------------
============================================================================= */

/* stacked tabs */
.custom-stacked-tabs .nav.nav-pills.nav-stacked li {
  margin-bottom: 8px;
}
.custom-stacked-tabs .nav.nav-pills.nav-stacked li a {
  border: 1px solid #e9e9e9;
  border-right-width: 0;
  min-height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.6;
  line-height: 1.2em;
}
.custom-stacked-tabs .nav.nav-pills.nav-stacked li a:hover,
.custom-stacked-tabs .nav.nav-pills.nav-stacked li a:focus {
  background-color: #FCFCFC;
  opacity: 0.9;
}
.custom-stacked-tabs .nav.nav-pills.nav-stacked li.active a {
  background-color: #FCFCFC;
  color: #1e1e1e;
  opacity: 1;
}
@media (max-width: 350px) {
  .custom-stacked-tabs .tab-selected.nav.nav-pills.nav-stacked li a .glyphicon {
    margin-left: 2px;
    font-size: 80%;
  }
}
@media (max-width: 991px) {
  .custom-stacked-tabs .tab-selector.nav.nav-pills.nav-stacked li a {
    border-right-width: 1px;
    min-height: 2em;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .custom-stacked-tabs .tab-selector-wrapper {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
  .custom-stacked-tabs .tab-selected {
    position: relative;
    padding-right: 35px;
    letter-spacing: 0.1em;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 17px;
    padding-top: 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .custom-stacked-tabs .tab-selected:after {
    content: "\f0d7";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 10px;
    top: 12px;
  }
  .custom-stacked-tabs .tab-selector-wrapper.tab-selector-visible .tab-selected {
    border-color: #dadada;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0, 0.22);
    box-shadow: 0 0 8px rgba(0,0,0, 0.22);
  }
  .custom-stacked-tabs .tab-selector {
    position: absolute;
    top: 100%;
    margin-top: -5px;
    left: 15px;
    right: 15px;
    background-color: #fff;
    z-index: 90;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 5px 0;
    box-shadow: 0 3px 5px rgba(0,0,0,0.1);
  }
  .custom-stacked-tabs .tab-selector li {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .custom-stacked-tabs {
    padding-right: 15px;
    padding-bottom: 10px;
  }
  .custom-stacked-tabs .nav.nav-pills.nav-stacked {
    padding-top: 15px;
  }
  .custom-stacked-tabs .nav.nav-pills.nav-stacked li {
    margin-right: -15px;
    margin-left: 0;
  }
  .custom-stacked-tabs .nav.nav-pills.nav-stacked li a {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .custom-stacked-tabs .nav.nav-pills.nav-stacked li.active {
    margin-right: -16px;
    z-index: 10;
  }
  .custom-stacked-tabs .nav.nav-pills.nav-stacked li.active a {
    border-right-color: transparent;
  }
  .custom-stacked-tabs .tab-content {
    padding: 0;
  }
  .custom-stacked-tabs .tab-content > div {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    background: #FCFCFC;
  }
}

/* make our user-msg directive block element */
user-msg {
  display: block;
}
.user-msg-above-backdrop {
  position: relative;
  z-index: 1050;
}
.user-msg-above-backdrop user-msg .panel {
  background: #fff;
}
.user-msg-above-backdrop user-msg .panel > .panel-body > .close {
  display: none;
}
.user-msg-above-backdrop user-msg .panel > .panel-body > .close {
  display: none;
}
.user-msg-above-backdrop.with-spinner user-msg .panel > .panel-body > .alert-info {
  padding-left: 50px;
}
.user-msg-above-backdrop.with-spinner .ec-spinner {
  position: absolute;
  top: 36px;
  left: 35px;
  z-index: 1051;
}

/* charts */
.chart-container {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.chart-wrapper.closed {
  overflow: hidden;
  height: 0;
}

/* social labels for user profile page (partially overrides styles from bootstrap-social) */
.connected-accounts .btn-social {
  display: inline-block;
  width: 120px;
  margin-right: 20px;
  pointer-events: none;
}


/* UPLOAD FORMS */
[upload-list-images-view] .thumbnail {
  position:relative;
}
[upload-list-images-view] .thumbnail img {
  max-height: 300px;
  height: 100%;
}
[upload-list-images-view] select.form-control.image-select {
  border-color: #ddd;
}

/* receipt */
table.receipt {
  background: white;
  border: 10px solid white;
  font-family: monospace;
  font-size: 14px;
  box-shadow: 0 0 3px lightgray;
}
table.receipt th {
  font-weight: normal;
  text-align: right;
  padding-right: 5px;
}
table.receipt td {
  font-weight: bold;
}
table.receipt th, table.receipt td {
  vertical-align: baseline;
}

/* code / monospace */
.monospace {
  font-family: monospace;
}

.manage-students-modal [students-filter] .students .new-student .form-group .form-control {
  width: 175px;
}

/* small integer input (+/- input) ========================================== */
.small-integer-input,
.small-integer-input .changer,
.small-integer-input .value {
  display: inline-block;
}
.small-integer-input .changer {
  border: 1px solid #00b4e5;
  border-radius: 50px;
  text-align: center;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.3em;
  color: #00b4e5;
  cursor: pointer;
}
.small-integer-input .changer.disabled {
  cursor: default;
  border-color: #b6b6b6;
  color: #b6b6b6;
}
.small-integer-input .changer .fa {
  font-size: 0.8em;
}
.small-integer-input .value {
  width: 1.5em;
  text-align: center;
  font-weight: bold;
}

.age-and-grade-ranges .separator:after {
  content: ", ";
  margin: 0 0.1em 0 0;
}

/* */
.row-with-icon > .fa {
  vertical-align: top;
  margin-top: 4px;
}
.row-with-icon > .fa + * {
  display: inline-block;
  width: calc(100% - 25px);
}

.results-item-mismatch-modal .age-and-grade-ranges {
  font-weight: bold;
}
.results-item-mismatch-modal .age-and-grade-ranges .dimension {
  text-transform: lowercase;
}
.results-item-mismatch-modal .age-and-grade-ranges .fa {
  display: none;
}
.results-item-mismatch-modal .age-and-grade-ranges .separator:after {
  content: " and ";
}
.results-item-mismatch-modal .date-inputs {
  font-weight: bold;
  display: block;
  padding-top: 5px;
}
.results-item-mismatch-modal .date-inputs input.form-control {
  width: 110px;
  text-align: center;
}

.results-item-mismatch-popover {
  width: 300px;
  max-width: 300px;
}
.make-schedule-for-hearted-modal .child-display-name .age-and-grade {
  font-size: 13px;
}
