school-portal-selector,
ec-school-portal-selector {
  display: block;

  .form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    width: 100%;

    .inputs {
      flex-grow: 1;
    }

    .btn {
      width: auto;
    }
  }
}

.school-portal-selector-popover {
  width: 450px;
  max-width: none;

  school-portal-selector,
  ec-school-portal-selector {
    .form {
      .inputs {
        width: calc(100% - 52px);
      }
    }
  }
}
