@use "base/buttons/modifiers";

.portal-filters-container {
  height: 130px;
}

.portal-filters {

  .filters-bar {
    margin-bottom: 0;
    max-height: 45px;
    display: block;

    .filter-btn-menu .filter-btn {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .menu-body {
      padding-top: 30px;

      &.location {
        min-width: 500px;
        padding-top: 0;
      }

      &.dates {
        overflow-x: visible;
        overflow-y: visible;
        max-height: unset;
        min-width: 320px;
      }
    }

    .filter-menu.dates {
      @media (min-width: 767px) {
        width: 600px;
      }
      @media (max-width: 766px) {
        width: 100%;
      }
    }

    .calendar-picker {
      display: inline-block;
    }

    .favorite-link {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .search-box-wrapper {
    height: 40px;
    margin-bottom: 10px;
  }

  .search-box {
    .tags {
      max-height: 37px;
      border-radius: 30px;
    }
  }

  tags-input .input {
    width: 190px;
  }
}

.filter-and-views-wrapper {
  height: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  position: relative;

  &.right-frame-visible {
    .view-switch-controls {
      display: none;
    }
  }
  .view-switch-controls {
    position: absolute;
    z-index: 2;

    > div {
      display: inline-block;
    }

    .btn.map {
      width: 118px;
    }
  }
}

@media (min-width: 500px) {
  .filter-and-views-wrapper {
    .view-switch-controls {
      right: 0;
      top: 20px;
      position: absolute;
    }
  }
}

@media (min-width: 500px) and (max-width: 1079px) {
  .portal-filters {
    .search-box {
      max-width: calc(100vw - 200px);
    }
  }
}

@media (min-width: 1080px) {
  .portal-filters {
    .search-box {
      max-width: 700px;
    }
  }
}

@media (max-width: 767px) {
  .portal-filters-container {
    height: 110px;
  }
  .filter-and-views-wrapper {
    padding: 10px 0;
  }
}
@media (max-width: 499px) {
  .portal-filters {
    .search-box {
      width: 100%;
    }
  }
  .filter-and-views-wrapper {
    .view-switch-controls {
      position: fixed;
      bottom: calc(10px + var(--extra-bottom-space, 0px));
      text-align: center;
      left: 0;
      right: 0;
      margin: auto;

      .btn.map {
        @include modifiers.btn-raised;
        background-color: #ffffff;
        border-radius: 24px;
      }
    }
  }
}

.more-filters-modal .modal-footer .btn {
  min-width: 100px;
}
@media(min-width: 768px) {
  .portals-more-filters-modal .modal-dialog {
    width: 700px;
  }
}

// Portal filter: location ==========================//
.filter.location.menu-body {
  .location-details {
    position: relative;

    span[geo-location-input] {
      float: left;
      transition: width 0.4s;

      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .location-getter {
        border-radius: 0;
      }
    }

    > input {
      padding-right: 60px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-left: none;
      float: left;

      &:focus {
        position: relative;
      }
    }

    > label {
      font-weight: normal;
      text-align: left;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 16px;
      top: 7px;
    }

    &.miles-digit {
      > input {
        width: 120px;
      }
      span[geo-location-input] {
        width: calc(100% - 120px);
      }
    }
  }
}
// Subject filter ===========================//
.filter-menu.subject {
  max-width: 700px;
}
.menu-body.subject {
  ul {
    &.areas {
      column-count: 3;
      column-gap: 10px;

      @media (max-width: 500px) {
        column-count: 2;
      }

      > li {
        // so no category area wraps to the next column
        display: inline-block;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }
    }

    label {
      // if the checkbox text wraps, it needs to be indented
      padding-left: 15px;
      text-indent: -15px;

      &.category-area {
        font-weight: 700;
        font-size: 17px;
      }
    }
  }
}
// End of Subject filter======================//

.menu-body.age-grade-student {
  .grade-item {
    min-width: 115px;
    padding-left: 15px;
    padding-right: 0;
    float: left;
  }
}

.back-to-filters-wrapper {
  position: absolute;
  top: 0;
  text-align: center;
  left: 0!important;
  right: 0!important;
  margin: auto;
  z-index: 20;

  .btn {
    border-radius: 0 0 10px 10px;
    font-size: 80%;
    padding: 6px 8px;
    @include modifiers.btn-raised;
  }
}
