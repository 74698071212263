.editing-drop-in-dates {
  .drop-in-date-row {
    padding-top: 5px;

    .not-edited {
      cursor: pointer;

      &:hover {
        background: #E9E9E9;
      }
    }

    .date-wrapper {
      min-height: 34px;
      padding: 4px 12px 0 16px;
      width: 150px;

      .edit-icon {
        float: right;
      }
    }

    .input-wrapper {
      width: 150px;

      input {
        font-size: 16px;
        padding-top: 6px;
      }
    }
  }

  .notify-wrapper {
    margin-top: 20px;

    label {
      font-weight: normal;
    }

    input {
      margin-right: 5px !important;
    }
  }
}
