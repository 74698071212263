@use "abstracts/mixins";
@use "abstracts/variables";

[results-item] {
  display: block;
  height: 100%;
}
.results-item {
  height: 100%;
  position: relative;

  button.unstyled {
    background-color: #fff;

    &.hide-from-schedule.x-button {
      position: absolute;
      top: -15px;
      right: -15px;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      color: darkgray;
      border: 1px solid #eee;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      z-index: 5;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-size: 15px 15px;
      opacity: 1;

      &:hover {
        cursor: pointer;
        background-color: #EEEEEE;
      }
    }
  }

  a:hover,
  a:focus,
  a:active {
    color: variables.$text-color;
    text-decoration: none;
  }
}
.class-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.1),0 0px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.class-div:hover,
.class-div.selected-box {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}
.class-div.selected-box {
  background-color: variables.$selected-bg-color;
}
.class-div .program-media {
  display: block;
  position: relative;
  cursor: pointer;
}
.class-div .program-media ec-single-media-of-program-or-provider {
  width: 100%;
  height: 0;
  padding-top: 66.666667%;
  position: relative;
}
.class-div .program-media ec-single-media-of-program-or-provider img {
  position: absolute;
  top: 0;
  left: 0;
}
.class-div .program-main-info {
  padding: variables.$program-card-padding-tb variables.$program-card-padding-rl;
  position: relative;
  flex-grow: 1;
}
.class-div .program-media .discounts {
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  bottom: 15px;
  padding-right: 30px;
  overflow: hidden;
  z-index: 1;
}
.class-div .program-media .discount {
  float: left;
  clear: both;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 5px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1em;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 2px 5px 0 rgba(4,51,47,0.3);
}
.class-div .program-media .secondary-actions {
  position: absolute;
  right: 15px;
  bottom: 5px;
  z-index: 8;
}
.class-div .price {
  position: absolute;
  bottom: -12px;
  left: 0;
  background: #e3e3e3;
  color: variables.$dark-text-color;
  padding: 2px 20px;
  margin-right: -15px;
  font-size: 14px;
}
.class-div .price .price-amount {
  font-size: 15px;
  font-weight: bold;
}
.class-div .price .old-price {
  position: relative;
  text-decoration: none;
  font-size: 0.85em;
  color: #666;
}
.class-div .price .old-price:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid variables.$hero-color;
  transform: rotate(-30deg);
}
.class-div .price .see-detailed {
  font-size: 15px;
}
.class-div .new-icon {
  position: absolute;
  top: 10px;
  right: -20px;
  z-index: 2;
  transform: rotate(45deg);
  background: #fff;
  color: #999;
  padding: 0 20px;
  font-size: 12px;
  transition: top 0.25s ease;
}
.class-div .program-name,
.class-div .provider-name {
  display: block;
  line-height: 1.25em;
  overflow: hidden;

  a {
    color: variables.$dark-text-color;

    &:hover {
      color: variables.$link-hover-color;
    }
  }
}
.class-div .location-and-dropin-info {
  display: flex;
  height: 19px;
  gap: 5px;
  text-transform: uppercase;
  color: #828282;
  font-size: 12px;
  margin-bottom: 5px;
  overflow-y: hidden;

  .miles {
    text-transform: none;
    white-space: nowrap;
  }
}
.class-div .program-name {
  margin-bottom: 5px;
  height: 45px;
}
.class-div .bottom-section {
  border-top: 1px solid #eee;
  padding: variables.$program-card-padding-tb variables.$program-card-padding-rl;
  height: 65px;
}
.class-div .provider {
  position: relative;
  height: 45px;
}
.class-div .provider.with-logo {
  padding-left: 50px;
}
.class-div .provider-name {
  font-size: 14px;
  padding-top: 3px;
  font-weight: normal;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.class-div .provider-logo {
  display: block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #f7f7f7;
  position: absolute;
  top: 2px;
  left: 0;
}
.class-div .provider-logo img {
  width: 100%;
  height: 100%;
  background: #fff;
}
.class-div .star-rating {
  font-size: 14px;

  &.readonly {
    .star-rating-on, .star-rating-off {
      cursor: pointer;
    }
  }
}
.class-div .provider-more {
  font-size: 13px;
  white-space: nowrap;
  display: block;
}
.class-div .short-details {
  font-size: 14px;
  color: #4f4f4f;
}
.class-div .short-details .short-details-section {

  .fa {
    display: none;
  }

  &.soc-cue {

    .social-cue-snippet {
      display: block;
    }
  }
}

//program card skeleton for loading state
.class-div.skeleton {

  .media {
    width: 100%;
    padding-top: 66.666667%; /* 3:2 aspect ratio of the image */
    background-color: variables.$skeleton-base-color;
  }

  .line {
    width: 100%;
    height: 16px;
    margin-top: 3px;
    margin-bottom: 8px;
    border-radius: 7px;
    @include mixins.skeleton-animated-item;
  }

  .short-details {
    width: 60%;
    overflow: hidden; // for margin-top of .line to have effect
  }

  .bottom-section {
    .provider {
      overflow: hidden; // for margin-top of .line to have effect

      .provider-logo {
        @include mixins.skeleton-animated-item;
        & {
          position: absolute;
        }
      }
    }
  }
}
