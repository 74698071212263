@use "abstracts/variables";

.simple-chart-container {
  border: 1px solid #ddd;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 100px 50px;

  .simple-chart-title {
    font-size: 28px;
    line-height: 1.5em;
    font-weight: normal;
    color: variables.$text-color;
    margin-bottom: 80px;
    margin-left: -15px;
  }
}


.simple-chart {
  font-size: 18px;
  white-space: nowrap;

  .chart-row {

    .chart-line-title {
      text-align: right;
    }

    .chart-line-details {
      color: #8D8D8D;
    }

    .chart-line-fill {
      position: relative;
      border-left: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      height: 100px;
      margin-left: -1px;
      padding-top: 30px;
      text-align: right;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: -1px;
        z-index: 10;
        height: 30px;
        background-color: #ccc;
      }

      .chart-line-num {
        position: absolute;
        right: -20px;
        top: 40px;
        display: inline-block;
        width: 40px;
        text-align: center;
      }
    }
  }

  .chart-row.last-chart-row .chart-line-fill {
    border: none;
  }
}
