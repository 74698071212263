.or-separator {
  background: none;
  height: 34px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;

  hr {
    margin: -23px auto 10px;
    width: 100%;
  }

  .or-separator-text {
    background-color: #fff;
    display: inline-block;
    padding: 8px;
  }
}
