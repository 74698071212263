@use "abstracts/variables";

.panel {
  margin-bottom: 20px;
  background-color: transparent;
  border-radius: variables.$base-border-radius;
}

.panel-body {
  padding:15px 20px;
}

.panel-heading {
  padding:0;
  position: relative;
  transition:background 0.45s ease;
  -webkit-transition:background 0.45s ease;
}

.panel-heading a:not(.btn) {
  display: block;
  padding: 15px 20px;
}

.panel-title {
  font-size: 15px;
}

.panel-icon {
  position: absolute;
  right:20px;
  top:50%;
  display: inline-block;
  font-family:FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  font-size:18px;
  margin-top: -9px;
  color:#303030 !important;
}

.panel-icon:before {
  content:'\f106';
}

.collapsed .panel-icon:before {
  content:'\f107';
}

/* Default Color */
.panel-default {
  border-color: variables.$section-border-color;
}

.panel-default > .panel-heading {
  color: #505050;
  background-color: #f9f9f9;
  border-color: #d7d7d7;
}

.panel-default > .panel-heading:hover {
  background-color: #f2f2f2;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: variables.$section-border-color;
}

.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top-color: variables.$section-border-color;
}

/* Custom Color */
.panel-custom > .panel-heading {
  color: #f1f1f1;
}

/* Primary (Blue) add hover state */
.panel-primary > .panel-heading:hover {
  background-color: #286090;
}

/* Inverse (Dark)*/
.panel-inverse {
  border-color: #1a1a1a;
}

.panel-inverse > .panel-heading {
  color: #bebebe;
  background-color: #2a2a2a;
  border-color: #1a1a1a;
}

.panel-inverse > .panel-heading:hover {
  background-color: #1a1a1a;
}

.panel-inverse > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #1a1a1a;
}

.panel-inverse .panel-icon {
  color:#eaeaea !important;
}


.panel-primary .panel-title a,
.panel-custom .panel-title a {
  color:#f5f5f5;
}

.panel-primary .panel-icon,
.panel-custom .panel-icon {
  color:#f1f1f1 !important;
}

.panel-custom .panel-title a:hover,
.panel-primary .panel-title a:hover,
.panel-custom .panel-title a:focus,
.panel-primary .panel-title a:focus {
  color:#fff;
}

.panel-inverse .panel-title a {
  color:#eaeaea;
}

.panel-inverse .panel-title a:hover,
.panel-inverse .panel-title a:focus {
  color:#f1f1f1;
}


/* Panel Bordered */
.panel-bordered,
.panel-border-tb {
  border-color: variables.$section-border-color;
}

.panel-bordered > .panel-heading,
.panel-border-tb > .panel-heading {
  border-color: variables.$section-border-color;
}

.panel-bordered > .panel-heading + .panel-collapse > .panel-body,
.panel-der-tb > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: variables.$section-border-color;
}

.panel-border-tb > .panel-heading {
  border-radius: 0;
}

.panel-border-tb.panel {
  border-radius: 0;
  box-shadow:none;
  -webkit-box-shadow:none;
  border-left:none;
  border-right:none;
}

/* End of copied from theme */


.panel.panel-light {
  background-color: #FCFCFC;
}
.panel-title {
  font-size: 17px;
}
.panel-title > span:first-child {
  display: block;
  padding: 15px 20px;
}
.panel-title > span.panel-icon {
  left: 20px;
  right: auto;
  display: inline-block;
  padding: 0;
}
.panel-title > .panel-icon + * {
  padding-left: 45px !important;
}
.panel-title .panel-title-box {
  display: inline-block;
  margin: 0;
  padding: 15px 20px;
  border-right: 1px solid variables.$section-border-color;
  font-weight: normal;
}
.panel-title .panel-title-box:first-child {
  display: inline-block;
}
.panel-title .panel-title-box.no-border {
  border: none;
}
.panel-title .panel-title-box a {
  padding: 0;
  display: inline;
}

.panel-icon:before {
  content:'\f107';
}
.panel-open .panel-icon:before {
  content:'\f106';
}
.collapsed .panel-icon:before {
  content:'\f107';
}
