@use "abstracts/variables";
@use "abstracts/mixins";

.star-rating-info {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-size: 0.9em;
}
.star-rating-big .star-rating,
.star-rating.star-rating-big {
  font-size: 1.5em;
}
.star-rating .star-rating-on,
.star-rating .star-rating-off {
  color: variables.$rating-stars-color;
  margin-left: 0.05em;
  margin-right: 0.05em;
}
.star-rating.disabled .star-rating-on,
.star-rating.disabled .star-rating-off {
  color: #777;
}
.star-rating.clickable {
  .star-rating-on, .star-rating-off {
    cursor: pointer;
  }
}
.star-rating .visually-hidden {
  @include mixins.visually-hidden;
}
