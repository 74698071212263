@use 'abstracts/variables';

.nav-tabs,
.nav-pills {
  margin-bottom: 20px;
}

.nav-tabs {
  border-bottom: 1px solid variables.$section-border-color;
}

.nav-tabs,
.nav-pills {
  > li {
    > a,
    > .a {
      color:#606060;
      background: none;

      &:hover,
      &:focus {
        border-color: transparent;
        background-color: #f3f3f3;
      }

      > i {
        margin-right: 10px;
      }
    }
  }
}

.nav-tabs {
  > li {
    > a,
    > .a {
      margin-right: -1px;

      &:hover {
        border-color: variables.$section-border-color;
      }
    }
  }

  > li.active {
    > a,
    > .a {
      &,
      &:hover,
      &:focus {
        color: #505050;
        background-color: #fff;
        border: 1px solid variables.$section-border-color;
        border-top-width:4px;
        margin-top:-3px;
        border-bottom-color: transparent;
      }
    }
  }
}

@media (max-width: 767px){
  .nav-tabs,
  .nav-pills {
    > li {
      > a,
      > .a {
        /* Prevent text Overflow for nav-tabs */
        max-width:80px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        > i {
          display: none;
        }
      }
    }
  }
}

/* Nav pills - pills tab */
.nav-pills > li + li {
  margin-left: 3px;
}

.nav-pills > li.active {
  > a,
  > .a {
    &,
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

/* End of copied from theme */

.nav-tabs,
.nav-pills {
  > li {
    > a,
    > .a {
      max-width: none;

      tab-heading > i {
        margin-right: 10px;
      }
    }
  }
}

.nav-tabs.nav-justified > li.fake {
  border-bottom: 1px solid #ddd;

  a,
  .a {
    visibility: hidden;
    border-bottom-width: 0;
  }
}

.nav-tabs > li.dropdown {
  &.open {
    > a,
    > .a {
      &,
      &:hover,
      &:focus {
        background-color: #fff;
        border-color: #ccc;
        z-index: 1;
      }
    }
  }

  > .dropdown-menu > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}
