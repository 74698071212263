@use "sass:color";
@use "abstracts/variables";
@use "mixins";

.btn-green {
  $color-1: color.adjust(variables.$theme-color-green, $lightness: -10%);
  $color-2: color.adjust(variables.$theme-color-green, $lightness: -17%);

  @include mixins.button-color(
    $color-1, $color-1, $color-2, $color-2
  );
  @include mixins.button-color-border(
    $color-2, $color-2, $color-2, $color-2
  );

  &.btn.btn-border {
    .media-play-icon:before {
      background-color: $color-1;
    }
  }
}
