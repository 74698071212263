@use "abstracts/variables";

/*
 TODO: styles below were copied from theme CSS file and should be cleaned up
 to have only rules that we really use
*/

.callout {
	position: relative;
	margin-bottom: 60px;
	padding:30px 25px;
	background-color: #f1f1f1;
	overflow: hidden;
}

.callout.lighter {
	background-color: #f8f8f8;
}

.callout.large {
	padding:40px 30px;
}

.callout.larger {
	padding:50px 30px;
}

.callout.bordered {
	background-color: transparent !important;
	border:3px solid #f1f1f1;
}

.callout.no-bg {
	background-color: transparent !important;
}

.callout.white {
	background-color: #fff !important;
}

.callout.no-border-top {
	border-top:none !important;
}

.callout.bordered.fullwidth {
	border-left:none;
	border-right:none;
}

.callout.callout-vertical {
	text-align: center;
	padding:35px 25px;
}

.callout.callout-vertical.larger {
	padding:50px 30px;
}

.callout:after {
	content:'';
	position: absolute;
	left:0;
	top:0;
	bottom:0;
	display: block;
	width:3px;
}

.callout.no-border:after,
.callout.bordered:after,
.callout.callout-vertical.no-border:after {
	display: none;
}

.callout.callout-vertical:after {
	width:auto;
	bottom:auto;
	left:0;
	top:0;
	right:0;
	height:3px;
}

.callout-icon {
	position: absolute;
	left:30px;
	top:50%;
	z-index: 0;
	line-height: 1;
	font-size: 6em;
	color:#fff;
	/* vertical center and rotate little */
	transform:rotate(10deg) translateY(-50%);
	-webkit-transform:rotate(10deg) translateY(-50%);
	-ms-transform:rotate(10deg) translateY(-50%);
}

.callout-icon.reverse {
	left:auto;
	right:30px;
	transform:rotate(-10deg) translateY(-50%);
	-webkit-transform:rotate(-10deg) translateY(-50%);
	-ms-transform:rotate(-10deg) translateY(-50%);
}

.bordered .callout-icon  {
	color:#eaeaea;
}

.callout-wrapper {
	display: table;
	width:100%;
	position: relative;
	z-index: 1;
}

.callout-left,
.callout-right {
	display: table-cell;
	vertical-align: middle;
}

.callout-title {
	margin-bottom:8px;
	font-size: 20px;
	line-height: 1.4;
}

.callout-vertical .callout-title {
	margin-bottom: 13px;
}

.callout-desc {
	font-size: 16px;
	line-height: 1.5;
}

.callout-vertical .callout-desc {
	max-width: 800px;
	margin:0 auto 25px;
}

/* fix for auto inline-block margin */
.callout-action {
	font-size: 0;
}

.callout-action .btn + .btn {
	margin-left:10px;
}

.callout-action form {
	max-width:420px;
	margin:0 auto;
}

/* Color Schemes - Dark */
.callout.dark {
	background-color: #2a2a2a;
	border-color:#2a2a2a;
}

.callout.dark:not(.bordered) {
	color:#9a9a9a;
}

.callout.dark:not(.bordered) .callout-title {
	color:#bebebe;
}

.callout.dark .callout-icon {
	color:#4e4e4e;
}

/* Color Schemes - Custom*/
.callout.custom:not(.bordered) {
	color:#e1e1e1;
}

.callout.custom:not(.bordered) .callout-title {
	color:#eaeaea;
}

@media (min-width: 768px) {

	.callout-left {
		padding-right: 25px;
	}

	.callout-left *:last-child,
	.callout-right *:last-child {
		margin-bottom: 0;
	}

	.callout-right {
		text-align: right;
	}

	.callout-right.text-left {
		text-align: left;
	}
}

@media (max-width: 767px) {
	.callout {
		text-align: center;
	}

	.callout-wrapper,
	.callout-left,
	.callout-right {
		display: block;
	}

	.callout-desc {
		font-size: 15px;
	}

	.callout-left + .callout-right .callout-title {
		margin-top:20px;
	}

	.callout-left + .callout-right .callout-desc {
		margin-bottom: 0;
	}

	.callout-action .min-width.btn {
		min-width: 0;
	}
}

/* end of styles to cleanup */

.callout {
  background-color: #f5f5f5;
}
#content .container > .callout:first-child {
  margin-top: -20px;
}
.callout-desc.with-icon {
  padding-left: 2em;
  position: relative;
}
.callout-desc.with-icon > i {
  position: absolute;
  left: 0;
  top: 0.2em;
}
.callout.callout-vertical {
  border-bottom: #eee 1px solid !important;
}
.callout.callout-vertical.smaller {
  padding: 25px;
}
.callout.callout-vertical.smallest {
  padding: 10px;
}
.callout.callout-vertical:after {
  background-color: variables.$link-color;
}
.callout.no-border:after {
  display: none;
}
