@use "abstracts/variables";

body small,
body .small {
  font-size: variables.$small-font-size;
}

.default-line-height {
  line-height: variables.$default-line-height;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	font-weight: 700;
	line-height: 1.1;
	color: variables.$dark-text-color;
  margin-top: 0;

  small,
  .small {
    color: inherit;
  }

  .light {
    font-weight: normal;
  }
}

h1,
.h1 {
	font-size: 28px;
	margin-bottom: 20px;
}

h2,
.h2{
	font-size: 24px;
	margin-bottom: 20px;
}

h3,
.h3 {
	font-size: 20px;
	margin-bottom: 15px;
}

h4,
.h4 {
	font-size: 18px;
	margin-bottom: 15px;
}

h5,
.h5 {
	font-size: 15px;
	margin-bottom: 15px;
}

h6,
.h6 {
	font-size: 13px;
	margin-bottom: 10px;
}

ul,
ol {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

p {
	margin: 0 0 20px;
}

.text-bold {
  font-weight: 700;
}

.text-success {
  color: variables.$text-success-color;
}
.text-info {
  color: variables.$text-info-color;
}
.text-warning {
  color: variables.$text-warning-color;
}
.text-danger {
  color: variables.$text-danger-color;
}
.text-muted {
  color: variables.$text-muted-color;
}
.text-default {
  color: variables.$text-color;
}

.first-color {
  color: variables.$hero-color;
}
a.first-color:hover,
a.first-color:focus,
a.first-color:active {
  color: variables.$hero-color-hover;
}

@mixin link-color {
  color: variables.$link-color;

  &:hover,
  &:focus,
  &:active {
    color: variables.$link-hover-color;
  }
}
.link-color {
  @include link-color;
}

.heart-color {
  color: variables.$heart-color;
}

.highlighted-box,
.highlighted {
  background-color: variables.$highlighted-bg-color;
}
/* selected must be after highlighted as it should overwrite it if two classes
are on one element */
.selected-box,
.selected {
  background-color: variables.$selected-bg-color;
}

.details-icon {
  color: variables.$details-icon-color;
}

.text-left,
.form-horizontal .control-label.text-left {
  text-align: left;
}
.text-right,
.form-horizontal .control-label.text-right {
  text-align: right;
}

.price-fraction {
  font-size: 75%;
  position: relative;
  bottom: 0.25em;
}

// we should apply this class to p, div, span inside which we have long sentences
// long sentences are hard to read and the recommended max width for text is
// 700px
.text-wrapper {
  max-width: 700px;
}
