@use "base/font-awesome";

.ec-spinner {
  @include font-awesome.font-awesome('circle-o-notch');
  & {
    @include font-awesome.fa-lg;
    font-size: 24px;
    position: relative;
    top: 0;
    margin-right: 5px;
    animation: fa-spin 2s linear infinite;
    opacity: 0.5;
  }
}

.btn-spinner {
  position: relative;
  padding-left: 35px;

 .ec-spinner {
    position: absolute;
    top: -1px;
    left: 0;
    margin: 0;
  }
}

.spinner-overlay {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  text-align: center;

  &.hard-cover {
    background-color: rgba(255,255,255,0.85);
  }

  .spinner-wrapper {
    position: absolute;
    top: auto;
    bottom: 5px;
    left: 50%;
    width: 65px;
    height: 65px;
    min-width: 65px;
    margin-left: -32px;
    text-align: center;
    display: block;

    &.popover {
      background: white;
    }

    .ec-spinner {
      position: relative;
      top: 20px;
      font-size: 34px;
      margin: 0;
    }

    &.centered {
      top: 50%;
      left: 50%;
      bottom: auto;
    }
  }
}

.input-group-addon .ec-spinner {
  font-size: 20px;
  position: static;
  margin-left: -3px;
  margin-right: -3px;
}
