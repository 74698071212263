// Code below is needed by some styles of components and needs to be imported
// there, so we need to make sure that we only keep mixins here as such file
// can be imported many times without making final CSS file bigger (if we would
// include CSS styling here it would be copied into all places where this file
// is imported).

@use '../../abstracts/variables';

@mixin carousel-control {
  display: block;
  background: rgba(255,255,255,0.7);
  transition: background-color 0.35s ease;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  color: variables.$link-color;
  border: none;
  border-radius: 20px;
  text-align: center;
  line-height: 37px;
  position: absolute;
  top: 50%;
  font-size: 20px;
  text-shadow: none;
  opacity: 1;

  .glyphicon-chevron-left,
  .icon-prev,
  .glyphicon-chevron-right,
  .icon-next {
    width: 15px;
    height: 15px;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    display: inline-block;
    border-right: 3px solid variables.$link-color;
    border-bottom: 3px solid variables.$link-color;

    &:before {
      content: "";
    }
  }

  &.left {
    left: 5px;
  }

  &.right {
    right: 5px;
  }

  .glyphicon-chevron-left,
  .icon-prev {
    left: 4px;
    transform: rotate(-225deg);
  }

  .glyphicon-chevron-right,
  .icon-next {
    left: -3px;
    transform: rotate(-45deg);
  }

  &:hover {
    background: rgba(255,255,255,1);
  }

  &:active {
    background: #F6F6F6;
  }
}

@mixin carousel-control-disabled {
  background: rgba(181, 181, 181, 0.3);

  .glyphicon-chevron-left,
  .icon-prev,
  .glyphicon-chevron-right,
  .icon-next {
    border-right-color: variables.$text-muted-color;
    border-bottom-color: variables.$text-muted-color;
  }
}
