@use "abstracts/mixins";
@use "abstracts/variables";

.awards-page-content {

  .hero-blocks-section {
    padding-bottom: 75px;
  }

  .hero-block-text {
    max-width: 510px;
  }

  .winners-section {
    margin-top: 50px;

    &.no-image {
      margin-top: 0;
    }

    .container {
      position: relative;
    }

    .svg-stars-many {
      position: absolute;
      fill: variables.$theme-color-pink-light;
      right: 250px;
      top: 0;
      width: 260px;
    }

    .winners-title {
      font-size: 28px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 30px;
    }

    .subtitle {
      color: #000;
    }
    .winners-category {
      position: relative;
      margin-bottom: 35px;

      &.winner-single {
        margin-top: 30px;
        max-width: 650px;

        .review {
          margin-top: 20px;
          margin-bottom: 40px;
          font-style: italic;
          font-size: 20px;
          position: relative;
          padding-left: 60px;

          .fa {
            position: absolute;
            left: 0px;
            font-size: 50px;
            top: 0px;
            color: #aaa;
          }
        }

        .browse-programs {
          text-align: center;

          .btn {
            margin-bottom: 10px;
            width: 155px;

            &:first-of-type {
              margin-right: 20px;

              @media (max-width: 400px) {
                margin-right: 0;
              }
            }
          }
        }
      }

      .category {
        font-weight: 700;
      }

      .winner-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .winner {
        font-size: 18px;
        line-height: 29px;
        margin-left: 20px;
        margin-bottom: 10px;
        @include mixins.clearfix;

        .name {
          font-weight: bold;
        }

        .tag {
          font-size: 15px;
          margin-top: -5px;
        }

      }

      .winner-media {
        margin-top: 20px;
        margin-bottom: 20px;

        .winner-media-caption {
          font-size: 14px;
          margin-top: 10px;
        }
      }

      .winner-header {
        margin-bottom: 25px;

        &:after {
          content: "";
          display: block;
          width: 100px;
          height: 2px;
          background: #c6084a;
          margin-top: 25px;
        }

        .winner-header-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 0;

          strong {
            color: #c6084a;
          }
        }

        .winner-header-details {
          font-size: 18px;
        }

        .share-object {
          margin-top: 20px;
        }
      }

      .winner-description {
        font-size: 18px;

        ul {
          margin: 30px;

          li {
            margin-bottom: 10px;
          }
        }

        .strong-quote {
          display: block;
          font-size: 22px;
          font-style: italic;
          margin: 30px;
          font-weight: normal;
        }

        .winner-interview {

          &:before {
            content: "";
            display: block;
            width: 100px;
            height: 2px;
            background: #c6084a;
            margin-top: 25px;
            margin-bottom: 20px;
          }

          .winner-interview-intro {
            font-style: italic;
            color: #9B9B9B;
          }

          .interviewer {
            background-color: #FAFAFA;
            padding: 15px 20px 15px 100px;
            margin: 50px 0;
            min-height: 100px;
            position: relative;

            .interviewer-photo {
              position: absolute;
              left: 20px;
              top: 20px;
              width: 60px;
              height: 60px;
            }

            .interviewer-name {
              font-weight: bold;
            }
          }
        }
      }
    }

    .award-badge {
      width: 120px;
    }
  }

  @media (max-width: 1199px) {
    .winners-section .svg-stars-many {
      right: 100px;
    }
  }

  @media (max-width: 991px) {

    .hero-blocks-section .hero-block {
      margin-top: 70px;
    }

    .winners-section {
      .svg-stars-many {
        right: 100px;
        width: 130px;
      }

      .winners-title {
        font-size: 20px;
        line-height: 28px;
      }

      .winners-category {

        .winner {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: 767px) {

    .hero-blocks-section .hero-block {
      margin-top: 40px;
    }

    .winners-section {

      .award-badge {
        width: 300px;
      }

      .container {
        overflow: hidden;
      }

      .svg-stars-many {
        right: -40px;
        width: 130px;
      }

      .winners-title {
        font-size: 18px;
        line-height: 27px;
      }

      .winner {
        .winner-title {
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 10px;
        }

        .winner-description {
          font-size: 16px;

          .strong-quote {
            font-size: 20px;
          }
        }
      }

      .winners-category.winner-single {
        .review {
          padding-left: 40px;
          font-size: 16px;
          .fa {
            font-size: 30px;
          }
        }
      }
    }
  }
}

.awards-winner-page-content {

  .hero-blocks-section {
    padding-bottom: 150px;
  }

  .winners-section {
    margin-top: -150px;

    &.no-image {
      margin-top: 0;
    }

    .winner.winner-single {
      margin-top: 0;
    }
  }
}

.awards-nominate-page-content {

  .bg {
    height: 200px;
    background: variables.$theme-color-pink-light;
  }

  .google-form {
    max-width: 750px;
    width: 90%;
    height: 1200px;
    display: block;
    margin: -100px auto 0;
  }

  @media (max-width: 767px) {
    background: variables.$theme-color-pink-light;
    padding-top: 40px;

    .bg {
      display: none;
    }

    .google-form {
      height: 1250px;
      margin-top: 0;
    }
  }
}
