[addtocalendar] {

  .btn.dropdown-toggle {
    padding-left: 10px;

    .fa {
      margin-right: 6px;
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-top: 1px solid #ccc;

    li {
      a {
        height: 40px;
        padding: 9px 16px 9px 8px;
        border: 1px solid #ccc;
        border-top: none;

        .fa {
          margin-right: 5px;

          &.fa-google {
            color: #3866d0;
          }

          &.fa-yahoo {
            color: #4a00a0;
          }

          &.fa-apple {
            color: #000;
          }

          &.fa-calendar {
            color: #0078D4;
          }
        }
      }
    }
  }
}
