@use "portals-common";

body.page-type-district-page {
  @include portals-common.portals-common;
  @include portals-common.portal-with-page-header;
  @include portals-common.disable-suggestions-feature;

  .schools-list-container {
    overflow-y: auto;
  }

  @media (max-width: 450px) {
    .schools-container school-portal-selector.inline .inputs {
      min-width: 0;
      width: 100%;
    }
  }
}
