.btn.btn-danger-on-hover:hover,
.btn.btn-danger-on-hover:active {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-strong,
.btn-bold {
  font-weight: bold;
}

.btn.no-events {
  pointer-events: none;
}

.btn-block {
  display: block;
  white-space: normal;
  width: 100%;
}

.btn-full-size {
  width: 100%;
}

// backgrounds

.btn.btn-border.btn-white-bg {
  background-color: #fff;
}

.btn.no-background {
  background-color: transparent;
}

.btn-transparent {
  background-color: transparent !important;
}

// end of backgrounds

// shadows

@mixin btn-raised {
  box-shadow: 0 1px 3px rgba(166, 166, 166, 0.75);
}
.btn-raised {
  @include btn-raised;
}

.btn-shadow {
  box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.2);
  text-shadow: 1px 0.5px 2px rgba(0,0,0,0.5);
}
.btn-border.btn-shadow {
  text-shadow: none;
}

// end of shadows
