@use "abstracts/variables";

body {
  font: variables.$default-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: variables.$text-color;
}

a:active,
a:focus,
input,
select,
textarea {
	outline: none;
}

// links
@mixin link-like {
  color: variables.$link-color;
  transition: color variables.$link-hover-transition-time;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: variables.$link-hover-color;
    text-decoration: underline;
    outline: 0;
  }
}

span.link,
a {
  @include link-like;
}

a.danger-on-hover:hover {
  color: variables.$text-danger-color;
}

a[disabled] {
  color: variables.$text-muted-color !important;
  cursor: not-allowed;
  pointer-events: none;
}

// selection
::-moz-selection {
  background-color: #3390FF;
  color: #fff;
}
::selection {
  background-color: #3390FF;
  color: #fff;
}

.clickable:hover {
  cursor: pointer;
}

[hidden],
template {
  display: none !important;
}
