// btn sm
$btn-sm-v-padding: 7px;
$btn-sm-h-padding: 10px;
@mixin btn-sm {
  line-height: 1.5;
  font-size: 12px;
  padding: $btn-sm-v-padding $btn-sm-h-padding;
}
@mixin btn-sm-border {
  padding: $btn-sm-v-padding - 1px $btn-sm-h-padding - 1px;
}
@mixin btn-sm-border-light {
  padding: $btn-sm-v-padding $btn-sm-h-padding;
}
// btn sm padding
@mixin btn-sm-padding {
  padding: $btn-sm-v-padding - 1px $btn-sm-h-padding - 2px;
}
@mixin btn-sm-padding-border {
  padding: $btn-sm-v-padding - 2px $btn-sm-h-padding -3px;
}
@mixin btn-sm-padding-border-light {
  padding: $btn-sm-v-padding - 1px $btn-sm-h-padding - 2px;
}


// btn xs
$btn-xs-v-padding: 2px;
$btn-xs-h-padding: 5px;
@mixin btn-xs {
  font-size: 12px;
  line-height: 1.5;
  padding: $btn-xs-v-padding $btn-xs-h-padding;
}
@mixin btn-xs-border {
  padding: $btn-xs-v-padding - 1px $btn-xs-h-padding - 1px;
}
@mixin btn-xs-border-light {
  padding: $btn-xs-v-padding $btn-xs-h-padding;
}
// btn xs padding
@mixin btn-xs-padding {
  padding: $btn-xs-v-padding - 1px $btn-xs-h-padding;
}
@mixin btn-xs-padding-border {
  padding: $btn-xs-v-padding - 2px $btn-xs-h-padding -1px;
}
@mixin btn-xs-padding-border-light {
  padding: $btn-xs-v-padding - 1px $btn-xs-h-padding;
}


// btn lg
$btn-lg-v-padding: 10px;
$btn-lg-h-padding: 16px;
@mixin btn-lg {
  font-size: 16px;
  line-height: 1.5;
  padding: $btn-lg-v-padding $btn-lg-h-padding;
}
@mixin btn-lg-border {
  padding: $btn-lg-v-padding - 1px $btn-lg-h-padding - 1px;
}
@mixin btn-lg-border-light {
  padding: $btn-lg-v-padding $btn-lg-h-padding;
}


// btn lger
$btn-lger-v-padding: 17px;
$btn-lger-h-padding: 37px;
@mixin btn-lger {
  font-size: 18px;
  line-height: 20px;
  padding: $btn-lger-v-padding $btn-lger-h-padding;
}
@mixin btn-lger-border {
  padding: $btn-lger-v-padding - 1px $btn-lger-h-padding - 1px;
}
@mixin btn-lger-border-light {
  padding: $btn-lger-v-padding $btn-lger-h-padding;
}
