iframe#launcher[style*="right: 0px"] {
  right: -82px !important;
  /* Zendesk's original style: */
  /* transition-property: opacity, top, bottom; */
  transition-property: opacity, top, bottom, right !important;
  bottom: var(--extra-bottom-space, 0) !important;

  &:hover {
    right: -38px !important;
    transition-delay: 0s, 0s, 0s, 0.4s !important;
  }
}
