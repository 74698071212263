local-marketplace-selector,
ec-local-marketplace-selector {
  display: flex;

  span[geo-location-input] {
    flex-grow: 1;

    [g-places-autocomplete-drawer].after-input {
      width: 100%;

      .pac-container {
        width: 100% !important;
        max-width: none !important;
      }
    }
  }

  .btn {
    width: auto;
  }
}

.local-marketplace-selector-popover {
  width: 450px;
  max-width: none;
}
