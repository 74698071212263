@use 'abstracts/variables';

.entry {
	position: relative;
	margin-bottom:50px;
	padding-left:75px;
}

.entry:after {
	content:'';
	position: absolute;
	top:0;
	bottom:0;
	left:30px;
	display:block;
	width:1px;
	background-color: variables.$section-border-color;
	z-index:10;
}

.entry-title {
	font-size: 18px;
	line-height: 1.4;
	margin-bottom: 15px;
  color: #777;
}

.entry-date {
	position: absolute;
	left:0;
	top:0;
	display:block;
	width:60px;
	text-align: center;
	padding:13px 0 0;
	font:700 20px/1 'Lato', Arial, Helvetica, sans-serif;
	background-color: variables.$section-border-color;
	text-transform: uppercase;
	z-index:15;
}

.entry-date > span {
	display:block;
	background-color: #3a3a3a;
	color:#f1f1f1;
	font-size:14px;
	margin-top:11px;
	padding:6px 5px;
}

.entry-date > span.year {
  font-size: 14px;
  margin-top: 0;
  opacity: 0.15;
}


@media (min-width: 768px) {
	.entry {
		margin-bottom:70px;
		padding-left:90px;
	}

	.entry:after {
		left:35px;
	}

	.entry-title {
		font-size:20px;
	}

	.entry-date {
		width:70px;
		padding:17px 0 0;
		font-size:24px;
	}

	.entry-date > span {
		font-size:14px;
		margin-top:13px;
	}
}

@media (min-width: 992px) {
	.entry {
		margin-bottom:80px;
		padding-left:100px;
	}

	.entry:after {
		left:40px;
	}

	.entry-date {
		width:80px;
		padding:18px 0 0;
		font-size:28px;
	}

	.entry-date > span {
		font-size:16px;
		margin-top:16px;
	}

}

/* Timeline */
.blog-timeline {
	padding-left:20px;
	border-left:1px solid variables.$section-border-color;
	position: relative;

  .entry:before,
  .entry:after {
    content:'';
    position: absolute;
    bottom:auto;
    display:block;
    width: 0;
    height: 0;
    border-style: solid;
    background-color: transparent;
    top:25px;
    border-width: 12px 0 12px 12px;
  }

  .entry:before {
    left:-20px;
    border-color: transparent transparent transparent variables.$section-border-color;
    z-index:10;
  }

  .entry:after {
    left:-21px;
    border-color: transparent transparent transparent #fff;
    z-index:11;
  }

  @media (min-width: 768px) {
    padding-left:30px;

    .entry:before,
    .entry:after {
      top:35px;
    }

    .entry:before {
      left:-30px;
    }

    .entry:after {
      left:-31px;
    }
  }

  @media (min-width: 992px) {
    padding-left:35px;

    .entry:before,
    .entry:after {
      top:35px;
    }

    .entry:before {
      left:-35px;
    }

    .entry:after {
      left:-36px;
    }
  }
}
