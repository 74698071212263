/* Copied from theme */

.carousel-control {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top:-20px;
	width: 36px;
	height:40px;
	font-size: 20px;
	line-height: 38px;
	color: #fff;
	text-align: center;
	text-shadow: none;
	filter: alpha(opacity=100);
	opacity: 1;
	border:1px solid #fff;
	transition: background 0.35s ease, border 0.35s ease;
	-webkit-transition: background 0.35s ease, border 0.35s ease;
}

.carousel-control.left {
	left:20px;
	background-image:none;
}

.carousel-control.right {
	right: 20px;
	left: auto;
	background-image:none;
}

.carousel-control:hover,
.carousel-control:focus {
	filter: alpha(opacity=100);
	opacity: 1;
}

/* End of copied from theme */
