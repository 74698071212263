.support-email-form {
  display: block;

  .form-group {
    margin-bottom: 15px;
  }
}

.modal-body .support-email-form {
  margin: 30px;

  .form-group {
    margin-bottom: 15px;
  }

  .btn {
    display: block;
    width: 100%;
    font-weight: bold;
  }
}
