@use "abstracts/variables" as abstracts-variables;
@use "base/buttons/colors/btn-custom2";
@use "components/base/buttons/variables" as buttons-variables;

.flatpickr-calendar {
  border-radius: abstracts-variables.$base-border-radius;
  box-shadow: abstracts-variables.$raised-widget-box-shadow;
  border: abstracts-variables.$raised-widget-border;

  &:before,
  &:after {
    display: none;
  }

  .flatpickr-months {
    .flatpickr-month {
      height: 45px;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      width: 36px;
      height: 40px;
      border-bottom: 1px solid abstracts-variables.$link-color;

      &:hover {
        background: rgba(0,0,0,0.05);
      }
    }

    .flatpickr-prev-month,
    .flatpickr-next-month,
    .flatpickr-current-month {
      color: abstracts-variables.$link-color;

      &:hover {
        color: abstracts-variables.$link-hover-color;
      }
    }

    .flatpickr-current-month {
      padding: 0;
      left: 48px;
      right: 48px;
      width: auto;

      .flatpickr-monthDropdown-months {
        height: 40px;
        width: 112px;
        border-bottom: 1px solid abstracts-variables.$link-color;
        padding: 0;
        margin: 0;
        float: left;
      }

      .numInputWrapper {
        width: 88px;
        padding: 0 22px 0;
        height: 40px;
        border-bottom: 1px solid abstracts-variables.$link-color;
        float: right;
      }

      input.cur-year {
        padding: 0;
        margin: 0;
        height: 100%;
      }

      span {
        top: 0;
        bottom: 0;
        height: auto;
        padding: 0;
        width: 22px;
        border: 0;
        opacity: 1;

        &.arrowDown,
        &.arrowUp {
          &:after {
            top: 0;
            left: 0;
            width: 22px;
            height: 100%;
            border: 0;
            line-height: 40px;
          }
        }

        &.arrowDown {
          right: auto;
          left: 0;

          &:after {
            content: "\2212";  /* minus sign */
          }
        }

        &.arrowUp {
          right: 0;
          left: auto;

          &:after {
            content: "+";
          }
        }
      }
    }
  }

  .flatpickr-weekdays {
    .flatpickr-weekday {
      font-size: 100%;
    }
  }

  .flatpickr-days {
    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
      @include btn-custom2.btn-custom2;
      & {
        transition: buttons-variables.$button-transitions;
      }
    }

    .flatpickr-day.today {
      color: abstracts-variables.$hero-color;

      &:hover {
        color: abstracts-variables.$hero-color-hover;
      }

      &.selected {
        $shadow-color: rgba(255, 255, 255);
        $shadow-blur: 1px;
        text-shadow: 1px  0px $shadow-blur $shadow-color,
                    -1px  0px $shadow-blur $shadow-color,
                     0px  1px $shadow-blur $shadow-color,
                     0px -1px $shadow-blur $shadow-color;
      }

      &:not(.selected) {
        border-color: transparent;
        background: transparent;

        &:hover {
          background-color: #e6e6e6;
          border-color: transparent;
        }
      }
    }
  }
}

.flatpickr-calendar.open {
  // This is styling for calendar attached to body. Most of instances are
  // attached next to input and have separate styling in ec-date-picker Angular
  // component.
  // Default is 99999, but we want to keep it under page header.
  z-index: 999;
}

.flatpickr-calendar.inline {
  box-shadow: none;
  border: 1px solid abstracts-variables.$form-control-border-color;
}
