@use "abstracts/variables";
@use "base/carousel-controls-mixins";

/* Copied from theme */

.carousel-inner > .item {
	padding:0;
	margin:0;
}

.carousel-inner > .item > a {
	display: block;
}

.carousel-caption {
	font-size:16px;
	text-shadow:none;
}

.carousel-indicators li {
	margin:0 4px;
}

/* End of copied from theme */

.carousel {
  .carousel-indicators {
    bottom: 0;

    li {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0 4px;
      text-indent: -999px;
      position: relative;
      border: none;
      border-radius: 30px;
      background: rgba(255,255,255,0.7);
      transition: background-color 0.35s ease;
      opacity: 1;

      &:after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        cursor: pointer;
        background-color: transparent;
        border: 2px solid variables.$link-color;
        border-radius: 10px;
        position: absolute;
        top: 9px;
        left: 9px;
        transition: border-color 0.35s ease;
      }

      &:hover {
        background: rgba(255,255,255,1);

        &:after {
          border-color: variables.$link-hover-color;
        }
      }

      &.active {
        &:after {
          background-color: variables.$link-color;
        }
      }

      &:active {
        background: #F6F6F6;
      }
    }
  }

  .carousel-control {
    @include carousel-controls-mixins.carousel-control;
  }
}

.light-carousel {
  .carousel {
    position: relative;
    margin-bottom: 20px;

    .carousel-indicators {
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: 15;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: center;
      list-style: none;

      li {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 4px;
        text-indent: -999px;
        opacity: 0.7;
        transition: opacity 0.35s ease;
        position: relative;
        border: none;
        border-radius: 30px;
        background: transparent;

        &:after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          cursor: pointer;
          background-color: #fff;
          border: 2px solid #595959;
          border-radius: 10px;
          position: absolute;
          top: 9px;
          left: 9px;
        }

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;

          &:after {
            background-color: #ddd;
          }
        }

        &:active {
          background: #F6F6F6;
        }
      }
    }

    .carousel-control {
      position: absolute;
      top: 50%;
      bottom: auto;
      left: auto;
      right: auto;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      padding: 7px;
      font-size: 20px;
      line-height: 38px;
      color: variables.$text-color;
      text-align: center;
      text-shadow: none;
      border: none;
      background: none;
      opacity: .5;
      transition: opacity 0.35s ease;

      &:focus,
      &:hover {
        text-decoration: none;
        opacity: 1;
      }

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }

      .glyphicon-chevron-left,
      .icon-prev,
      .glyphicon-chevron-right,
      .icon-next {
        width: 15px;
        height: 15px;
        position: static;
        margin: 0;
        display: block;
        border-right: 2px solid #595959;
        border-bottom: 2px solid #595959;

        &:before {
          content: "";
        }
      }

      .glyphicon-chevron-left,
      .icon-prev {
        transform: rotate(-225deg);
      }

      .glyphicon-chevron-right,
      .icon-next {
        transform: rotate(-45deg);
      }
    }
  }
}
