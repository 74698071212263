.class-div-search-wrapper {
  margin: 0 0 15px 0;
  padding-right: 0;
  position: relative;
}

.class-div-search {
  padding: 0;
  background: #fff;
  border: 1px solid #f5f5f5;
  border-bottom: 3px solid #e7e7e7;
  border-radius: 4px;
  height: 487px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;

  .program-media .actions {
    display: none;
  }

  .program-main-info {
    height: 200px;
  }

  .provider {
    height: 54px;
  }

  .buttons {
    position: relative;
    padding-right: 100px;

    .btn-search-result {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .btn-inquire {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

}
