@use 'abstracts/variables';

// TODO: use this mixins for tags-input and ui-select styling

@mixin tags-container {
  padding-top: 5px;
  padding-bottom: 2px;

  &:focus-within {
    box-shadow: variables.$control-focus-box-shadow;
  }
}

@mixin tag-item {
  background: #ddd;
  border: none;
  margin: 1px 6px 4px 0;
  padding: 4px 30px 0 12px;
  height: 26px;
  font: inherit;
  line-height: 18px;
  border-radius: 16px;
  color: inherit;
  white-space: nowrap;
  position: relative;
  transition: background-color 0.4s ease;
  list-style-type: none;

  &:hover {
    box-shadow: none;
    background: #c4c4c4;
  }
}

@mixin tag-item-remove-button {
  text-decoration: none;
  color: #666;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 30px;
  font-weight: normal;
  opacity: 0.6;
  text-shadow: none;
  z-index: 10;
  position: absolute;
  top: 3px;
  right: 3px;
  line-height: 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  transition: opacity 0.4s ease;
  text-indent: 9999em;
  overflow: hidden;
  border-radius: 10px;

  &:after {
    content:"\00d7"; // times
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-indent: 0;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
    box-shadow: variables.$control-focus-box-shadow;
  }
}

@mixin tags-input {
  flex: 1 0 150px;
  width: 100%;
  height: 26px;
  margin: 1px 0px 4px 0;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
