@use "abstracts/variables";
@use "forms-mixins";

.required-star {
  color: #DF4747;
}

input[type="checkbox"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus {
  box-shadow: variables.$control-focus-box-shadow;
}

/* Copied from theme */

.form-group,
.form-control,
.input-group,
.normal-selectbox,
.large-selectbox{
	margin-bottom: 25px;
}

.form-group .input-group {
	margin-bottom:0;
}

.form-group .form-control {
	margin-bottom:0;
}

.input-lg,
.form-group-lg .form-control,
.form-group-lg .form-control-static {
	height: 46px;
	padding: 10px 18px;
}

.input-lg {
	font-size: 16px;
	line-height: 1.5;
}

select.input-lg,
select.form-group-lg .form-control {
	height: 46px;
	line-height: 46px;
}

.form-control-feedback {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.input-lg + .form-control-feedback {
	width: 46px;
	height: 46px;
	line-height: 46px;
}

.input-sm + .form-control-feedback {
	width: 34px;
	height: 34px;
	line-height: 34px;
}

.input-group-btn > .btn + .btn {
	border-left:1px solid rgba(0, 0, 0, .05);
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	margin-left:0;
}

/* input groups*/
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	padding: 10px 18px;
	font-size:16px;
	line-height: 1.5;
}

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 46px;
	line-height: 46px;
	font-size:16px;
	line-height: 1.5;
}


.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 34px;
	padding: 7px 12px;
}

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 34px;
	line-height: 34px;
}

/* input group addons */
.input-group-addon {
	padding: 8px 16px;
	font-size: 14px;
	color: #6a6a6a;
	background-color: transparent;
	border: 1px solid variables.$form-control-border-color;
	font-weight: 400;
  border-radius: variables.$form-control-border-radius;
}

.input-group-addon.input-sm {
	padding: 7px 12px;
}

.input-group-addon.input-lg {
	padding: 9.5px 18px;
	font-size:16px;
}

/* input[type="number"] */
/* Chrome, Safari, Edge, Opera */
input[type="number"].hide-arrows::-webkit-outer-spin-button,
input[type="number"].hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"].form-control.hide-arrows {
  -moz-appearance: textfield !important;
}

/* Checkboxed and Radios */
input[type="radio"],
input[type="checkbox"] {
	margin-top:6px;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	margin-left:-22px;
}

.radio label,
.checkbox label,
.radio-inline,
.checkbox-inline {
	padding-left:22px;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-left:15px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #f1f1f1;
}

.has-feedback label~.form-control-feedback {
	top:26px;
}

/* end of copied from theme */


/* Copied from forms.css */

/* =============================================================================
-------------------  FORMS -----------------------------------------------------
============================================================================= */

.form-group {
  margin-bottom: 15px;
}

.input-group-addon {
  padding: 8px 14px;
}

/* make sure that TAGS-INPUT and UI-SELECT look same as normal inputs */
tags-input *, tags-input *::before, tags-input *::after,
.tags-input *, .tags-input *::before, .tags-input *::after {
  box-sizing: border-box;
}

tags-input,
.tags-input {
  display: block;
}

tags-input .host,
.tags-input .host {
  position: relative;
  margin: 0;
  height: 100%;
}

tags-input .tags,
.tags-input .tags,
.ui-select-container,
.ui-select-multiple.ui-select-bootstrap {
	padding: 8px 14px;
	box-shadow: none;
	-webkit-box-shadow:none;
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	border: 1px solid variables.$form-control-border-color;
	color:inherit;
	font-size:14px;
  border-radius: variables.$form-control-border-radius;
  background-color: #FFF;
}

tags-input .tags.focused,
.tags-input .tags.focused,
.ui-select-container.open {
  border-color: variables.$form-control-focus-border-color;
}

.has-error tags-input .tags,
.has-error tags-input .tags.focused,
.has-error .tags-input .tags,
.has-error .tags-input .tags.focused,
.has-error .ui-select-container,
.has-error .ui-select-container.open,
.has-error .ui-select-multiple.ui-select-bootstrap {
  border-color: variables.$form-error-color;
}

tags-input .tags,
.tags-input .tags {
  overflow: hidden;
}

tags-input .tags .tag-list,
.tags-input .tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .tags .tag-item,
.tags-input .tags .tag-item,
.ui-select-container .ui-select-match-item,
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  background: #ddd;
  border: none;
  margin: 1px 4px 1px 0;
  height: 26px;
  font: inherit;
  padding: 4px 30px 0 12px;
  display: inline-block;
  float: left;
  line-height: 18px;
  border-radius: 16px;
  color: inherit;
  white-space: nowrap;
  position: relative;
  transition: background-color 0.4s ease;
}
tags-input .tags .tag-item:hover,
.tags-input .tags .tag-item:hover,
.ui-select-container .ui-select-match-item:hover,
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item:hover {
  box-shadow: none !important;
  background: #c4c4c4;
}
tags-input .tags .tag-item.no-remove,
.tags-input .tags .tag-item.no-remove,
.ui-select-container .ui-select-match-item.no-remove,
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item.no-remove {
  padding-right: 12px;
}

tags-input .tags .tag-item .remove-button,
.tags-input .tags .tag-item .remove-button,
.ui-select-container .ui-select-match-item .ui-select-match-close,
.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  text-decoration: none;
  color: #666;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  font: normal 30px Arial, sans-serif;
  opacity: 0.6;
  text-shadow: none;
  z-index: 100;
  position: absolute;
  top: 4px;
  right: 4px;
  line-height: 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  transition: opacity 0.4s ease;
}
tags-input .tags .tag-item .remove-button:hover,
.tags-input .tags .tag-item .remove-button:hover,
.ui-select-container .ui-select-match-item .ui-select-match-close:hover {
  opacity: 1;
}
tags-input .tags .tag-item .remove-button:active,
.tags-input .tags .tag-item .remove-button:active,
.ui-select-container .ui-select-match-item .ui-select-match-close:active {
  opacity: 1;
}

[disabled] tags-input .tags .tag-item .remove-button,
[disabled] .tags-input .tags .tag-item .remove-button {
  display: none;
}

tags-input.no-tag-decoration .tags .tag-item {
  background: transparent;
  padding-left: 0;
}

tags-input .tags .input,
tags-input .tags .input:disabled,
.tags-input .tags .input,
.tags-input .tags .input:disabled,
.ui-select-container input.ui-select-search,
.ui-select-container input.ui-select-search:disabled,
.ui-select-multiple.ui-select-bootstrap input.ui-select-search,
.ui-select-multiple.ui-select-bootstrap input.ui-select-search:disabled {
  background-color: rgba(0,0,0,0);
  height: 20px;
  font: inherit;
  margin: 4px;
  border: 0;
  outline: none;
  padding: 0;
}
tags-input .tags .input,
tags-input .tags .input:disabled,
.tags-input .tags .input,
.tags-input .tags .input:disabled {
  float: left;
}
tags-input .tags .input[type="number"]::-webkit-outer-spin-button,
.tags-input .tags .input[type="number"]::-webkit-outer-spin-button,
tags-input .tags .input[type="number"]::-webkit-inner-spin-button,
.tags-input .tags[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
tags-input .tags .input[type="number"],
.tags-input .tags .input[type="number"] {
  -moz-appearance: textfield;
}
.ui-select-container input.ui-select-search,
.ui-select-container input.ui-select-search:disabled,
.ui-select-multiple.ui-select-bootstrap input.ui-select-search,
.ui-select-multiple.ui-select-bootstrap input.ui-select-search:disabled {
  clear: both;
  display: block;
}

.ui-select-container input.ui-select-search,
.ui-select-container input.ui-select-search:disabled {
  width: 100% !important;
}

tags-input .tags .input.invalid-tag,
.tags-input .tags .input.invalid-tag {
  color: variables.$form-error-color;
}

tags-input.ng-invalid .tags {
  border: 1px solid variables.$form-error-color;
}

tags-input .tags .input::-ms-clear,
.tags-input .tags .input::-ms-clear {
  display: none;
}

tags-input .autocomplete,
body > .autocomplete {
  font-size: 14px;
}

tags-input .autocomplete,
body > .autocomplete,
.tags-input .autocomplete {
  margin-top: -1px;
  position: absolute;
  padding: 5px 0;
  z-index: 999;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  max-height: 200px;
  overflow-y: auto;
}
tags-input .autocomplete .suggestion-list,
body > .autocomplete .suggestion-list,
.tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #FFF;
}
tags-input .autocomplete .suggestion-item,
body > .autocomplete .suggestion-item,
.tags-input .autocomplete .suggestion-item {
  padding: 3px 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  line-height: 1.42857;
  color: #333;
  text-align: left;
}
tags-input .autocomplete .suggestion-list .suggestion-item:hover,
body > .autocomplete .suggestion-list .suggestion-item:hover,
.tags-input .autocomplete .suggestion-list .suggestion-item:hover {
  background-color: #428BCA !important;
  color: #FFF !important;
}
tags-input .autocomplete .suggestion-item.selected,
body > .autocomplete .suggestion-item.selected,
.tags-input .autocomplete .suggestion-item.selected {
  background-color: #428BCA !important;
  color: #FFF !important;
}
tags-input .autocomplete .suggestion-item em,
body > .autocomplete .suggestion-item em,
.tags-input .autocomplete .suggestion-item em {
  font-weight: bold;
  font-style: normal;
}
.ui-select-multiple.ui-select-bootstrap .ui-select-match-item span:first-child {
  display: inline-block;
  white-space: nowrap;
}
tags-input .host.has-feedback.input-with-feedback-wrapper,
.tags-input .host.has-feedback.input-with-feedback-wrapper {
  display: block;
}
tags-input .host.has-feedback.input-with-feedback-wrapper .tags,
.tags-input .host.has-feedback.input-with-feedback-wrapper .tags {
  padding-right: 24px;
}
fieldset[disabled] tags-input .tags {
  background-color: #f1f1f1;
}
tags-input.single-tag.tags-selected .tags .input {
  display: none;
}
tags-input.single-tag.tags-selected .tags .tag-list .tag-item {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0;
}
tags-input.multi-tag.no-input .tags .input {
  display: none;
}
tags-input.multi-tag.no-remove .tags li {
  padding-right: 12px;
}
tags-input.multi-tag.no-remove .tags li a {
  display: none;
}
/* end of TAGS-INPUT and UI-SELECT */

.has-feedback.input-with-feedback-wrapper {
  display: inline-block;
}
@media (max-width: 767px) {
  .has-feedback.input-with-feedback-wrapper {
    display: block;
  }
}
.form-horizontal .has-feedback.input-with-feedback-wrapper .form-control-feedback {
  right: 0;
}
.fa.form-control-feedback {
  line-height: 40px;
}

.form-control,
.normal-selectbox,
.large-selectbox {
  margin-bottom: 0;
}

.radio-label {
  float: left;
  margin-right: 10px;
}

/* checkbox and radio wrappers */
.checkbox,
.radio {
  margin: 0;
}
.checkbox + .checkbox,
.radio + .radio {
  margin: 0;
}
.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding: 0;
  margin: 0;
}
.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 0;
}
.radio label,
.checkbox label,
.radio-inline,
.checkbox-inline {
  padding: 0;
}
.checkbox label,
.radio label {
  min-height: 0;
  font-weight: bold;
}
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  margin: 0;
  position: static;
}

.normal-labels label,
.normal-labels .checkbox label,
.normal-labels .radio label,
.checkbox label.label-light,
.radio label.label-light,
.label-light,
label .light  {
  font-weight: normal;
}

label.label-link {
  color: variables.$link-color;

  &:hover,
  &:focus,
  &:focus-within {
    color: variables.$link-hover-color;
    text-decoration: underline;
  }
}

/* FORM VALIDATION HELPER CLASSES */
.form-validation-help {
  display: none;
}
.has-error .form-validation-help {
  display: block;
}

.paginationDirective .form-inline .input-group input {
  width: 60px;
}

.form-group .like-field {
  display: inline-block;
  padding: 10px 15px 0 15px;
  font-size: 14px;
}

.form-control.no-expand {
  display: inline-block;
  width: auto;
}

select.form-control.with-placeholder.empty {
  color: #a1a1a1;
}
select.form-control.with-placeholder.empty option {
  color: #555555;
}
select.form-control.with-placeholder option.placeholder {
  color: #a1a1a1;
}

@supports (-moz-appearance:none) and (display:contents) {
  input[type=file] {
    position: relative;
    height: 40px;
    padding: 0;
    border: 0;
  }
}

.form-control.ng-invalid-required {
  border-color: variables.$form-required-color;
}
.has-error .form-control.ng-invalid-required.ng-dirty {
  border-color: variables.$form-error-color;
}
.has-error .form-control.ng-invalid-required {
  border-color: variables.$form-error-color;
}

input[type=radio].ng-invalid-required,
input[type=checkbox].ng-invalid-required {
  outline: 2px solid variables.$form-required-color;
}
.has-error input[type=radio].ng-invalid-required.ng-dirty,
.has-error input[type=checkbox].ng-invalid-required.ng-dirty {
  outline: 2px solid variables.$form-error-color;
}
.has-error input[type=radio].ng-invalid-required,
.has-error input[type=checkbox].ng-invalid-required {
  outline: 2px solid variables.$form-error-color;
}

.form-input-error-message {
  background-color: variables.$form-error-color;
  color: #fff;
  padding: 0 15px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  display: block;
  white-space: nowrap;
  position: absolute;
  top: -30px;
  left: 0;
  z-index: 2;
}
.form-input-error-message:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid variables.$form-error-color;
  position: absolute;
}

.yes-no-answers {
  overflow: hidden;
  list-style-type: none;
  padding-left: 1em;
}
.yes-no-answers li.form-group {
  margin: 0;
  float: left;
  clear: both;
  padding: 5px 0;
}
.yes-no-answers .control-label {
  display: inline-block;
  width: 200px;
  font-weight: bold;
}
.yes-no-answers .answers {
  display: inline-block;
}
.yes-no-answers .answers label {
  margin: 0;
}
.yes-no-answers .answers label + label {
  margin-left: 20px;
}

.rounded-inputs .form-control,
.rounded-inputs tags-input .tags,
.rounded-inputs .tags-input .tags,
.rounded-inputs tags-input .tags .tag-item,
.rounded-inputs .tags-input .tags .tag-item,
.rounded-inputs .ui-select-container,
.rounded-inputs .ui-select-multiple.ui-select-bootstrap,
.rounded-inputs .calendar-picker-btn,
.rounded-inputs span[geo-location-input] .location-getter {
  border-radius: 50px;
}

label .optional-label {
  font-weight: 100;
  font-size: 90%;
  margin-left: 5px;
}

/* End of copied from forms.css */

// Set line height of some inputs to default line height for inputs
// (to overwrite some different styling of those inputs in Bootstrap).
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  line-height: 20px;
}

// remove some browser styling for inputs
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="number"].form-control,
input[type="email"].form-control,
input[type="tel"].form-control,
input[type="text"].form-control,
input[type="password"].form-control,
input[type="search"].form-control,
textarea.form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.form-control,
tags-input .tags,
.tags-input .tags,
.ui-select-container,
.ui-select-multiple.ui-select-bootstrap {
  @include forms-mixins.form-control;
}

tags-input .tags,
.tags-input .tags,
.ui-select-container,
.ui-select-multiple.ui-select-bootstrap {
  height: auto;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-control:focus,
.form-control.focus,
tags-input .tags.focused,
.tags-input .tags.focused,
.ui-select-container.open {
  @include forms-mixins.form-control-focus;
}

.input-value-changed .form-control,
.input-value-changed.form-control {
  border-color: variables.$form-edited-color;
}
.input-value-changed.has-feedback .form-control-feedback {
  color: variables.$form-edited-color;
}

.has-error.control-label {
  color: variables.$form-error-color;
}

.has-error .form-control,
.has-error.form-control {
  border-color: variables.$form-error-color;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error.has-feedback .form-control-feedback,
.has-error .has-feedback .form-control-feedback,
.has-feedback .has-error .form-control-feedback {
  color: variables.$form-error-color;
}
.has-error .form-control:focus,
.has-error.form-control:focus {
  @include forms-mixins.form-control-focus;
  border-color: variables.$form-error-color;
}

@media (min-width: 768px) {
  .form-inline .form-control,
  .form-inline tags-input .tags,
  .form-inline .tags-input .tags,
  .form-inline .ui-select-container,
  .form-inline .ui-select-multiple.ui-select-bootstrap,
  .form-inline ec-date-picker {
    @include forms-mixins.inline-form-control;
  }
}
.form-inline-xs .form-control,
.form-inline-xs tags-input .tags,
.form-inline-xs .tags-input .tags,
.form-inline-xs .ui-select-container,
.form-inline-xs .ui-select-multiple.ui-select-bootstrap,
.form-inline-xs ec-date-picker {
  @include forms-mixins.inline-form-control;
}

/* placeholders */
::placeholder {
	color: variables.$placeholder-color !important;
  opacity: 1 !important;
  text-overflow: ellipsis;
}

textarea.form-control {
  padding-top: 12px;

  &.resize-vertical {
    resize: vertical;
  }
}

.form-control.StripeElement {
  padding-top: 11px;
}

.form-control[disabled].disabled-awaiting {
  cursor: wait;
}

/* Small forms ------------------------------------------------------------- */

.input-sm {
  font-size: 12px;
}

.input-sm,
.form-group-sm .form-control,
.form-group-sm .form-control-static {
  height: 34px;
  padding: 7px 12px;
}

select.input-sm,
select.form-group-sm .form-control {
  height: 34px;
  line-height: 34px;
}

/* Large forms ------------------------------------------------------------- */

.input-lg {
  @include forms-mixins.input-lg;
}

.input-lger {
  @include forms-mixins.input-lger;
}

.form-lg {
  @include forms-mixins.form-lg;
}

.form-lger {
  @include forms-mixins.form-lger;
}

.form-lg-sm {
  @media (min-width: 768px) {
    @include forms-mixins.form-lg;
  }
}

.form-lg-md {
  @media (min-width: 992px) {
    @include forms-mixins.form-lg;
  }
}
.form-lg-lg {
  @media (min-width: 1200px) {
    @include forms-mixins.form-lg;
  }
}

.form-lger-sm {
  @media (min-width: 768px) {
    @include forms-mixins.form-lger;
  }
}

.form-lger-md {
  @media (min-width: 992px) {
    @include forms-mixins.form-lger;
  }
}

.form-lger-lg {
  @media (min-width: 1200px) {
    @include forms-mixins.form-lger;
  }
}
