@use "abstracts/variables";

.large-providers-intro {
  overflow: auto;
  position: fixed;
  top: variables.$header-height;
  bottom: var(--extra-bottom-space, 0);
  left: 0;
  right: 0;

  @media (max-width: 767px) {
    top: variables.$mobile-header-height;
  }
}
body:not(.is-referral-portal) .large-providers-intro {
  top: variables.$header-height + 61px;

  @media (max-width: 767px) {
    top: variables.$mobile-header-height + 61px;
  }
}
.large-providers-intro > .container {
  padding-top: 15px;
  padding-bottom: 30px;
}
.large-providers-intro .miles-input {
  width: 80px;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: baseline;
}
.large-providers-intro .miles-input[disabled] {
  background: transparent;
}
.large-providers-intro .zip-and-rest-container {
  max-width: 475px;
}
