@use "portals-common";

body.page-type-school-page {
  @include portals-common.portals-common;
  @include portals-common.portal-with-page-header;
  @include portals-common.disable-suggestions-feature;
}

body.page-type-school-page .page-header.small h1 {
  font-weight: normal;
}
@media (max-width: 767px) {
  body.page-type-school-page .page-header.small h1 .subtitle {
    display: none;
  }
}

// do not show location details in schedule items
body.page-type-school-page .schedule-cell.suggested .session-details .info-section.where,
body.page-type-school-page .schedule-cell.selected-box .session-details .info-section.where {
  display: none;
}
