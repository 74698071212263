@keyframes providerTopToBottom {
  0% {
    opacity:1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity:0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
