@use "x-button-mixins";


.x-button {
  @include x-button-mixins.x-button;
}

.x-button-inverse {
  @include x-button-mixins.x-button-inverse;
}
