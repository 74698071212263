@use "abstracts/variables";
@use "base/font-awesome";

// all pages except main front page
body:not(.page-type-front-main) .only-on-main-front-page {
  display: none;
}
// parents front page
body.page-type-front-main .hide-on-main-front-page {
  display: none;
}

.front-page-content.front-main {

  .main-section {
    margin-bottom: 70px;

    .block.text {
      margin: 20px auto;
      max-width: 84%;

      .text-wrapper {
        margin: 30px 0 40px;
        max-width: 700px;
      }

      .section-actions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;

        .btn {
          padding-left: 0;
          padding-right: 0;
        }

        .elop .full-text {
          display: none;
        }
      }
    }

    .block.image {
      position: relative;
      margin: 150px auto 100px;
      max-width: 600px;

      picture {
        height: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }

      .image-texts {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .image-texts-inner {
          position: relative;
          margin: 0 5%;
          height: 100%;
        }

        .image-text {
          position: absolute;
          text-shadow: 1px 1px 1px #fff;

          .link-wrapper {
            font-size: 18px;
            margin-bottom: 0.3em;

            a {
              color: inherit;
            }
          }

          .info {
            font-size: 16px;
            margin: 0;
            line-height: 1.2em;
          }

          &.districts-and-schools {
            top: -80px;
            left: 50%;
            width: 180px;
            margin-left: -90px;
          }

          &.parents {
            bottom: -70px;
            right: 0;
            width: 120px;
          }

          &.providers {
            left: 0;
            bottom: -30px;
            width: 170px;
          }
        }
      }
    }

    @media (min-width: 500px) {
      .block.text {
        margin: 30px auto;

        .section-actions {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: auto;
          grid-template-areas: "elop elop elop";

          .elop {
            grid-area: elop;

            .full-text {
              display: inline;
            }

            .short-text {
              display: none;
            }
          }
        }
      }
    }

    @media (min-width: 600px) {
      .block.text {
        margin: 40px auto;
      }

      .block.image {
        margin-top: 100px;

        .image-texts {
          .image-text {
            &.districts-and-schools {
              top: 50px;
              right: -20px;
              left: auto;
              margin: 0;
            }

            &.parents {
              bottom: -30px;
              right: 20px;
            }

            &.providers {
              bottom: 20px;
              left: 60px;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      .block.text {
        margin: 50px auto;
        max-width: 700px;
      }
    }

    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .block.text {
        margin: 100px 20px 0;
        grid-row-start: 1;
        grid-column-start: 2;
      }

      .block.image {
        grid-row-start: 1;
        grid-column-start: 1;
        margin: 20px 20px 0 0;

        .image-texts {

          .image-texts-inner {
            margin-right: 0;
          }

          .image-text {

            &.districts-and-schools {
              top: 20px;
              right: 0;
            }

            &.parents {
              right: 20px;
              bottom: -25px;
            }

            &.providers {
              left: 20px;
              bottom: 10px;
            }
          }
        }
      }
    }

    @media (min-width: 1200px) {
      .block.image {
        .image-texts {
          .image-text {

            .link-wrapper {
              font-size: 20px;
            }

            .info {
              font-size: 18px;
            }

            &.districts-and-schools {
              width: 200px;
              top: 20px;
              right: 15px;
            }

            &.parents {
              width: 140px;
              right: 40px;
              bottom: -35px;
            }

            &.providers {
              width: 200px;
              left: 20px;
              bottom: 0;
            }
          }
        }
      }
    }

    @media (min-width: 1400px) {
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .why-section-wrapper {
    padding: 150px 0 100px;
    margin-bottom: 50px;

    > .title {
      padding-bottom: 100px;
      text-align: center;
    }

    .why-section-container {
      display: flex;
      flex-wrap: wrap;
      padding: 0 8%;
      gap: 20px;
      margin: 0 auto;
      max-width: 1000px;

      .why {
        border: 1px solid variables.$form-control-border-color;
        border-radius: variables.$form-control-border-radius;
        background: #fff;
        position: relative;
        padding: 66px 25px 100px;
        margin: 0 auto 100px;
        max-width: 310px;

        .title {
          line-height: 1.5em;
          font-size: 22px;
        }
        .icon {
          width: 80px;
          height: 80px;
          position: absolute;
          top: -40px;
          left: 20px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .text-wrapper {
          margin: 0;

          .more {
            position: absolute;
            bottom: 0;
            left: 25px;
            right: 25px;
            display: block;
            padding-bottom: 25px;

            a {
              &:before {
                content: "";
                display: block;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                position: absolute;
                bottom: -18px;
                left: 50%;
                margin-left: -22px;
              }

              &:after {
                @include font-awesome.font-awesome('angle-double-down');
                & {
                  display: block;
                  border-radius: 100%;
                  border: 3px solid #58595b;
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  bottom: -22px;
                  left: 50%;
                  margin-left: -18px;
                  text-align: center;
                  font-size: 30px;
                  color: #58595b;
                  line-height: 35px;
                }
              }
            }
          }
        }
      }
    }

    .ec-theme-blue .text-wrapper .more a:before {
      background-color: variables.$theme-color-blue;
    }
    .ec-theme-yellow .text-wrapper .more a:before {
      background-color: variables.$theme-color-yellow;
    }
    .ec-theme-green .text-wrapper .more a:before {
      background-color: variables.$theme-color-green;
    }
  }


  @media (min-width: 1200px) {
    .why-section-wrapper {
      .why-section-container {
        padding: 0;
      }
    }
  }

  #for-parents {
    .section-actions {
      margin: -30px 0 80px;
    }

    @media (min-width: 412px) {
      .section-actions .btn .line-break {
        display: none;
      }
    }

    @media (min-width: 768px) {
      .value-propositions {
        margin-left: 0;
        margin-right: 0;
        padding-top: 30px;
        display: block;

        .value-proposition-wrapper {
          padding-bottom: 40px;
        }

        .value-proposition {
          border: none;
          margin: 0;
          padding: 0;

          .value-proposition-icon {
            width: 40px;
            height: 40px;
            top: -10px;
            left: 0;
          }

          .value-proposition-title {
            padding-left: 50px;
          }
        }
      }
    }
  }

  #for-schools, #for-districts {
    @media (min-width: 360px) {
      .section-actions .btn .line-break {
        display: none;
      }
    }
  }

}
