@use "abstracts/mixins";

.reviews-modal {

  .accordion-toggle {
    .review-header {
      @include mixins.clearfix;
      & {
        margin-top: -10px;
        margin-bottom: -5px;
      }

      .review-title {
        float: left;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .star-rating {
        float: right;

        &.star-rating-big {
          margin-bottom: 0px;
          margin-top: 5px;
        }
      }
    }
  }
}
