.btn-default {
  &,
  &.focus,
  &:focus {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  &:hover,
  &.hover,
  &:active,
  &.active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.btn.btn-border.btn-default {
  &,
  &.focus,
  &:focus {
    color:#aeaeae;
    border-color:#aeaeae;
  }

  &:hover,
  &.hover,
  &:active,
  &.active {
    color:#505050;
    border-color:#adadad;
    background-color: #e6e6e6;
  }
}
