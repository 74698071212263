@use "abstracts/mixins";

.results-header {
  margin: 15px 0;
  line-height: 22px;
  display: block;
  min-height: 22px;

  @media (max-width: 430px) {
    min-height: 43px;
  }

  .selected-location {
    background: #fff;
    border-radius: 3px;
    border: 1px solid lightgray;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 5px 25px 5px 10px;
    font-size: 14px;
    margin: 5px -10px 0;
    position: relative;

    .close {
      position: absolute;
      top: 5px;
      right: 8px;
      font-size: 21px;
      color: #a5a5a5;
      opacity: 1;
    }
  }

  .results-message {
    min-height: 22px;

    .results-text-skeleton {
      max-width: 500px;
      height: 16px;
      position: relative;
      top: 3px;
      margin-bottom: 12px;
      border-radius: 7px;
      @include mixins.skeleton-animated-item;

      &.results-text-skeleton-second-line {
        display: none;
        width: 150px;

        @media (max-width: 430px) {
          display: block;
        }
      }
    }

    .main-results {
      display: block;
    }
  }

  .results-actions {
    padding-top: 5px;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.stacked {
      display: block;

      .results-action.make-schedule {
        margin-bottom: 10px;
      }
    }

    .results-action .ec-spinner {
      font-size: 20px;
    }
  }
}
