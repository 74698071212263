.pagination-page-links {
  text-align: center;
  margin-top: 10px;
}
.pagination-page-links a.current-page {
  color: #505050;
}
.pagination-page-links a.current-page:hover {
  cursor: default;
}

.pagination-direct-links {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  li {
    margin-right: 5px;
    margin-left: 5px;

    .next, .previous {
      width: 150px;
    }
  }

  a.current-page {
    font-weight: 700;
    pointer-events: none;
    border: none;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    li {
      margin: 0;
      &.page-number {
        display: none;
      }
      .next, .previous {
        width: 100%;
      }
    }
  }
}
