.share-individual-classes {

  .filters-bar {

    .filter-menu {
      padding: 40px 20px 20px 20px;
    }
    .filter-btn-menu .btn .show-on-small {
      display: none;
    }

    @media (max-width: 510px) {

      .filter-btn-menu {
        margin-right: 4px;

        .btn {
          font-size: 12px;
          padding-left: 8px;
          padding-right: 8px;
        }

        &.all-selected .btn {
          .hide-on-small {
            display: none;
          }
          .show-on-small {
            display: inline;
          }
        }
      }
    }
  }

  .program-cards {
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }

  .program-card-wrapper {
    min-width: 33.333333%;
    max-width: 33.333333%;
    padding-left: 15px;
    margin-top: 15px;
    flex: 1;

    @media (max-width: 700px) {
      min-width: 50%;
      max-width: 50%;
    }

    @media (max-width: 450px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .program-card {
    background: #fff;
    border: 1px solid #f5f5f5;
    border-bottom: 3px solid #e7e7e7;
    border-radius: 4px;
    cursor: pointer;
    height: 100%;
    transition: border-color 0.5s ease, box-shadow 0.5s ease;

    &:hover {
      border-color: #e7e7e7 #e7e7e7 #bcbcbc #e7e7e7;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .program-media {
      width: 100%;
      padding-top: 66.666667%;
      position: relative;

      ec-single-media-of-program-or-provider {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .program-info {
      padding: 20px;
      position: relative;
      height: 165px;

      .program-name {
        margin-bottom: 15px;
        height: 45px;
        line-height: 1.25em;
        overflow: hidden;
      }

      .children-details {
        line-height: 1.2em;
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }

  .sharing-details {
    .program-details {
      margin-top: 15px;
      margin-bottom: 30px;
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 30px;

      .program-media {
        grid-row: 1;
        grid-column: 2;

        ec-single-media-of-program-or-provider {
          background: none;

          > img,
          > iframe {
            width: 200px;
            height: 133px;
            float: right;
          }
        }
      }

      .program-info {
        grid-row: 1;
        grid-column: 1;

        .program-name {
          margin-bottom: 15px;
        }
      }

      @media (max-width: 700px) {
        display: block;

        .program-media {
          ec-single-media-of-program-or-provider {
            > img,
            > iframe {
              float: none;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
