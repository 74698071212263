@use "abstracts/variables";
@use "buttons/types/x-button-mixins";

.filters-bar {

  .filter-btn-menu {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;

    &.active {
      .filter-btn {
        background-color: #F7F7F7 !important;
        border: 1px solid #000;
        position: relative;
        &::after {
          content: "" !important;
          width: calc(100% + 2px) !important;
          height: calc(100% + 2px) !important;
          background-color: transparent !important;
          position: absolute !important;
          top: -1px !important;
          left: -1px !important;
          border-color: inherit !important;
          border-style: solid !important;
          border-width: 2px !important;
          border-radius: inherit !important;
        }
      }
    }

    //more is the last button on the right of the filters bar
    &.more {
      margin-right: 0;
    }

    .filter-btn {
      border-radius: 30px !important;
    }
  }

  .form-group.search {
    margin-bottom: 10px;

    .form-control {
      border-radius: 30px;
    }
  }

  .filter-menu {
    position: absolute;
    z-index: 10;
    margin-top: 8px;
    min-width: 280px;
    max-width: 600px;
    background: #fff;
    border: variables.$raised-widget-border;
    box-shadow: variables.$raised-widget-box-shadow;
    border-radius: 12px;

    .menu-body {
      padding: 40px 20px 20px 20px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 250px);
    }

    .form-group > label {
      width: 150px;
    }

    label {
      font-weight: 100;
    }

    .close {
      @include x-button-mixins.x-button;
      & {
        background-position: left bottom;
        position: absolute;
        right: 0;
        top: 2px;
        margin-top: 0;
        text-shadow: none;
        background-size: 15px 15px;
        width: 30px;
        height: 30px;
      }

      &:hover {
        cursor: pointer;
      }

      * {
        display: none;
      }
    }

    hr {
      border: 1px solid variables.$section-border-color;
    }
  }
}

.menu-footer {
  padding: 12px 14px;
  border-top: 1px solid variables.$section-border-color;

  .btn-wrapper {
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.single-action {
      justify-content: flex-end;
    }
  }

  .btn {
    min-width: 100px;
  }
}

.more-filters-modal {

  h3.modal-title {
    display: inline;
    padding-right: 10px!important;
  }

  section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid variables.$section-border-color;

    &.no-border {
      border-bottom: none;
    }
    .section-header {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  label {
    font-weight: 100;
  }

  tags-input .tags {
    max-width: 310px;
  }

  .info-tooltip {
    color: #c5c5c5;
    margin-left: 10px;
  }
}
