@font-face {
  font-family: '6cricketsIcons';
  src:
    url('../fonts/6cricketsIcons.ttf?ip3m20') format('truetype'),
    url('../fonts/6cricketsIcons.woff?ip3m20') format('woff'),
    url('../fonts/6cricketsIcons.svg?ip3m20#6cricketsIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ec-icon-"], [class*=" ec-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '6cricketsIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ec-icon-checkmark:before {
  content: "\e904";
}
.ec-icon-logo-6:before {
  content: "\e901";
}
.ec-icon-camps:before {
  content: "\e902";
}
.ec-icon-classes:before {
  content: "\e903";
}
.ec-icon-friends:before {
  content: "\e900";
}
