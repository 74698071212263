@use 'abstracts/variables';

.page-header {
	padding:30px 0 10px;
	margin: 0 0 40px;
	border-bottom: none;
	background-color: #fff;
	border-bottom:1px solid variables.$section-border-color;
}

.page-header.homepage-page-header {
	position: relative;
	padding:0;
}

.page-header.no-margin {
	margin:0 !important;
}

.page-header.no-border {
	border:none;
}

.page-header.larger {
	padding:32px 0;
}

.page-header h1 {
	margin-bottom:14px;
}

.page-header-desc {
	margin-bottom:10px;
	color:#7a7a7a;
	font-size:15px;
}

@media (min-width:768px) {
	.page-header {
		padding:35px 0 20px;
		margin-bottom:60px;
	}

	.page-header-desc {
		margin-bottom:20px;
		font-size:16px;
	}
}

@media (min-width:992px) {

	.larger-desc .page-header-desc {
		margin-bottom:0;
	}

	.page-header .col-md-6 h1 {
		margin-bottom:0;
	}

	.page-header.larger-desc .col-md-6 h1 {
		margin-bottom:10px;
	}

	.page-header .col-md-push-6{
		text-align: right;
	}
}

/* Page Header Dark (Same color scheme with footer-inverse class and navbar-inverse class)*/
.page-header.dark {
	background-color: #2a2a2a;
	border-bottom-color:#1e1e1e;
}

.page-header.dark h1,
.page-header.dark h2,
.page-header.dark h3 {
	color:#bebebe;
}

.page-header.dark .page-header-desc {
	color:#8a8a8a;
}

/* Page Header Gray (Same color scheme with footer-default class and navbar-default class)*/

.page-header.gray {
	background-color: #eaeaea;
	border-bottom-color:#d7d7d7;
}

.page-header.gray h1,
.page-header.gray h2,
.page-header.gray h3 {
	color:#8a8a8a;
}

.page-header.gray .page-header-desc {
	color:#6a6a6a;
}

/* Page Header Gray (Same color scheme with footer-custom class and navbar-custom class)
	- Changes duo to color scheme
*/
.page-header.custom h1,
.page-header.custom h2,
.page-header.custom h3 {
	color:#eaeaea;
}

.page-header.custom .page-header-desc {
	color:#eaeaea;
}
