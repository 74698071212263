@use "../base/tags-input-mixins";

body {
  // body is needed to have higher priority over default angular material styles

  // common ================================================================ //

  .mat-mdc-chip.mat-mdc-standard-chip {
    @include tags-input-mixins.tag-item;
    & {
      padding-top: 3px;
    }

    .mdc-evolution-chip__action--primary::before {
      display: none;
    }

    .mat-mdc-chip-remove {
      @include tags-input-mixins.tag-item-remove-button;
    }
  }

  // mat-chip-grid ========================================================= //

  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-mdc-form-field-type-mat-chip-grid {
    .mat-mdc-form-field-infix {
      padding: 6px 0 3px;
    }
  }

  .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin: 0;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
    padding: 0;
    display: inline;
  }

  .mat-mdc-chip-input.mat-mdc-input-element {
    min-height: 26px;
    margin: 1px 0px 4px 0;
  }

  // mat-chip-set ========================================================== //

  .mat-mdc-chip-set.form-control {
    padding-top: 5px;
    padding-bottom: 2px;

    .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
      opacity: 1;
      padding-right: 12px;
      padding-top: 0;

      .mdc-evolution-chip__action--primary {
        padding: 0;
      }
    }
  }
}
