/* custom bg colors (check IMPORTANT note below if you want to change them) */
.custom-light-bg-2 {
  background-color: #f5f5f5;
}

/* TODO: color definitions below should be moved to where other those classes are fully defined */

blockquote {
  border-left-color: #777;
}

blockquote.blockquote-reverse  {
  border-right-color: #777;
}
