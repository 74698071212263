@use "abstracts/variables";

.label {
	display: inline-block;
	padding: 0 10px;
	font:600 10px/1.6 "Lato", Arial, Helvetica, sans-serif;
	color: #fff;
	text-align: center;
	border-radius: 0;
	text-transform: uppercase;
	vertical-align: middle;
}
.label.label-rounded {
  border-radius: 100px;
  line-height: 1.4em;
  height: 1.4em;
  padding: 0 1.2em;
}
.label.label-rounded.with-icon {
  padding: 0 0 0 0.6em;
}
h2 .label-rounded {
  font-size: 20px;
  position: relative;
  top: -3px;
}
.label.label-ok {
  background-color: #00B4E5;
}
.label.label-not-ok {
  background-color: variables.$hero-color;
}

.show-more-label {
  background: #e8e8e8;
  border-radius: 10px;
  padding: 3px 10px;
  display: inline-block;
  margin-left: 5px;

  &.expanded {
    font-size: 0.9em;
    color: variables.$link-color;

    &:hover {
      color: variables.$link-hover-color;
    }
  }
}
