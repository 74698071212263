.page-header {
  margin: 0;
}
.page-header.custom {
  background-color: #F5F5F5;
  border-bottom-color: #fff;
}
.page-header.larger {
  padding: 20px 0;
}
.page-header.custom h1 {
  margin: 0;
  color: #777;
  font-weight: 400;
}
.page-header h1.with-icon {
  padding-left: 90px;
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
}
.page-header h1.with-icon > .fa.page-title-icon,
.page-header h1.with-icon > img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 40px;
  line-height: 80px;
  position: absolute;
  left: 0;
  top: 0;
}
.page-header h1.with-icon > img.profile-image {
  border-radius: 0;
  width: auto;
  height: auto;
  max-width: 80px;
  max-height: 80px;
}
.page-header.custom h1.with-icon > .fa.page-title-icon {
  background-color: #FFF;
  color: #777;
}
.page-header.custom .page-header-desc {
  color: #888;
}
.page-header.small {
  padding: 10px 0;
  height: 61px;
}
.page-header.small h1 {
  font-size: 20px;
  font-weight: bold;
}
.page-header.small h1.with-icon {
  padding-left: 50px;
  height: 40px;
}
.page-header.small h1.with-icon > .fa.page-title-icon,
.page-header.small h1.with-icon > img {
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
}
.page-header.small h1.with-icon > img.profile-image {
  width: auto;
  height: auto;
  max-width: 40px;
  max-height: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.page-header.small h1.with-details-link {
  position: relative;
  padding-right: 140px;
  line-height: 20px;
}
.page-header.small h1 .details-link {
  font-size: 0.75em;
  position: absolute;
  right: 0;
  top: 12px;
  font-weight: normal;
}
@media (max-width: 767px) {
  .page-header.small h1.with-details-link {
    padding-right: 20px;
  }
  .page-header.small h1 .details-link {
    top: 11px;
    font-size: 18px;
  }
  .page-header.small h1 .details-link .text {
    display: none;
  }
}
.page-header .container,
.page-header .portal-container {
  display: flex;
  align-items: center;
  height: 100%;
}
