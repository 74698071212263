@use "abstracts/mixins";
@use "abstracts/variables";
@use "base/font-awesome";

.about-page-content {
  position: relative;

  svg.svg-about-page-decoration {
    fill: #F4F362;
    position: absolute;
    top: 100px;
    left: -610px;
    width: 750px;
  }

  .about-page-section {
    font-size: 18px;
    padding-top: 80px;

    .container {
      max-width: 700px;
      padding-right: 40px;
      position: relative;

      > svg {
        position: absolute;
        top: -60px;
        right: -20px;
        fill: #FDD4E2;
        width: 150px;

        &.svg-rays {
          width: 200px;
          right: 0;
        }

        &.svg-dots-many {
          width: 100px;
          top: -25px;
        }
      }
    }

    figcaption {
      font-size: 14px;
      margin: 10px 0 20px;
    }

    .social-media-links {
      margin: 50px 0 0;
      @include mixins.clearfix;

      strong {
        float: left;
        margin-right: 30px;
      }
    }
  }

  .about-section-title {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
    max-width: 590px;
    position: relative;

    &.about-section-title-big {
      font-size: 48px;
      line-height: 56px;
      font-weight: normal;
      margin-bottom: 50px;
    }

    .intro {
      font-size: 18px;
      line-height: 29px;
      display: block;
      font-weight: bold;
      color: variables.$text-color;
    }
  }

  .about-page-section-join {
    .btn {
      margin-top: 30px;
    }
  }

  .about-page-section-selected-articles {
    margin-bottom: 200px;

    .selected-article {

      .selected-article-title {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      .selected-article-author {
        color: #8A8A8A;
        margin-bottom: 0;
      }

      .selected-article-content {
        margin-top: 20px;
        margin-bottom: 50px;
        line-height: 29px;
      }
    }

    .see-all-articles {
      font-weight: bold;

      &:after {
        @include font-awesome.font-awesome('chevron-right');
        & {
          margin-left: 10px;
          font-size: 12px;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  @media (max-width: 991px) {

    svg.svg-about-page-decoration {
      left: -630px;
    }

    .about-page-section {
      padding-top: 70px;

      .container {
        max-width: 600px;
        padding-right: 40px;

        > svg {
          top: -30px;
          width: 120px;

          &.svg-rays {
            width: 150px;
            right: 85px;
          }

          &.svg-dots-many {
            width: 100px;
            top: -25px;
          }
        }
      }
    }

    .about-section-title {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 30px;
      max-width: 420px;

      &.about-section-title-big {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 30px;
      }

      .intro {
        font-weight: normal;
      }
    }

    .about-page-section-selected-articles {
      margin-top: 50px;

      .selected-article {

        .selected-article-title {
          font-size: 18px;
          line-height: 29px;
        }
      }
    }
  }

  @media (max-width: 767px) {

    svg.svg-about-page-decoration {
      left: -660px;
      top: -80px;
    }

    .about-page-section {
      padding-top: 50px;
      font-size: 16px;
      overflow: hidden;

      .container {
        padding-right: 25px;
        padding-left: 25px;

        > svg {
          top: -40px;
          width: 100px;
          right: -30px;

          &.svg-rays {
            width: 120px;
            right: auto;
            left: 340px;
            top: -20px;
          }

          &.svg-dots-many {
            width: 100px;
            top: -45px;
          }
        }
      }
    }

    svg.svg-about-page-decoration + .about-page-section {
      padding-top: 30px;
    }

    .about-section-title {
      margin-bottom: 20px;
      max-width: none;

      &.about-section-title-big {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 20px;
        width: 380px;
      }

      .intro {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .about-page-section-join {
      .btn {
        margin-top: 10px;
      }
    }

    .about-page-section-selected-articles {
      @include mixins.top-waved-background(#FAFAFA);
      margin-top: 70px;
      padding-top: 80px;
      margin-bottom: 0;
      padding-bottom: 100px;
    }
  }

  @media (max-width: 500px) {
    .about-page-section .container > svg.svg-rays {
      left: 170px;
    }
    .about-section-title.about-section-title-big {
      width: 220px;
    }
  }
}
