@use "mixins";

.btn-danger {
  @include mixins.button-color(
    #d9534f, #d43f3a, #c9302c, #ac2925
  );
  @include mixins.button-color-border(
    #ea1c0d, #d43f3a, #ea1c0d, #c8180b
  );
}
