@use "abstracts/mixins";
@use "abstracts/variables";

/* details modal for program and session */
@mixin stacked-details-and-media   {
  display: block;

  .actions {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .short-details {
    margin-right: 0;
  }
}

.details-modal {

  .details-modal-section {
    border-top: 1px solid variables.$section-border-color;
    margin-top: 40px;
    padding-top: 40px;

    &:first-child {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    &.location-info {
      padding-top: 0;
      border-top: none;
    }
  }

  .location-info + .details-modal-section {
    border-top: none;
    margin-top: 0;
  }

}

.provider-info,
.program-info {
  .short-details-and-media {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;

    .actions {
      margin-top: 40px;
    }

    .short-details {
      position: relative;
      margin-right: 20px;
    }

    &.stacked {
      @include stacked-details-and-media;
    }
  }

  .expandable {
    &.minimal {
      @include mixins.limit-viewable-height(150px);

      .fade-to-white {
        @include mixins.fading-gradient;
        @include mixins.fading-to-white;
      }
    }
  }
}

@media (max-width: 730px) {
  .provider-info,
  .program-info {
    .short-details-and-media {
      @include stacked-details-and-media;
    }
  }
}

.modal-media {
  position: relative;
  height: 280px;
  width: 420px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .carousel {
    position: relative;
    height: 100%;
    width: 100%;
    margin-right: 20px;

    .carousel-inner {
      height: 100%;
      width: 100%;

      div {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 991px) {
    height: 213px;
    width: 320px;
  }
}

.details-modal .provider-info-title .rating {
  float: right;
}

.details-modal.program-modal .session-list .session .wrapper > .session-page {
  display: none;
}
@media (max-width: 400px) {
  .details-modal.program-modal .session-list .session .wrapper {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .details-modal.program-modal .session-list .session {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .details-modal.program-modal .session-list {
    margin-left: -10px;
    margin-right: -10px;
  }
  .details-modal.program-modal .session-list .session {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
