@use "abstracts/mixins";
@use "abstracts/variables";
@use "layout/header";

// should be used on body selector
@mixin portals-common {
  overflow: hidden;

  #footer-wrapper {
    display: none;
  }

  .cart-container .cart-summary .cart-actions .go-to-schedule-button {
    display: none;
  }
  @media (max-width: 450px) {
    .cart-container .cart-summary .cart-actions .go-to-checkout-button {
      margin-top: 30px;
    }
  }

  // styles for printing
  @media print {
    // hide everything except schedule table
    #wrapper {
      padding-top: 0;
      display: block;
    }
    #header-wrapper {
      display: none;
    }
    #content-wrapper {
      display: block;
    }
    &,
    #content {
      overflow: visible !important;
    }
    .page-header {
      display: none;
    }
  }
}

// should be used on body selector
@mixin portal-with-page-header {

  .page-header {
    border-bottom-color: variables.$section-border-color;
  }

  &.is-referral-portal .page-header {
    display: none;
  }

  &:not(.is-referral-portal) {
    .frames {
      top: variables.$header-height + 61px;

      @media (max-width: 767px) {
        top: variables.$mobile-header-height + 61px;
      }
    }
  }

  /* results tab ----------------------------------------------------------- */
  &:not(.is-referral-portal) {
    .filters-panel {
      top: variables.$header-height + 61px;
    }
  }
  @media (max-width: 767px) {
    &:not(.is-referral-portal) {
      .filters-panel {
        top: variables.$mobile-header-height + 61px;
      }
    }
  }
}

// should be used on body selector
@mixin disable-suggestions-feature {
  &.is-referral-portal .suggestions-feature {
    display: none !important;
  }
}

/* portal container is applied to header, filters, and tab-pane-container only
  in portals*/
.portal-container {
  @include mixins.left-right-padding(25px);
  .week-header-fixed {
    padding-left: 25px;
  }

  @media (min-width: 1330px) {
    @include mixins.left-right-padding(80px);
    .week-header-fixed {
      padding-left: 80px;
    }

    &.right-frame-visible {
      @include mixins.left-right-padding(25px);

      .week-header-fixed {
        padding-left: 25px;
      }
    }
  }

  @media (max-width: header.$header-mobile-breakpoint) {
    @include mixins.left-right-padding(15px);

    .week-header-fixed {
      padding-left: 15px;
    }
  }
}

.portal-announcement-button-container {
  display: flex;
  justify-content: flex-end;

  @media (min-width: 600px) {
    ~.results-header {
      margin-top: 0;
    }
  }

  button {
    z-index: 2;
    border-radius: 0px 0px 7px 7px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
