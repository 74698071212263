@use "layout/containers";

.front-page-content {

  .front-section {
    margin-top: 100px;

    &.first-front-section {
      margin-top: 0;
    }

    &.last-front-section {
      margin-bottom: 150px;
    }

    .container {
      @include containers.narrow-container;
    }

    .front-section-title {
      text-align: center;
      margin-bottom: 50px;
    }

    @media (max-width: 991px) {
      .front-section-title {
        margin-bottom: 40px;
      }
    }

    @media (max-width: 767px) {
      margin-top: 50px;

      .front-section-title {
        margin-bottom: 20px;
      }
    }

  }

  .front-section.front-section-narrow .container {
    max-width: 650px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 767px) {
      max-width: 500px;
    }

    @media (max-width: 550px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &:not(.front-parents) .hero-blocks-section:not(.front-section-narrow) {
    @media (min-width: 768px) and (max-width: 991px) {
      .container {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  .value-propositions-section {

    .front-section-title {
      margin-bottom: 0;
    }

    .value-propositions {
      margin-top: 120px;
      margin-bottom: -70px;
    }

    @media (max-width: 767px) {

      .front-section-title {
        margin-bottom: 20px;
      }

      .value-propositions {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }

  }

  .testimonials-section {

    .container {
      margin-bottom: 200px;
    }

    .testimonials-section-icon {
      margin-bottom: 40px;

      svg {
        width: 70px;
        height: 70px;

        @media (max-width: 991px) {
          width: 60px;
          height: 60px;
        }
      }
    }

    @media (min-width: 1200px) {
      .container {
        width: 970px;
      }
    }

    @media (max-width: 991px) {

      .container {
        margin-bottom: 120px;
      }

      .testimonials-section-icon {
        svg {
          width: 60px;
          height: 60px;
        }
      }

      .light-carousel.testimonials-carousel .carousel .carousel-control {
        &.left {
          left: -60px;
        }
        &.right {
          right: -60px;
        }
      }

    }

    @media (max-width: 767px) {

      .container {
        margin-bottom: 0;
      }

      .testimonials-section-icon {
        display: none;
      }

      .light-carousel.testimonials-carousel .carousel {

        .carousel-control {
          display: none;
        }

        .carousel-inner {
          background: #fff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
          max-width: 530px;
          margin-left: auto;
          margin-right: auto;
        }

        .testim {
          padding: 25px;
        }

      }

    }
  }

  .in-the-news-section {

    .news-logos {
      text-align: center;
      margin: 80px -20px;

      .news-logo {
        margin: 10px 20px;
        display: inline-block;

        &:hover {
          text-decoration: none;
        }

        img {
          height: auto;
          width: auto;
          max-height: 100px;
          max-width: 150px;
        }

        &.komo-news-logo img {
          width: 80px;
        }
      }

      @media (max-width: 991px) {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
      }
    }
  }

  .awarded-section {

    .awarded-logo {
      text-align: center;

      img {
        height: 120px;
        width: auto;
      }
    }

    @media (max-width: 991px) {
      .awarded-logo img {
        height: 100px;
      }
    }
  }

  .additional-benefits-section {
    font-size: 18px;

    .colored-circles-list {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;

      > li {
        margin-bottom: 30px;

        h3 {
          font-size: 22px;
          margin-bottom: 5px;
          line-height: 1.6em;
        }
      }
    }

    .read-more-wrapper {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 25px;
      font-weight: bold;
    }

    @media (max-width: 767px) {
      font-size: 16px;

      .colored-circles-list {
        > li {
          h3 {
            font-size: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .try-it-yourself-section {

    @media (min-width: 768px) {
      .btn {
        width: 160px;
      }
    }

    @media (min-width: 992px) {
      .btn {
        width: 220px;
      }
    }
  }

  .commission-breakdown-section {
    font-size: 18px;

    .front-section-title {
      margin-bottom: 20px;
    }

    .front-section-title + p {
      margin-bottom: 50px;
    }

    .simple-chart-container {
      margin: 0 auto 20px;
      max-width: 870px;
      padding-right: 20px;
      background: #fff;
    }

    .commission-breakdown-details {
      margin-left: auto;
      margin-right: auto;
      max-width: 550px;
      margin-top: 50px;
    }

    @media (max-width: 1199px) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width: 991px) {
      .simple-chart-container {
        max-width: 720px;
        padding-right: 0;
        padding-left: 20px;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px;

      .container {
        padding-left: 25px;
        padding-right: 25px;
      }

      .simple-chart-container {
        padding-top: 40px;
        padding-bottom: 20px;

        .simple-chart-title {
          font-size: 14px;
          margin-bottom: 40px;
          font-weight: bold;
        }

        .simple-chart {
          font-size: 11px;
          white-space: normal;

          .chart-row .chart-line-fill {
            height: 80px;

            &:before {
              height: 25px;
            }
          }
        }
      }

      .commission-breakdown-details {
        margin-top: 24px;
      }
    }
  }

  // h3 isn't even allowed in ul, but there it is.
  ul h3 {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 110%;
  }

  .with-wave-bg {
    padding-top: 100px;
    padding-bottom: 100px;
    margin: 0;
  }

  .multi-block-wrapper {
    display: grid;
    grid-gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    max-width: 1300px;

    .block {
      &.text {
        margin-top: 100px;
        margin-bottom: 50px;
        max-width: 700px;

        &.left {
          margin-left: 50px;
        }
        &.right {
          margin-right: 50px;
          position: relative;
        }
        .who {
          text-transform: uppercase;
          color: #8A8A8A;
          margin-bottom: 20px;
          font-size: 0.83em;
        }
      }

      &.image {
        display: flex;
        flex-direction: column;

        picture {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        img {
          max-width: 100%;
          width: auto;
          height: auto;
          margin: auto;
        }
      }

      .text-wrapper {
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }
  }

  .section-actions {
    max-width: 700px;
    position: relative;
    margin-top: 20px;

    .link-wrapper {
      margin-bottom: 30px;
    }
  }

  .action-and-dashboards-buttons {
    display: inline-grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 15px;

    .btn {
      width: 100%;
      min-width: 260px;

      .media-play-icon {
        font-size: 24px;
      }
    }

    .dashboards-text {
      margin-top: 15px;
    }

    @media (min-width: 550px) {
      grid-template-columns: 1fr 1fr;

      .btn {
        min-width: 200px;
      }

      .dashboards-text {
        grid-column: 1 / 3;
      }
    }

    @media (min-width: 1200px) {
      .btn .media-play-icon {
        font-size: 30px;
      }
    }
  }

  @media (min-width: 768px) {
    .multi-block-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media not all and (min-width: 992px) {
    .multi-block-wrapper .block {
      &.text.left {
        margin-left: 40px;
      }
      &.text.right {
        margin-right: 40px;
      }
    }
  }

  @media not all and (min-width: 768px) {
    .multi-block-wrapper {
      grid-template-rows: auto auto;
      row-gap: 15px;

      .block {
        &.text {
          grid-row-start: 2;

          &.right,
          &.left {
            margin: 0 10% 20px 10%;
            max-width: 700px;
          }
        }
        &.image {
          grid-row-start: 1;

          /* img.main-multi-color may refer to the round image or
           * the horizontal image, depending on the window width.
           * We want to bound the size of the round image, not the
           * horizontal image (which should span the full window width).
           * Keep this breakpoint in sync with the <source> media
           * attribute of the round image.
           */
          @media (min-width: 500px) {
            img.main-multi-color {
              max-width: 500px;
            }
          }
        }
      }
    }
  }

}
