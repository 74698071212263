@use "sizes-mixins";

// Small button
.btn.btn-sm,
.btn-group-sm > .btn {
  @include sizes-mixins.btn-sm;
}
.btn.btn-sm.btn-border,
.btn-group-sm > .btn.btn-border {
  @include sizes-mixins.btn-sm-border;
}
.btn.btn-sm.btn-border-light,
.btn-group-sm > .btn.btn-border-light {
  @include sizes-mixins.btn-sm-border-light;
}
// Small padding button
.btn.btn-sm-padding,
.btn-group-sm-padding > .btn {
  @include sizes-mixins.btn-sm-padding;
}
.btn.btn-sm-padding.btn-border,
.btn-group-sm-padding > .btn.btn-border {
  @include sizes-mixins.btn-sm-padding-border;
}
.btn.btn-sm-padding.btn-border-light,
.btn-group-sm-padding > .btn.btn-border-light {
  @include sizes-mixins.btn-sm-padding-border-light;
}


// Extra small button
.btn.btn-xs,
.btn-group-xs > .btn {
  @include sizes-mixins.btn-xs;
}
.btn.btn-xs.btn-border,
.btn-group-xs > .btn.btn-border {
  @include sizes-mixins.btn-xs-border;
}
.btn.btn-xs.btn-border-light,
.btn-group-xs > .btn.btn-border-light {
  @include sizes-mixins.btn-xs-border-light;
}
// Extra small padding button
.btn.btn-xs-padding,
.btn-group-xs-padding > .btn {
  @include sizes-mixins.btn-xs-padding;
}
.btn.btn-xs-padding.btn-border,
.btn-group-xs-padding > .btn.btn-border {
  @include sizes-mixins.btn-xs-padding-border;
}
.btn.btn-xs-padding.btn-border-light,
.btn-group-xs-padding > .btn.btn-border-light {
  @include sizes-mixins.btn-xs-padding-border-light;
}


// Large button
.btn.btn-lg,
.btn-group-lg > .btn {
  @include sizes-mixins.btn-lg;
}
.btn.btn-lg.btn-border,
.btn-group-lg.btn-group-btn-border > .btn {
  @include sizes-mixins.btn-lg-border;
}
.btn.btn-lg.btn-border-light,
.btn-group-lg.btn-group-btn-border-light > .btn {
  @include sizes-mixins.btn-lg-border-light;
}


// Extra large button
.btn.btn-lger,
.btn-group-lger > .btn {
  @include sizes-mixins.btn-lger;
}
.btn.btn-lger.btn-border,
.btn-group-lger.btn-group-btn-border > .btn {
  @include sizes-mixins.btn-lger-border;
}
.btn.btn-lger.btn-border-light,
.btn-group-lger.btn-group-btn-border-light > .btn {
  @include sizes-mixins.btn-lger-border-light;
}
