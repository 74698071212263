h3 a.other-section {
  display: inline-block;
  float: right;
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
}
.session-list {
  display: flex;
  flex-wrap: wrap;
}
.panel.panel-default.program {
  width: 100%;
}
.session-list .session {
  display: flex;
}
.session-list .session .wrapper {
  margin-bottom: 20px;
  padding: 10px 15px 60px;
  background-color: #FCFCFC;
  border: 1px solid #eaeaea;
  width: 100%;
  position: relative;
}
.session-list .session .wrapper > .session-details-info {
  display: block;
  color: inherit;
  margin-right: 10px;
}
.session-list .session .wrapper > .session-details-info .share-object.icon-only {
  position: absolute;
  top: 5px;
  right: 5px;
}
.session-list .session .wrapper > .session-details-info.with-group-discount {
  padding-top: 20px;
}
.session-list .session .wrapper > .session-details-info.with-group-discount
.row-with-icon.group-discount {
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 0;
  height: 30px;
  background-color: white;
  padding: 2px 15px;
  font-size: 95%;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14),
  0 3px 3px -2px rgba(0,0,0,0.12),
  0 1px 8px 0 rgba(0,0,0,0.2);
}

.session-list .session .wrapper > .session-details-info.with-group-discount
.row-with-icon.group-discount .percent {
  font-weight: 700;
}

.session-list .session .wrapper > .actions {
  text-align: right;
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  left: 15px;
  right: 15px;
}
.session-list .session .wrapper > .actions > a {
  vertical-align: middle;
}
.providers-locations-list > li {
  margin-bottom: 1ex;
}
.providers-locations-list > li > ul {
  margin-left: 1em;
}
@media(max-width: 767px) {
  .program-list .program .panel-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .program-list .program .panel-heading .panel-title-box {
    border-right: none;
    padding: 5px 10px;
  }
}

program-media-summary {
  display: block;
  max-width: 960px;
}
/*
Mimic the cropping style of ec-single-media-of-program-or-provider.
This code duplication will go away when the program page is redone to
share code with the program modal.
*/
program-media-summary .program-image {
  width: 100%;
  height: 0;
  padding-top: 66.666667%;
  position: relative;
}
program-media-summary .program-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
