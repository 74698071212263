@use "abstracts/mixins";
@use "abstracts/variables";

.front-page-content.front-providers {

  .commission-breakdown-section {
    margin-bottom: 250px;
  }

  @media (max-width: 767px) {
    .front-section.hero-blocks-section {
      @include mixins.bottom-waved-background(variables.$theme-color-green-light);
      padding-bottom: 60px;
    }

    .front-section.testimonials-section {
      @include mixins.top-bottom-waved-background(variables.$theme-color-green-light);
      padding-top: 50px;
      padding-bottom: 75px;
    }

    .commission-breakdown-section {
      margin-bottom: 100px;
    }
  }

  @media (min-width: 768px) {
    .front-section.value-propositions-section {
      .front-section-content {
        @include mixins.top-waved-background(variables.$theme-color-green-light);
        margin-top: 120px;

        > .container {
          position: relative;
          top: -130px;
        }
      }
    }

    .front-section.additional-benefits-section {
      @include mixins.bottom-waved-background(variables.$theme-color-green-light);
      margin-top: 0;
      padding-bottom: 200px;
    }
  }
}
