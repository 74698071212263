@mixin table-as-cards($with-header: false, $with-collapse-button-in-header: false) {
  border: 0;

  thead {
    display: none;
  }

  tbody > tr {
    display: block;
    border: 0;
    border-bottom: 1px solid #eaeaea;
    margin: 15px;
    padding: 0;
  }

  tbody > tr > td, // to overwrite Bootstrap rules
  td {
    display: block;
    width: 100%;
    text-align: right;
    border: none;
    border-bottom: 1px dotted #eaeaea;
    padding: 10px 0 !important;
    // For cells without content, without this left floated label will not
    // properly fill cell and cell will not have proper height.
    overflow: hidden;

    &:before {
      content: attr(data-label);
      display: block;
      float: left;
      text-align: left;
      font-weight: bold;
    }
  }

  > tbody > tr > td:last-child {
    border-bottom: 0;
  }

  // nested tables
  table tr {
    padding: 0;

    &:first-child td:first-child {
      padding-top: 0 !important;
    }

    &:last-child td:last-child {
      border-bottom: none;
      padding-bottom: 0 !important;
    }
  }

  .show-block-on-table-as-cards {
    display: block;
  }

  .show-inline-on-table-as-cards {
    display: inline;
  }

  .hide-on-table-as-cards {
    display: none;
  }

  @if $with-header {
    tbody > tr {
      border: none;
    }

    > tbody > tr > td:first-child {
      background-color: #f5f5f5;
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-align: left;
      white-space: normal;
    }
  }

  @if $with-collapse-button-in-header {
    > tbody > tr > td:first-child {
      padding-right: 40px !important;
      position: relative;

      .collapse-button {
        position: absolute;
        top: 10px;
        right: 15px;
      }
    }
  }
}

@mixin table-condensed {
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td,
  > thead > tr > th:first-child,
  > tbody > tr > td:first-child {
    padding: 8px 12px;
    font-size: 15px;
  }
}

@mixin table-extra-condensed {
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td,
  > thead > tr > th:first-child,
  > tbody > tr > td:first-child {
    padding: 3px 5px;
    font-size: 13px;
  }
}
