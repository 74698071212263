@use "sass:color";
@use "abstracts/variables";
@use "mixins";

.btn-blue {
  $color-1: color.adjust(variables.$theme-color-blue, $lightness: -10%);
  $color-2: color.adjust(variables.$theme-color-blue, $lightness: -20%);

  @include mixins.button-color(
    $color-1, $color-1, $color-2, $color-2
  );
  @include mixins.button-color-border(
    $color-2, $color-2, $color-2, $color-2
  );
}
