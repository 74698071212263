@use "abstracts/variables";

.underlined {
  text-decoration: underline;
}
a.underlined:hover {
  text-decoration: none;
}
@media (max-width: 991px) {
  a.underlined:active {
    background: #F6F6F6;
  }
}

.strike-through {
  text-decoration: line-through;
}

// make image in grayscale by default and in color on hover
img.grayed {
  transition: filter .35s ease-in-out, opacity .35s ease-in-out;
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
  opacity: 0.5;
}
img.grayed:hover {
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); /* FF 35+ */
  opacity: 1;
}

// for printing
.no-page-break {
  page-break-inside: avoid;
}
.break-after {
  page-break-after: always;
}

.gray-bg-on-hover:hover {
  background-color: #f7f7f7;
}
.row.gray-bg-on-hover:hover {
  background-color: transparent;
  background-image: linear-gradient(to right, transparent, #f7f7f7 5%, #f7f7f7 95%, transparent);
}

.link-color-on-hover:hover {
  color: variables.$link-color;
}

.fade {
  transition: opacity .15s linear;
}
