@use "mixins";

.btn-success {
  @include mixins.button-color(
    #5cb85c, #4cae4c, #449d44, #398439
  );
  @include mixins.button-color-border(
    #4caf50, #4cae4c, #3d8b40, #327334
  );
}
