@use "abstracts/variables";
@use "base/forms-mixins";

.hero-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.1em;
  @media (min-width: 360px) { font-size: 28px; }
  @media (min-width: 412px) { font-size: 32px; }
  @media (min-width: 576px) { font-size: 40px; }
}

.multi-block-wrapper .hero-title {
  @media (min-width: 768px) { font-size: 32px; }
  @media (min-width: 992px) { font-size: 40px; }
}

.hero-text-container {
  font-size: 16px;
  line-height: 1.6em;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 1.8em;
  }
}

.hero-buttons {
  @media (min-width: 768px) {
    @include forms-mixins.form-lg;
  }

  @media (min-width: 1200px) {
    @include forms-mixins.form-lger;
  }

  .stacked-buttons {
    display: inline-flex;
    flex-direction: column;
    row-gap: 15px;
  }
}

.hero-block {
  margin-top: 100px;
  position: relative;
  padding-left: 40%;

  .hero-block-image > svg {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 37%;

    &.svg-butterfly {
      top: -20px;
      left: -10%;
      max-width: 47%;
    }

    &.svg-coffee {
      max-width: 26%;
    }

    &.svg-heart-with-pencil {
      max-width: 34%;
      left: -10%;
    }

    &.svg-maze {
      max-width: 24%;
    }

    &.svg-kids {
      left: -8%;
    }
  }

  &.no-image {
    padding-left: 0;
  }

  .hero-block-nav {
    font-size: 18px;
    position: relative;
    top: -20px;

    li {
      display: inline-block;
      margin-right: 35px;

      a {
        color: variables.$header-menu-color;
        border-bottom: 4px solid transparent;
      }
    }

    a:hover,
    li.active a {
      text-decoration: none;
      color: variables.$header-menu-color-hover;
      border-bottom-color: variables.$header-active-menu-bottom-border-color;
    }
  }

  .hero-block-text {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 1199px) {
    .hero-block-image > svg {
      &.svg-butterfly {
        left: -15%;
        max-width: 53%;
      }
    }
  }

  @media (max-width: 991px) {
    padding-left: 37%;

    .hero-block-image > svg {
      max-width: 30%;
    }

    .hero-block-nav {
      font-size: 16px;

      li {
        margin-right: 25px;
      }
    }
  }

  @media (max-width: 767px) {
    padding-left: 0;
    margin-top: 50px;

    .hero-block-image {
      display: none;
    }

    &.with-nav {
      margin-top: 75px;

      .hero-block-nav {
        position: absolute;
        left: -25px;
        right: -25px;
        top: -75px;
        background: #fff;
        border-bottom: 1px solid #ddd;
        font-size: 13px;
        padding-top: 10px;

        li {
          margin: 0;

          a {
            padding: 10px 12px 5px;
            display: inline-block;
          }

          &:first-child {
            a {
              padding-left: 25px;
            }
          }
        }
      }
    }
  }
}

.multi-hero-blocks-container {
  @media (min-width: 992px) {
    .hero-block-wrapper-left .hero-block {
      margin-right: 10%;
    }
    .hero-block-wrapper-right .hero-block {
      margin-left: 10%;
    }
  }
}


