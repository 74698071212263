/* =============================================================================
-------------------  GLOBAL CLASSES AND FIXES FOR ELEMENTS FROM THEME  ---------
============================================================================= */

/* enable momentum scrolling on iOS on all elements with overflow: scroll */
* {
  -webkit-overflow-scrolling: touch;
}

/* disable dotted outline on active links */
:focus {
  outline: none !important;
}
::-moz-focus-inner {
  border: 0 !important;
}

#content {
	width: 100%;
  padding: 0;
}

.no-padding {
	padding:0 !important;
}

.no-margin {
	margin:0 !important;
}

@media (min-width: 992px) {
	/* container-fluid give a larger padding */
	#content .container-fluid {
		padding-left:30px;
		padding-right:30px;
	}

}

iframe {
	border:none;
}

img.pull-right {
	margin-left:20px;
	margin-bottom:20px;
}

img.pull-left {
	margin-right:20px;
	margin-bottom:20px;
}


blockquote {
	position: relative;
	padding:8px 0 8px 25px;
	margin: 30px 0;
	font-size:16px;
	border-left:2px solid transparent;
}

blockquote.blockquote-reverse {
	padding:8px 25px 8px 0;
	margin:30px 0;
	border-left:none;
	border-right:2px solid transparent;
}

blockquote p {
	margin-bottom:15px;
}

blockquote cite {
	font-size:16px;
	font-weight: 700;
}

.flip-left-right,
.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.remove-button {
  cursor: pointer;
}

.clip {
  overflow: hidden;
}

.link-hover:hover {
  cursor: pointer;
}

.list {
  list-style: initial;
  margin-bottom: 10px;
  padding-left: 24px;
}

pre.breakable {
  white-space: normal;
  word-break: normal;
}

.text-disabled {
  opacity: 0.5;
}
.text-disabled,
.text-disabled * {
  cursor: not-allowed;
}
.text-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.in-text-separator:after {
  content: "·";
  font-weight: bold;
  color: #b6b6b6;
  margin: 0 3px;
}

.fa.crossed {
  position: relative;
}
.fa.crossed > span:before,
.fa.crossed > span:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  border-top: 1px solid #000;
}
.fa.crossed > span:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.fa.crossed > span:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* -------------------  PAGE TITLE  ----------------------------------------- */

.main-container-wrapper {
  padding-top: 40px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .main-container-wrapper {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.main-container-wrapper.no-top-padding {
  padding-top: 0;
}

.container.full-width {
  width: auto;
}

/* -------------------  FOR EQUAL COLUMN HEIGHTS  --------------------------- */
@media (min-width: 768px) {
  .flex-box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .flex-box .flex-col {
    flex: 1;
  }
}


.left-icon {
  margin-left: 10px;
}

/* ----------------- UTILS ------------------------- */
.preserve-space {
  white-space: pre-wrap;
}

.keep-together {
  white-space: nowrap;
}
@media (max-width: 360px) {
  .keep-together {
    white-space: inherit;
  }
}

/* Useful for conditional emphasis. */
.strong {
  font-weight: bold;
}

.no-border {
  border: none !important;
}

.fa-sm {
  font-size: .75em;
  line-height: 1.333333em;
  vertical-align: 15%;
}

/* ----------------- PRINT ------------------------- */
.print-only {
  display: none;
}
.print-only-inline {
  display: none;
}
@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .print-only {
    display: block;
  }

  .print-only-inline {
    display: inline;
  }

  .screen-only {
    display: none;
  }

  /* disable printing href attribute for links (this is set in Bootstrap) */
  a[href]:after {
    content: none !important;
  }
}

/* ---- used on home page, afterschool page, marketing-registration page ---- */
.main-separator {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #d7d7d7;
}
.main-separator .icon-container {
  position: absolute;
  width: 50px;
  top: -9px;
  left: calc(50% - 25px);
  background-color: #fff;
  text-align: center;
}
.main-separator .fa {
  font-size: 130%;
}
.main-separator hr {
  border-color: #d7d7d7;
}

/* price inputs with $ inside */
.input-with-icon.price input.form-control {
  padding: 9px 14px 9px 34px;
}
.input-with-icon.price .form-control-feedback {
  left: 0;
}

/* --------------- Condensed grid (rows and columns) -------------------------*/
.row-condensed {
  margin-left: -5px;
  margin-right: -5px;
}
.row-condensed .col-xs-1,
.row-condensed .col-sm-1,
.row-condensed .col-md-1,
.row-condensed .col-lg-1,
.row-condensed .col-xs-2,
.row-condensed .col-sm-2,
.row-condensed .col-md-2,
.row-condensed .col-lg-2,
.row-condensed .col-xs-3,
.row-condensed .col-sm-3,
.row-condensed .col-md-3,
.row-condensed .col-lg-3,
.row-condensed .col-xs-4,
.row-condensed .col-sm-4,
.row-condensed .col-md-4,
.row-condensed .col-lg-4,
.row-condensed .col-xs-5,
.row-condensed .col-sm-5,
.row-condensed .col-md-5,
.row-condensed .col-lg-5,
.row-condensed .col-xs-6,
.row-condensed .col-sm-6,
.row-condensed .col-md-6,
.row-condensed .col-lg-6,
.row-condensed .col-xs-7,
.row-condensed .col-sm-7,
.row-condensed .col-md-7,
.row-condensed .col-lg-7,
.row-condensed .col-xs-8,
.row-condensed .col-sm-8,
.row-condensed .col-md-8,
.row-condensed .col-lg-8,
.row-condensed .col-xs-9,
.row-condensed .col-sm-9,
.row-condensed .col-md-9,
.row-condensed .col-lg-9,
.row-condensed .col-xs-10,
.row-condensed .col-sm-10,
.row-condensed .col-md-10,
.row-condensed .col-lg-10,
.row-condensed .col-xs-11,
.row-condensed .col-sm-11,
.row-condensed .col-md-11,
.row-condensed .col-lg-11,
.row-condensed .col-xs-12,
.row-condensed .col-sm-12,
.row-condensed .col-md-12,
.row-condensed .col-lg-12 {
  padding-left: 5px;
  padding-right: 5px;
}

.row-extra-condensed {
  margin-left: -3px;
  margin-right: -3px;
}
.row-extra-condensed .col-xs-1,
.row-extra-condensed .col-sm-1,
.row-extra-condensed .col-md-1,
.row-extra-condensed .col-lg-1,
.row-extra-condensed .col-xs-2,
.row-extra-condensed .col-sm-2,
.row-extra-condensed .col-md-2,
.row-extra-condensed .col-lg-2,
.row-extra-condensed .col-xs-3,
.row-extra-condensed .col-sm-3,
.row-extra-condensed .col-md-3,
.row-extra-condensed .col-lg-3,
.row-extra-condensed .col-xs-4,
.row-extra-condensed .col-sm-4,
.row-extra-condensed .col-md-4,
.row-extra-condensed .col-lg-4,
.row-extra-condensed .col-xs-5,
.row-extra-condensed .col-sm-5,
.row-extra-condensed .col-md-5,
.row-extra-condensed .col-lg-5,
.row-extra-condensed .col-xs-6,
.row-extra-condensed .col-sm-6,
.row-extra-condensed .col-md-6,
.row-extra-condensed .col-lg-6,
.row-extra-condensed .col-xs-7,
.row-extra-condensed .col-sm-7,
.row-extra-condensed .col-md-7,
.row-extra-condensed .col-lg-7,
.row-extra-condensed .col-xs-8,
.row-extra-condensed .col-sm-8,
.row-extra-condensed .col-md-8,
.row-extra-condensed .col-lg-8,
.row-extra-condensed .col-xs-9,
.row-extra-condensed .col-sm-9,
.row-extra-condensed .col-md-9,
.row-extra-condensed .col-lg-9,
.row-extra-condensed .col-xs-10,
.row-extra-condensed .col-sm-10,
.row-extra-condensed .col-md-10,
.row-extra-condensed .col-lg-10,
.row-extra-condensed .col-xs-11,
.row-extra-condensed .col-sm-11,
.row-extra-condensed .col-md-11,
.row-extra-condensed .col-lg-11,
.row-extra-condensed .col-xs-12,
.row-extra-condensed .col-sm-12,
.row-extra-condensed .col-md-12,
.row-extra-condensed .col-lg-12 {
  padding-left: 3px;
  padding-right: 3px;
}

/* ---------Some useful classes for responsive design ------*/
/* bootstrap v4-alpha had similar classes, but they were removed in v4. */

@media (max-width: 543px) {
  .hidden-xs-down {
    display: none!important;
  }
}

@media (min-width: 544px) {
  /* bootstrap v4-alpha called this hidden-sm-up. */
  /* What it called hidden-xs-up hid unconditionally, at all sizes. */
  .hidden-xs-up {
    display: none!important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none!important;
  }
}

@media (min-width: 768px) {
  /* bootstrap v4-alpha called this hidden-md-up. */
  .hidden-sm-up {
    display: none!important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none!important;
  }
}

@media (min-width: 992px) {
  /* bootstrap v4-alpha called this hidden-lg-up. */
  .hidden-md-up {
    display: none!important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none!important;
  }
}

@media (min-width: 1200px) {
  /* bootstrap v4-alpha called this hidden-xl-up. */
  .hidden-lg-up {
    display: none!important;
  }
}

/*------------ Help------------ */
.help.below-input,
label.has-help .fa,
span.help {
  font-size: 85%;
  color: #808080;
}
