@use "abstracts/variables";
@use "base/font-awesome";
@use "base/calendar_picker_button_as_text";
@use "components/base/buttons/variables" as buttons-variables;

/* frames */
.frames {
  background-color: #fff;
  position: fixed;
  z-index: 1;
  top: variables.$header-height;
  bottom: var(--extra-bottom-space, 0);
  left: 0;
  right: 0;

  &.left-frame-hidden {
    top: variables.$header-height;

    @media (max-width: 767px) {
      top: variables.$mobile-header-height;
    }
  }

  @media (max-width: 767px) {
    top: variables.$mobile-header-height;
  }
}
.frames .frames-divider {
  width: 0;
  height: 100vh;
  float: left;
  position: relative;
}
.frames .frames-divider .handler {
  height: 100%;
  background-color: #fff;
  transition: background-color 0.2s, border-color 0.2s;
  position: absolute;
  top: 0;
  left: -9px;
  right: -8px;
  z-index: 5;
  border-left: 1px solid variables.$section-border-color;
  border-right: 1px solid variables.$section-border-color;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 40px;
    border-radius: 5px;
    position: absolute;
    //calculated based on the $header-height
    top: calc(50vh - 50px);
    left: 5px;
    background: #777777;
  }
}
.frames .frames-divider:hover {
  .handler {
    background-color: #f0f0f0;
    border-color: #c6c6c6;
    cursor: col-resize;
  }
}
.frames .frame {
  float: left;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
  height: 100%;
}
.frames .frame-first {
  width: 100%;
  padding-right: 8px;
}
.frames .frame-second {
  padding-left: 7px;
  width: 0%;
}
.frames.resizing-manually .frame {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.frames .frame .frame-body {
  clear: both;
  height: 100%;
}
.frames .frame .frame-body .frame-body-content {
  position: relative;
  height: 100%;
}
.frames .frame .frame-body .frame-body-content > ng-include {
  height: 100%;
}

.frames .frame .frame-body .frame-body-content .tab-content .tab-pane,
.frames .frame .frame-body .frame-body-content .tab-content [schedule-table],
.frames .frame .frame-body .frame-body-content .tab-content .schedule-wrapper,
.frames .frame .frame-body .frame-body-content .tab-content .schedule-wrapper > *:not(.print-only),
.frames .frame .frame-body .frame-body-content .tab-content .tab-content-wrapper {
  height: 100%;
}
.frames .frame-first .frame-body .frame-body-content .tab-content {
  border-right: 1px solid variables.$section-border-color;
  height: 100%;
}
.frames .frame-second .frame-body .frame-body-content .tab-content {
  height: calc(100% - 53px);
  border-left: 1px solid variables.$section-border-color;
  transition: background-color 0.35s, transform 0.35s;

  &.with-map {
    height: 100%;
  }

  .tab-pane {
    transition: opacity 0.35s;
  }

  &.blink {
    background: variables.$link-color;
    transform: translateY(-10px);

    .tab-pane {
      opacity: 0.25;
    }
  }
}
.frames .frame .nav-tabs {
  margin: 0;
  padding: 0 15px;
}
.frames .frame .nav-tabs li {
  position: relative;
}
.frames .frame .schedule-tab {
  transition: background-color 0.35s, transform 0.35s;

  .schedule-close {
    top: 10px;
    right: 10px;
    position: absolute;
  }

  .schedule-options {
    position: absolute;
    left: 115px;
    top: 9px;
    cursor: pointer;
    display: none;
  }

  .calendar-picker {
    display: none;
  }

  .calendar-input {
    @include calendar_picker_button_as_text.style-calendar-picker-button-as-text;

    .calendar-picker-btn {
      display: inline;
      padding: 0 0 3px;
      font-size: 14px;
      margin-bottom: 3px;
      text-align: left;
    }

    .input-group-btn {
      margin-left: 70px;
      font-size: 14px;

      .btn {
        position: relative;
        padding-right: 20px;

        &:after {
          @include font-awesome.font-awesome('angle-down');
          & {
            font-size: 1.2em;
            position: absolute;
            right: 0;
            top: 2px;
          }
        }
      }
    }

    @media (max-width: 400px) {
      .input-group-btn {
        margin-left: 35px;
      }
    }

    @media (max-width: 355px) {
      .input-group-btn .of-week {
        display: none;
      }
    }
  }

  &.active {

    > .a {
      padding-top: 0;
      padding-bottom: 0;
      height: 43px;
      line-height: 1.4em;
    }

    .calendar-picker {
      display: block;
    }

    .schedule-options {
      display: block;
    }

  }

  &.blink {
    background: variables.$link-color;
    transform: translateY(-10px);
  }
}
.schedule-options-dropdown-menu {
  margin-top: 21px;
  margin-left: -252px;
}
.frames .frame.programs-frame .tab-content {
  position: relative;
}
.frames .frame.programs-frame .tab-content-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
}
.frames .frame.programs-frame .tab-content-wrapper.week-view-wrapper {
  overflow-x: auto;
}
.programs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;
}
.frames .frame.schedule-and-map-frame .tab-content-wrapper {
  padding-top: 10px;
}
.frames .frame.schedule-and-map-frame .tab-content-wrapper > div {
  height: 100%;
}
.frames .frame.schedule-and-map-frame .tab-pane-map {
  padding: 0;

  .close-map {
    background-color: #fff;
    border-radius: buttons-variables.$button-border-radius;
    position: relative;
    z-index: 3;
    top: 10px;
    left: 10px;
    position: absolute;
    opacity: 1;
  }

}
.frames .frame.schedule-and-map-frame .tab-pane-map .map {
  height: 100%;
}
.frames .frame.schedule-and-map-frame .no-children-form {
  overflow: auto;
  padding: 40px 15px 15px 15px;
  height: 100%;
}
@media (min-width: 768px) {
  .frames .frame.schedule-and-map-frame .no-children-form .simple-children-details-form-wrapper {
    min-width: 360px;
  }
}
.frames .frame.schedule-and-map-frame .no-children-form .simple-children-details-form-wrapper .form-title small {
  display: block;
  margin-top: 15px;
}

// hiding right frame
.frames.hide-right-frame {
  .frames-divider,
  .frame-second {
    display: none;
  }
  .frame-first {
    width: 100% !important;
    padding-right: 0;
  }
}

// week view =============================================================== //
.frames .week-view-wrapper {

  .week-header-fixed {
    position: absolute;
    left: 0;
    top: -1px;
    display: none;
    z-index: 1;
    overflow: hidden;

    &.active {
      display: block;
    }

    @media (max-width: 767px) {
      display: none !important;
    }

    .week {
      margin: 0;
    }
  }

  .week {
    margin: 15px 0;

    .day-of-week {
      .day-of-week-header {
        background: #F8F8F8;
        border: 1px solid #E2E2E2;
      }
    }
  }

  @media (max-width: 767px) {
    .week {
      .day-of-week {
        .day-of-week-header {
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          position: relative;

          .day-of-week-toggle {
            position: absolute;
            top: 10px;
            right: 20px;

            .fa {
              transition: transform 0.4s;
            }
          }

          &.expanded .day-of-week-toggle .fa {
            transform: scale(1, -1);
          }
        }

        .day-of-week-sessions {
          margin: 0 -15px;
          padding: 0 15px;
        }

        .day-of-week-session {
          box-shadow: 0 2px 8px rgba(0,0,0,0.15);
          margin-bottom: 20px;
        }
      }

    }
  }

  @media (min-width: 768px) {

    .week {
      display: flex;

      .day-of-week {
        flex: 1;
        min-width: 220px;
        max-width: 360px;
        border-right: 1px solid #E2E2E2;

        &:first-child {
          border-left: 1px solid #E2E2E2;
        }

        .day-of-week-header {
          border-left: none;
          padding: 5px 20px;
          font-size: 14px;
          pointer-events: none;
          border-left: none;
          border-right: none;

          .day-of-week-toggle {
            display: none;
          }
        }

        .day-of-week-sessions {
          height: auto !important;
          display: block !important
        }

        .day-of-week-session {
          border-bottom: 1px solid #E2E2E2;
        }
      }

    }

    .week.week-for-1-days .day-of-week {
      width: 100%;
    }

    .week.week-for-2-days .day-of-week {
      width: 50%;
    }

    .week.week-for-3-days .day-of-week {
      width: 33.333333%;
    }

    .week.week-for-4-days .day-of-week {
      width: 25%;
    }

    .week.week-for-5-days .day-of-week {
      width: 20%;
    }

    .week.week-for-6-days .day-of-week {
      width: 16.666666%;
    }

    .week.week-for-7-days .day-of-week {
      width: 14.285714%;
    }

  }
}

@media (max-width: 767px) {
  .frames .frames-divider {
    display: none;
  }
  .programs-visible .frames .schedule-and-map-frame,
  .schedule-visible .frames .programs-frame,
  .map-visible .frames .programs-frame {
    display: none;
  }
  .frames .frame-first, .frames .frame-second {
    padding: 0;
  }
}

@media print {
  .frames {
    position: static;
  }

  // schedule frame visible
  .schedule-visible .frames {

    .frame-first,
    .frames-divider {
      display: none;
    }
    .frame-second {
      width: 100% !important;
      padding: 0;
      display: block;
    }
    .frame .nav-tabs {
      display: none;
    }
    .frame .schedule-tab.active {
      float: left;
      padding-top: 2px;

      .text {
        font-size: 20px;
      }

      .schedule-options {
        display: none;
      }

      .calendar-picker .calendar-picker-btn {
        overflow: visible;
        width: auto;
        padding: 5px 0 0;

        .calendar-text {
          font-size: 16px;
        }
      }
    }
    .frame.schedule-and-map-frame .tab-pane-map {
      display: none;
    }
    .frame.schedule-and-map-frame .tab-pane-schedule {
      display: block;
    }
    .frame .schedule-tab .schedule-close {
      display: none;
    }

  }

  .frames .frame .frame-body .frame-body-content {
    padding: 0;

    .tab-content {
      height: auto;
      border: none;

      .tab-content-wrapper {
        overflow: visible;
      }
    }
  }

  .schedule-options-dropdown-menu {
    display: none !important;
  }

}
