.ec-stripe-card-container {
  margin-bottom: 10px;

  .help-block {
    margin-bottom: 0;
  }
}

.ec-stripe-other-card-fields {

  .ec-stripe-form-row {
    margin-bottom: 10px;
  }

  .help-block {
    margin-bottom: 0;
  }

}
