.share-url-directive {
  display: grid;
  grid-template-columns: auto 95px;
  grid-gap: 15px;

  &.only-copy {
    display: block;
  }

  .input-addon {
    display: flex;
    width: 100%;
    height: 40px;

    .input-suffix {
      display: table-cell;
      color: #484848;
      background-color: #edefed;
      height: 100%;
      width: 70px;
      white-space: nowrap;
      line-height: 22px;
      vertical-align: middle;
      padding: 7px !important;

      border: 1px solid #aaa;
      border-radius: 2px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    .input-copy {
      border: 1px dashed #ccc;
      border-right: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      > input {
        font-size: 14px;
        border: 0;
        padding: 8px 14px;
        width: 100%;
      }
    }
  }
}

.share-or-email.modal-body {
  .email-section {
    margin-bottom: 40px;
    text-align: center;

    .btn-custom {
      width: 300px;
    }
  }
}
