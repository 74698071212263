@use "button-group-mixins";

.btn-group {
  @include button-group-mixins.btn-group;
}

.btn-group-vertical {
  @include button-group-mixins.btn-group-vertical;
}

// Sizes

.btn-group-sm {
  @include button-group-mixins.btn-group-sm;
}

.btn-group-sm-padding {
  @include button-group-mixins.btn-group-sm-padding;
}

.btn-group-xs {
  @include button-group-mixins.btn-group-xs;
}

.btn-group-xs-padding {
  @include button-group-mixins.btn-group-xs-padding;
}
