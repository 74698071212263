@use "abstracts/variables";

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  vertical-align: middle;

  /* Hide default HTML checkbox */
  input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 50%;
    height: 100%;
    opacity: 0;
    margin: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: background-color .4s, border-color .4s;
    border: 1px solid #999;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: #999;
    transition: transform .4s, background-color .4s;
  }

  input:checked + .slider {
    background-color: variables.$link-color;
    border-color: variables.$link-color;
  }

  input:checked + .slider:before {
    background-color: #fff;
  }

  input:focus + .slider {
    box-shadow: variables.$control-focus-box-shadow;
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// disabled state
fieldset[disabled] .switch .slider,
.switch input[disabled] + .slider,
.switch input.disabled + .slider {
  opacity: 0.5;
  cursor: not-allowed;
}
