@use "abstracts/variables";

[calendar-picker] .calendar-picker-btn .calendar-text .short {
  display: none;
}
[calendar-picker] .input-group .calendar-picker-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 10px;
  padding-left: 10px;
  display: inline-block;
  overflow: hidden;
  height: 40px;
}
[calendar-picker] .input-group .dropdown-toggle {
  padding-left: 10px;
  padding-right: 10px;
}
[calendar-picker] .input-group .calendar-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
[calendar-picker] .input-group .btn.calendar-picker-btn {
  width: 100%;
  position: relative;
}
[calendar-picker] .input-group .input-group-btn {
  width: 1%;
}
.calendar-picker-btn .fa {
  font-size: 1.2em;
}
.calendarpicker.dropdown-menu {
  background-color: #F6FEF6 !important;
  padding: 10px;
  font-size: 15px;
}
.calendarpicker.popover {
  min-width: inherit;
  max-width: 100%;
  position: relative;
  width: 589px;

  &.bottom {
    margin-top: 8px!important;
  }

  .popover-footer {
    background: none;
  }
}
.calendarpicker.popover .popover-content {
  min-height: 130px;
  padding: 15px 0 0;
}
.calendarpicker.popover label input[type="radio"] {
  margin: 0 2px 0 5px;
}
.calendarpicker.popover .calendar-column .title .input-wrapper {
  position: relative;
  height: 40px;

  .fake-input,
  ec-date-picker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: bold;
  }

  .fake-input {
    color: variables.$link-color;
    white-space: nowrap;
    transition: color 0.15s ease;

    &.changing {
      color: #fff;
    }
  }

  ec-date-picker {
    --ec-date-picker-input-group-display: flex;
    --ec-date-picker-input-group-max-width: none;

    .form-control {
      opacity: 0;
    }

    &:focus-within,
    &.active {
      .form-control {
        opacity: 1;
      }
    }
  }

}
